
const isBlank = val => val === undefined || val === null || val === ''

const PROJECT_ARRAY_FIELDS = [
  {
    field: 'attachments',
    nestedField: 'fileName',
  },
  {
    field: 'makeOrders',
    nestedField: 'makeOrderNumber',
  },
  {
    field: 'partGroups',
    nestedField: 'part.fileName',
  },
  {
    field: 'shipments',
    nestedField: 'shipmentNumber',
  },
]

const PROJECT_OBJECT_FIELDS = [
  {
    field: 'customer',
    nestedField: 'emailAndName',
  },{
    field: 'projectManager',
    nestedField: 'email',
  },{
    field: 'overrides',
    nestedField: 'shippingPrice',
    fieldNameOverride: 'shippingPriceType',
  },
]

const PROJECT_FIELDS_TO_HIDE = [
  'asParam_finishingDays',
  'asParam_finishingMarkup',
  'asParam_internalServiceShipment',
  'asParam_serviceType',
  'asParam_shipMethodToInt',
  'asParam_supplier',
  'calculations',
  'createdAt',
  'expediteLeadTimeOverride1',
  'expediteLeadTimeOverride2',
  'expediteLeadTimeOverride3',
  'expediteLeadTimeOverride4',
  'makeOrders',
  'materials',
  'markupParam_additionalBufferDays',
  'markupParam_supplierLeadTime',
  'markupParam_totalFreight',
  'mkCastParam_markup',
  'mkCncParam_markup',
  'mkDmlsParam_markup',
  'mkInjmdParam_moldMarkup',
  'mkInjmdParam_partsMarkup',
  'mkShtmlParam_markup',
  'partGroups',
  'partsListForQb',
  'previouslyBookedProject',
  'quotes',
  'selectedPricingData',
  'shipments',
  'visibleSheetNames',
]

export function getProjectDifferences(previouslyBookedProject, projectToMaybeSave){
  previouslyBookedProject = deepCopy((previouslyBookedProject && typeof previouslyBookedProject === 'object') ? previouslyBookedProject : {})
  projectToMaybeSave = deepCopy((projectToMaybeSave && typeof projectToMaybeSave === 'object') ? projectToMaybeSave : {})
  const fields = unique([...Object.keys(previouslyBookedProject), ...Object.keys(projectToMaybeSave)])

  return fields
    .map(field => fieldsToMatches(field, previouslyBookedProject, projectToMaybeSave))
    .filter(matchesByNotMatching)
    .filter(diff => diffsByFieldsToHide(diff, PROJECT_FIELDS_TO_HIDE))
    .map(handleBooleans)
    .map(handleShipAddress)
    .map(diff => handleObjectFields(diff, PROJECT_OBJECT_FIELDS))
    .map(diff => handleArrayFields(diff, PROJECT_ARRAY_FIELDS))
    .filter(diff => diff.oldVal !== diff.newVal)
}

const PARTGROUP_FIELDS_TO_HIDE = [
  'processId',
  'materialId',
  'finishId',
  'part.partFileObject',
]

const PARTGROUP_ARRAY_FIELDS = [
  {
    field: 'postProcesses',
    nestedField: 'postProcessId',
  },
  {
    field: 'part.cncRoughingTools',
    stringFunction: tool => JSON.stringify(tool)
  },
  {
    field: 'part.cncFinishingTools',
    stringFunction: tool => JSON.stringify(tool)
  },
  {
    field: 'part.imRoughingTools',
    stringFunction: tool => JSON.stringify(tool)
  },
  {
    field: 'part.imFinishingTools',
    stringFunction: tool => JSON.stringify(tool)
  },
  {
    field: 'productionToolPartConfigurations',
    stringFunction: ptpc => `${ptpc.quantity} x ${ptpc.materialId}`
  },
  {
    field: 'tolerances',
    stringFunction: tolerance => JSON.stringify(tolerance)
  },
]

const PARTGROUP_OBJECT_FIELDS = [
  {
    field: 'part.meshProperties',
    nestedField: 'shells',
  },
]


export function getPartGroupDifferences(previouslyBookedPartGroups, partGroupsToMaybeSave){
  previouslyBookedPartGroups = deepCopy(typeof previouslyBookedPartGroups === 'object' ? previouslyBookedPartGroups : [])
  partGroupsToMaybeSave = deepCopy(typeof partGroupsToMaybeSave === 'object' ? partGroupsToMaybeSave : [])

  previouslyBookedPartGroups = previouslyBookedPartGroups
    .map(ptg => {
      Object.keys(ptg.part).forEach(key => {
        ptg['part.' + key] = ptg.part[key]
      })
      delete ptg.part
      return ptg
    })

  partGroupsToMaybeSave = partGroupsToMaybeSave
    .map(ptg => {
      Object.keys(ptg.part).forEach(key => {
        ptg['part.' + key] = ptg.part[key]
      })
      delete ptg.part
      return ptg
    })

  const partGroupNumbers = unique([...previouslyBookedPartGroups.map(ptg => ptg.partGroupNumber), ...partGroupsToMaybeSave.map(ptg => ptg.partGroupNumber)])
  return partGroupNumbers
    .map(partGroupNumber => {
      let oldPartGroup = previouslyBookedPartGroups.find(ptg => ptg.partGroupNumber === partGroupNumber)
      oldPartGroup = oldPartGroup ? oldPartGroup : {}

      let newPartGroup = partGroupsToMaybeSave.find(ptg => ptg.partGroupNumber === partGroupNumber)
      newPartGroup = newPartGroup ? newPartGroup : {}

      const fields = unique([
        ...Object.keys(oldPartGroup),
        ...Object.keys(newPartGroup)
      ])

      const diffs = fields
        .map(field => fieldsToMatches(field, oldPartGroup, newPartGroup))
        .filter(matchesByNotMatching)
        .filter(diff => diffsByFieldsToHide(diff, PARTGROUP_FIELDS_TO_HIDE))
        .map(handleBooleans)
        .map(diff => handleObjectFields(diff, PARTGROUP_OBJECT_FIELDS))
        .map(diff => handleArrayFields(diff, PARTGROUP_ARRAY_FIELDS))
        .filter(diff => diff.oldVal !== diff.newVal)

      const partName = newPartGroup['part.fileName'] ? newPartGroup['part.fileName'] : oldPartGroup['part.fileName']

      return {
        partName: newPartGroup['part.fileName'] ? newPartGroup['part.fileName'] : oldPartGroup['part.fileName'],
        diffs,
      }
    })
}

function unique(array){
  return Array.from(new Set(array))
}

function fieldsToMatches(field, oldObject, newObject){
  const oldVal = oldObject[field]
  const newVal = newObject[field]

  if(oldVal === newVal){
    return { doMatch: true }
  }
  if(String(oldVal) === String(newVal) && typeof oldVal !== 'object' && typeof newVal !== 'object'){
    return { doMatch: true }
  }
  if(isBlank(oldVal) && isBlank(newVal)){
    return { doMatch: true }
  }
  if(JSON.stringify(oldVal) === JSON.stringify(newVal)){
    return { doMatch: true }
  }

  return {
    doMatch: false,
    field,
    oldVal,
    newVal,
  }
}

function matchesByNotMatching(diff){
  return !diff.doMatch
}

function diffsByFieldsToHide(diff, FIELDS_TO_HIDE){
  return !FIELDS_TO_HIDE.includes(diff.field)
}

function handleShipAddress(diff){
  if(diff.field === 'shipAddress'){
    if(typeof diff.oldVal === 'object'){
      const {street, street2, city, state, zip, country} = diff.oldVal
      diff.oldVal = `${street}, ${street2 ? street2 + ', ' : ''}${city}, ${state} ${zip}, ${country}`
    }
    if(typeof diff.newVal === 'object'){
      const {street, street2, city, state, zip, country} = diff.newVal
      diff.newVal = `${street}, ${street2 ? street2 + ', ' : ''}${city}, ${state} ${zip}, ${country}`
    }
  }
  return diff
}

function handleBooleans(diff){
  if(typeof diff.oldVal === 'boolean'){
    diff.oldVal = diff.oldVal ? 'TRUE' : 'FALSE'
  }
  if(typeof diff.newVal === 'boolean'){
    diff.newVal = diff.newVal ? 'TRUE' : 'FALSE'
  }

  return diff
}

function handleObjectFields(diff, OBJECT_FIELDS){
  const objectField = OBJECT_FIELDS.find(objectField => objectField.field === diff.field)
  if(objectField){
    diff.field = objectField.fieldNameOverride ? objectField.fieldNameOverride : diff.field
    diff.oldVal = diff.oldVal ? diff.oldVal[objectField.nestedField] : ''
    diff.newVal = diff.newVal ? diff.newVal[objectField.nestedField] : ''
  }

  return diff
}

function handleArrayFields(diff, ARRAY_FIELDS){
  const arrayField = ARRAY_FIELDS.find(arrayField => arrayField.field === diff.field)
  if(arrayField){
    diff.oldVal = diff.oldVal ? diff.oldVal.map(item => {
      if(arrayField.stringFunction){
        return arrayField.stringFunction(item)
      }
      return item[arrayField.nestedField]
    }).join(', ') : ''
    diff.newVal = diff.newVal ? diff.newVal.map(item => {
      if(arrayField.stringFunction){
        return arrayField.stringFunction(item)
      }
      return item[arrayField.nestedField]
    }).join(', ') : ''
  }

  return diff
}

function deepCopy(obj){
  return JSON.parse(JSON.stringify(obj))
}

