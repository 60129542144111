const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function startNewDimensionTask(s3StlFileId, partNumber){
  let response
  try{
    response = await startNewDimensionTaskRequest(s3StlFileId, partNumber)
  } catch (err){
    logUnexpected(err, { s3StlFileId, partNumber }, 'startNewDimensionTaskRequest')
    throw err
  }

  let result
  try{
    result = parseStartNewDimensionTaskResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseStartNewDimensionTaskResponse', { s3StlFileId, partNumber })
    throw err
  }

  return result
}

function startNewDimensionTaskRequest(s3StlFileId, partNumber){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/geom/startParamExtractTask',
    data: {
      s3StlFileId,
      partNumber,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseStartNewDimensionTaskResponse(response) {
  if(response.status === 200){
    return response.data.success
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
