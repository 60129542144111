import config from "../../config"
import React,{Fragment,useContext,useEffect,useState} from 'react'
import {Accordion,AccordionDetails,AccordionSummary,AppBar,Button,Modal,Paper,Table,TableBody,TableCell,TableHead,TableRow,Typography,withStyles} from '@material-ui/core'
import AppToolBar from '../Common/AppToolBar.js'
import {
  formatPrice,
  getOrderDateFromCurrentTime,
  sleep,
} from '../../utils'
import {calculateLockedProjectTotal} from '../../shared/calculateLockedProjectTotal.js'
import {calculateShipDateFromOrderDateAndLeadTimeDays} from '../ShipmentTracking/calculateDateFromBusinessDayOffset.js'
import downloadPdfToLocalMachineFromBase64 from '../../utils/downloadPdfToLocalMachineFromBase64.js'
import getFileNameFromProject from '../QuoteTool/getFileNameFromProject.js'
import {getPartGroupDifferences,getProjectDifferences} from './getDifferences.js'
import getProjectQuotePdf from '../QuoteTool/getProjectQuotePdf.js'
import makeInvoice from './makeInvoice.js'
import {ShippingDetailsExpansionDetails} from './ShippingDetailsExpansionDetails.js'
import {OrderSummaryDiv} from './OrderSummaryDiv.js'
import {ShippingAddressExpansionDetails} from './ShippingAddressExpansionDetails.js'
import {PaymentInformationExpansionDetails} from './PaymentInformationExpansionDetails'
import {placeOrderAsCustomer,placeOrderAsDme} from './placeOrder'
import projectIsAleadyBooked from '../Common/projectIsAleadyBooked.js'
import refreshFiles from '../Common/refreshFiles.js'
import {ReviewExpansionDetails} from './ReviewExpansionDetails.js'
import saveProjectAndNestedObjects from '../ShipmentTracking/saveProjectAndNestedObjects.js'
import { SHIP_PROVIDER_OPTIONS } from '../SELECT_FIELDS.js'
import {SnackBarContext} from '../Common/SnackBarStore'
import withProject from '../Common/withProject.js'
import updateCustomerAddress from './updateCustomerAddress.js'
import { useNonMountEffect, useStateCallback } from "../../hooks"
import { default as getShippingOptionsArray } from './getShippingOptionsArray.js'
import USER_TYPES from '../../utils/USER_TYPES.js'
import {v4 as uuid} from 'uuid'
import createFile from '../../utils/createFile.js'
import { uploadFileToS3 } from '../QuoteTool/cloudObjectStorage'
import getFileFromBase64 from '../../utils/getFileFromBase64.js'

var validator = require("email-validator");

function Checkout(props) {
  const [ billingFieldsToHighlight, setBillingFieldsToHighlight ] = useState({ // Used to control red border styling for required fields that fail validation in billing section
    name: false,
    email: false,
    paymentReferenceNumber: false,
    po: false
  })
  const [ checkoutStep, setCheckoutStep ] = useState(1) // Current form index (1 = shipping address, 2 = shipping method, 3 = payment info, 4 = review)
  const [ isEditProjectModalOpen, setIsEditProjectModalOpen ] = useState(false)
  const [ isSavingAddress, setIsSavingAddress ] = useState(false)
  const [ paymentMethod, setPaymentMethod ] = useState(props.project.paymentLocation === 'CC - Quote Tool' ? 'credit' : 'po') // Credit card / PO tracking
  const [ paymentPO, setPaymentPO ] = useState('')
  const [ projectToMaybeSave, setProjectToMaybeSave ] = useState({})
  const [ makeOrdersToMaybeSave, setMakeOrdersToMaybeSave ] = useState([])
  const [ shipAddressReadOnly, setShipAddressReadOnly ] = useState(false)
  const [ shippingAddressFieldsToHighlight, setShippingAddressFieldsToHighlight ] = useState({ // Used to control red border styling for required fields that fail validation in shipping address section
    city: false,
    country: false,
    name: false,
    phone: false,
    street: false,
    state: false,
    zip: false
  })
  const [ shippingMethodFieldsToHighlight, setShippingMethodFieldsToHighlight ] = useState({ // Used to control red border styling for required fields that fail validation in shipping method section
    accountNumber: false,
    accountZip: false
  })
  const [ shipVia, setShipVia ] = useState(props.project.shipAccountNumber === 'Autotiv' ? 'autotiv' : 'customerShipping') // Used for shipping details
  const [ stripe, setStripe ] = useState()
  const [ token, setToken ] = useStateCallback()

  const [ quotePrefix, setQuotePrefix ] = useState('') // temp value to help DMEs make more pdfs quicker

  const [ shippingOptions, setShippingOptions ] = useState([])
  const [ areShippingOptionsLoading, setAreShippingOptionsLoading ] = useState(false)

  const openSnackBarWithMessage = useContext(SnackBarContext)

  useEffect(() => {
    if(!areShippingOptionsLoading){
      setAreShippingOptionsLoading(true)

      getShippingOptionsData(props.project, props.selectedPricingData)
        .then(latestShippingOptions => setShippingOptions(latestShippingOptions))
        .finally(() => setAreShippingOptionsLoading(false))
    }
  }, ['ONMOUNT']) // eslint-disable-line react-hooks/exhaustive-deps

  useNonMountEffect(() => {
    if(props.project.isCustomShippingMethodUsed || shippingOptions.find(so => so.shipMethod === 'custom')){
      setAreShippingOptionsLoading(true)

      const DEBOUNCE_TIME = 2000
      debounceProm(() => getShippingOptionsData(props.project, props.selectedPricingData), DEBOUNCE_TIME)
        .then(latestShippingOptions => setShippingOptions(latestShippingOptions))
        .finally(() => setAreShippingOptionsLoading(false))
    }
  }, [
    props.project.customShippingDays,
    props.project.customShippingMethod,
    props.project.isCustomShippingMethodUsed,
    props.project.customShippingPrice,
  ])

  useEffect(()=>{
    const script = document.createElement("script")
    script.id = "stripe-js"
    script.src = "https://js.stripe.com/v3/"
    script.async = true;
    document.head.appendChild(script)

    // use either the test or live stripe key depending on how the app is running
    let stripePublicKey
    if (process.env.NODE_ENV === 'development') {
      stripePublicKey = config.STRIPE_PUBLIC_KEY_TEST
    } else {
      stripePublicKey = config.STRIPE_PUBLIC_KEY
    }

    if (window.Stripe) {

      setStripe(window.Stripe(stripePublicKey))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe(window.Stripe(stripePublicKey))
      })
    }
  },[]) // only run onMount

  // paymentMethod meta-onChange effect
  useNonMountEffect(()=>{
    if(paymentMethod !== 'po'){
      setPaymentPO('')
    }
  },[ paymentMethod ])

  // shipVia meta-onChange effect
  useNonMountEffect(()=>{
    const projectCopy = { ...props.project }
    if(shipVia === 'autotiv'){  // If setting shipVia to 'Autotiv' also update sibling fields
      projectCopy.shipAccountNumber = 'Autotiv'
      projectCopy.shipProvider = SHIP_PROVIDER_OPTIONS.FEDEX
      projectCopy.shipAccountZipCode = ''
      props.setProject(projectCopy, { saveProject: true })
    }
    else{ // If setting shipVia to 'Your shipping account'
      projectCopy.shipAccountNumber = ''
      props.setProject(projectCopy, { saveProject: true })
    }
  },[ shipVia ])

  const setProjectField = (name, value) =>{
    setProjectRaw(name, value)
  }

  const setProjectFieldAndSave = (name, value) => {
    setProjectRaw(name, value, { saveProject: true })
  }

  const setProjectRaw = (name, value, setProjectConfig) =>{
    const updatePath = name.split(".");
    if (updatePath.length === 1) {
      props.setProject({ ...props.project, [updatePath[0]]: value }, setProjectConfig)
    }
    else if (updatePath.length === 2) {
      props.setProject({
        ...props.project,
        [updatePath[0]]: { ...props.project[updatePath[0]], [updatePath[1]]: value }
      }, setProjectConfig)
    }
    else if (updatePath.length === 3) {
      props.setProject({
        ...props.project,
        [updatePath[0]]: {
          ...props.project[updatePath[0]],
          [updatePath[1]]: {
            ...props.project[updatePath[0]][updatePath[1]],
            [updatePath[2]]: value
          }
        }
      }, setProjectConfig)
    }
  }

  /*
    nextStepHandler is called by the form buttons to navigate between expansion panels
    the 'desiredStep' arg is optional and used for explicitly setting the step
  */
  const nextStepHandler = (desiredStep) => {
    if(desiredStep){  // If desiredStep is provided, set state to that
      setCheckoutStep(desiredStep)
    }
    else{ // Otherwise navigate to next step
      let nextStep = checkoutStep + 1
      if(nextStep === 3){ // If navigating to payment form : shipping details must be validated first
        if(validateShippingMethod()){  // If form is completed
          setCheckoutStep(nextStep)
          return
        }
        else{ //If form is not completed warn user
          props.snackbar('Please fill out shipping method details')
          return
        }
      }
      else if(nextStep === 4){  // If navigating to review form : payment details must be validated first
        if(validatePaymentInfo()){  // If form is completed
          setCheckoutStep(nextStep)
          return
        }
        else{ //If form is not completed warn user
          props.snackbar('Please fill out payment details')
          return
        }
      }
      setCheckoutStep(nextStep)
    }
  }

  const updateCustomerAddressHandler = async () => {
    if(!validator.validate(props.project.shipEmail)){
      props.snackbar('Please enter a valid shipping email address')
      return
    }
    if(!validateShippingData()){
      props.snackbar('Complete Shipping address form before saving. Required fields have asterisks.')
      return
    }

    const updatedCustomer = {
      ...props.project.customer,
      shippingContact: props.project.shipName,
      shippingCompany: props.project.shipCompany,
      shippingPhone: props.project.shipPhone,
      shippingEmail: props.project.shipEmail,
      shippingAddress: props.project.shipAddress,
    }

    setIsSavingAddress(true)
    updateCustomerAddress(updatedCustomer)
      .then(() => setProjectField('customer', updatedCustomer))
      .then(() => openSnackBarWithMessage('Address saved!'))
      .catch(err => openSnackBarWithMessage(err.message))
      .finally(() => setIsSavingAddress(false))
  }

  /*
    saveCustomerHandler changes the shipAddressReadOnly state if the required data in the ship address form is valid
  */
  const saveCustomerHandler = () => {
    if(!validator.validate(props.project.shipEmail)){
      props.snackbar('Please enter a valid shipping email address')
    }
    else if(validateShippingData()){  // If required shipping address fields are filled
      setShipAddressReadOnly(true) // Set address edit state to true to collapse that part of the form
    }
    else{
      props.snackbar('Complete Shipping address form before saving. Required fields have asterisks.')
    }
  }

  /*
    checkForExistingShipAddress verifies if parent component holds a shipping address already
  */
  const checkForExistingShipAddress = () => {
    let existingShipAddress = true
    if(props.project.shipAddress){
      existingShipAddress = props.project.shipAddress.city ? existingShipAddress : false
      existingShipAddress = props.project.shipAddress.country ? existingShipAddress : false
      existingShipAddress = props.project.shipAddress.state ? existingShipAddress : false
      existingShipAddress = props.project.shipAddress.street ? existingShipAddress : false
      existingShipAddress = props.project.shipAddress.zip ? existingShipAddress : false
    }
    else{existingShipAddress = false}
    existingShipAddress = props.project.shipEmail ? existingShipAddress : false
    existingShipAddress = props.project.shipName ? existingShipAddress : false

    if(props.user.type !== USER_TYPES.DME){
      existingShipAddress = props.project.shipPhone ? existingShipAddress : false
    }

    return existingShipAddress
  }

  /*
    editShipDetails is a simple method for setting this.state.shipAddressReadOnly prop to true
    shipAddressReadOnly is used to conditionally render the ship address form to input or read only modes
  */
  const editShipDetails = () => {
    setShipAddressReadOnly(false)
  }

  /*
    formatShippingLabel takes a shipping option object and formats
    the label that is used on the shipping method radio selector
  */
  const formatShippingLabel = option => {
    if(option.arrivalDateString === 'loading'){
      return <Typography>loading shipping options...</Typography>
    }
    let {arrivalDateString,cost} = option
    let formattedCost = formatPrice(cost)
    return (<Typography>{arrivalDateString} ({option.customerTranslation}) | <span style={{fontWeight:'bold'}}>{formattedCost}</span></Typography>)
  }

  /*
    validateShippingData checks all the fields required in the first step of checkout and returns a bool
    It also tracks fields that fail validation with the 'requiredFieldsToHighlight' object
    which controls the red border on required shipping address textfields
  */
  const validateShippingData = () => {
    let dataIsValid = true
    dataIsValid = props.project.shipName ? dataIsValid : false
    dataIsValid = props.project.shipAddress.city ? dataIsValid : false
    dataIsValid = props.project.shipAddress.country ? dataIsValid : false
    dataIsValid = props.project.shipAddress.state ? dataIsValid : false
    dataIsValid = props.project.shipAddress.street ? dataIsValid : false
    dataIsValid = props.project.shipAddress.zip ? dataIsValid : false

    //Track validation results, used for error styling the required textfields
    let requiredFieldsToHighlight = {} // Object to track required fields not filled by user
    requiredFieldsToHighlight.name = props.project.shipName ? false : true
    requiredFieldsToHighlight.city = props.project.shipAddress.city ? false : true
    requiredFieldsToHighlight.country = props.project.shipAddress.country ? false : true
    requiredFieldsToHighlight.state = props.project.shipAddress.state ? false : true
    requiredFieldsToHighlight.street = props.project.shipAddress.street ? false : true
    requiredFieldsToHighlight.zip = props.project.shipAddress.zip ? false : true

    if(!(props.user.type === USER_TYPES.DME)){
      dataIsValid = props.project.shipPhone ? dataIsValid : false
      requiredFieldsToHighlight.phone = props.project.shipPhone ? false : true
    }

    setShippingAddressFieldsToHighlight(requiredFieldsToHighlight)  //Update state with results of validation, trigger error highlighting on missed fields
    return dataIsValid
  }

  /*
    validateShippingMethod checks shipping method details and returns a bool
    It also tracks fields that fail validation with the 'requiredFieldsToHighlight' object
    which controls the red border on required shipping method textfields
  */
  const validateShippingMethod = () => {
    let dataIsValid = true
    dataIsValid = shipVia ? dataIsValid : false
    let requiredFieldsToHighlight = {} // Object to track required fields not filled by user
    if(shipVia === 'customerShipping'){
      dataIsValid = props.project.shipProvider ? dataIsValid : false
      dataIsValid = props.project.shipAccountNumber ? dataIsValid : false
      dataIsValid = props.project.shipAccountZipCode ? dataIsValid : false
      //Track validation results, used for error styling the required textfields
      requiredFieldsToHighlight.accountNumber = props.project.shipAccountNumber ? false : true
      requiredFieldsToHighlight.accountZip = props.project.shipAccountZipCode ? false : true
    }

    if(props.project.isCustomShippingMethodUsed){
      if(!props.project.customShippingMethod){
        dataIsValid = false
      }
      if(!(props.project.customShippingDays > 0) && props.project.customShippingDays !== 0){
        dataIsValid = false
      }
    }

    const shippingOption = shippingOptions.find(option => {
      return (option.shipMethod === props.project.paidShipMethod || option.shipMethod === 'custom')
    })
    if(!shippingOption){
      dataIsValid = false
      requiredFieldsToHighlight.paidShipMethod = true
    }

    setShippingMethodFieldsToHighlight(requiredFieldsToHighlight)  //Update state with results of validation, trigger error highlighting on missed fields
    return dataIsValid
  }

  /*
    validatePaymentInfo checks payment details and returns a bool
    It also tracks fields that fail validation with the 'requiredFieldsToHighlight' object
    which controls the red border on required payment textfields
  */
  const validatePaymentInfo = () => {
    let dataIsValid = true
    let requiredFieldsToHighlight = {} // Object to track required fields not filled by user

    dataIsValid = props.project.billingName ? dataIsValid : false
    requiredFieldsToHighlight.name = props.project.billingName ? false : true

    dataIsValid = validator.validate(props.project.billingEmail) ? dataIsValid : false
    requiredFieldsToHighlight.email = validator.validate(props.project.billingEmail) ? false : true

    if(props.user.type === USER_TYPES.DME){
      requiredFieldsToHighlight.paymentReferenceNumber = props.project.paymentReferenceNumber ? false : true
      dataIsValid = props.project.paymentReferenceNumber ? dataIsValid : false
    } else {

      if(paymentMethod === 'po'){
        dataIsValid = paymentPO ? dataIsValid : false
        //Track validation results, used for error styling the required textfields
        requiredFieldsToHighlight.po = paymentPO ? false : true
      }

    }

    setBillingFieldsToHighlight(requiredFieldsToHighlight)  //Update state with results of validation, trigger error highlighting on missed fields
    return dataIsValid
  }

  /*
    saveTokenHandler takes a token (from the Stripe API) and stores it locally until order is placed
  */
  const saveTokenHandler = (token) => {
    setToken(token,()=>{
      nextStepHandler()
    })
  }

  const downloadQuote = async () => {
    const fileContents = await getProjectQuotePdf(props.project, props.selectedPricingData.pricingData, props.selectedPricingData.selectedLeadTime)

    let currentQuoteRevision = +props.project.lastQuoteRevision + 1
    currentQuoteRevision = Number.isNaN(currentQuoteRevision) ? 0 : currentQuoteRevision

    const fileName = getFileNameFromProject(props.project, quotePrefix, currentQuoteRevision)
    const pdfFileName = `${fileName}.pdf`

    const pdfAsFile = await getFileFromBase64(fileContents, pdfFileName)

    const s3Id = await uploadFileToS3(pdfAsFile)

    const file = {
      s3Id: s3Id,
      fileName: pdfFileName,
      projectId: props.project.projectNumber,
      fileType: 'quote',
      active: true,
      userUploaded: props.user.email,
      isActive: true,
    }

    const fileInDb = await createFile(file)

    await refreshFiles(props.project.projectNumber)

    setProjectField('lastQuoteRevision', currentQuoteRevision)

    downloadPdfToLocalMachineFromBase64(fileContents, pdfFileName)
  }

  const makeInvoiceThunk = async () => {
    let project = {...props.project}
    project = decorateProjectWithPricingData(project, props.selectedPricingData, getShippingOptions(shippingOptions, props.project))
    project = decorateProjectWithPaymentLocation(project, paymentMethod)

    project.projectStatus = "Waiting for Payment"
    project.quoteStatus = "Won"

    project.selectedPricingData = JSON.stringify(props.selectedPricingData)

    if(projectIsAleadyBooked(props.project)){
      return openEditProjectModal(project)
    }

    const savedProject = await saveProjectAndNestedObjects(project)

    const isWaitingForPaymentInvoice = true
    return makeInvoice(project, props.MATERIAL_TRANSLATIONS, isWaitingForPaymentInvoice)
      .then(() => {
        props.navigateToSummaryPage(props.project.projectNumber)
      })
  }

  const placeOrderThunk = () => {
    let project = {...props.project}
    project = decorateProjectWithPricingData(project, props.selectedPricingData, getShippingOptions(shippingOptions, props.project))
    project = decorateProjectWithDates(project, ORDER_DATE, getShippingOptions(shippingOptions, props.project))
    project = decorateProjectWithPaymentLocation(project, paymentMethod)

    project.selectedPricingData = JSON.stringify(props.selectedPricingData)

    if(props.user.type === USER_TYPES.DME){
      project.projectStatus = "Building"
      project.quoteStatus = "Won"

      const leadTimePricePair = props.selectedPricingData.pricingData.leadTimePricePairs
            .find(ltpp => ltpp.price === props.selectedPricingData.selectedLeadTime)

      const makeOrders = project.isLeadTimeLocked ? JSON.parse(project.lockedMakeOrders) : leadTimePricePair.makeOrders
      if(projectIsAleadyBooked(props.project)){
        openEditProjectModal(project, makeOrders)
        return Promise.resolve()
      }
      return placeOrderAsDme(project, makeOrders)
        .then(projectToSave => saveProjectAndNestedObjects(projectToSave))
        .then(() => {
          props.navigateToSummaryPage(props.project.projectNumber)
        })
    } else {
      if(paymentMethod === 'po'){
        project.paymentReferenceNumber = paymentPO
      }

      return placeOrderAsCustomer(project, paymentMethod, paymentPO, token)
        .then(() => {
          props.navigateToConfirmationPage()
        })
    }
  }

  const finishPlacingOrder = async () => {
    const isRebooking = true
    placeOrderAsDme(projectToMaybeSave, makeOrdersToMaybeSave, isRebooking)
      .then(projectToSave => {
        return saveProjectAndNestedObjects(projectToMaybeSave)
      })
      .then(() => {
        props.navigateToSummaryPage(projectToMaybeSave.projectNumber)
      })
  }

  const applyCustomerAddressToProject = () => {
    props.setProject(
      {
        ...props.project,
        shipName: props.project.customer.shippingContact,
        shipCompany: props.project.customer.shippingCompany,
        shipAddress: props.project.customer.shippingAddress,
        shipEmail: props.project.customer.shippingEmail,
        shipPhone: props.project.customer.shippingPhone,
      }
    )
  }

  const onChangeShippingPriceOverride = ev => {
    const projectCopy = {...props.project}

    if(ev.target.checked === false){
      projectCopy.isCustomShippingMethodUsed = false // must use custom price for custom shipping method
      projectCopy.overrides = {
        ...projectCopy.overrides,
        shippingPrice: "calculated",
      }
    }

    if(ev.target.checked === true){
      projectCopy.overrides = {
        ...projectCopy.overrides,
        shippingPrice: "custom",
      }
    }

    props.setProject(projectCopy, { saveProject: true })
  }

  const setPromoCodeData = (promoCode, promoCodePercentOff) => {
    props.setProject(
      {
        ...props.project,
        promoCode,
        promoCodePercentOff,
      },
      { saveProject: true }
    )
  }

  const getOrderDate = () => {
    const orderDate = props.project.orderDate ? props.project.orderDate : getOrderDateFromCurrentTime()
    return orderDate
  }

  const openEditProjectModal = (projectToSave, makeOrdersToSave) => {
    setProjectToMaybeSave(projectToSave)
    setMakeOrdersToMaybeSave(makeOrdersToSave)
    setIsEditProjectModalOpen(true)
  }

  const ORDER_DATE = getOrderDate()
  return(
    <Fragment>
      <AppBar position="sticky">
        <AppToolBar changeUserType={props.changeUserType} changeUserloggedIn={true} showHomeLink={true} user={props.user}/>
        {projectIsAleadyBooked(props.project) ?
         <div className="editing-project-bar">
           EDITING BOOKED PROJECT - you must checkout again
         </div>
         : null}
      </AppBar>
        <div className={props.classes.container}>
          <Typography color="textPrimary" variant="h4">
            Checkout - Project {props.project.projectNumber}
          </Typography>
          <div style={{display:'flex',justifyContent:'space-between'}}>
            <div>
              <AccordionSection
                expanded={checkoutStep === 1}
                header="1. Shipping address"
                onClick={(e)=>{nextStepHandler(1)}}
              >
                <ShippingAddressExpansionDetails
                  applyCustomerAddressToProject={applyCustomerAddressToProject}
                  checkForExistingShipAddress={checkForExistingShipAddress}
                  editShipDetails={editShipDetails}
                  fieldsToHighlight={shippingAddressFieldsToHighlight}
                  isSavingAddress={props.isSavingAddress}
                  nextStepHandler={nextStepHandler}
                  project={props.project}
                  saveCustomerHandler={saveCustomerHandler}
                  setProjectField={setProjectField}
                  shipAddressReadOnly={shipAddressReadOnly}
                  updateCustomerAddressHandler={updateCustomerAddressHandler}
                  user={props.user}
                />
              </AccordionSection>

              <AccordionSection
                disabled={checkoutStep < 2}
                expanded={checkoutStep === 2}
                header="2. Shipping method and delivery date"
                onClick={(e)=>{nextStepHandler(2)}}
              >
                <ShippingDetailsExpansionDetails
                  areShippingOptionsLoading={areShippingOptionsLoading}
                  fieldsToHighlight={shippingMethodFieldsToHighlight}
                  formatShippingLabel={formatShippingLabel}
                  nextStepHandler={nextStepHandler}
                  project={props.project}
                  setShipVia={setShipVia}
                  setProjectFieldAndSave={setProjectFieldAndSave}
                  shippingOptions={getShippingOptions(shippingOptions, props.project)}
                  shipVia={shipVia}
                  user={props.user}
                />
              </AccordionSection>

              <AccordionSection
                disabled={checkoutStep < 3}
                expanded={checkoutStep === 3}
                header="3. Payment Information"
                onClick={(e)=>{nextStepHandler(3)}}
              >
                <PaymentInformationExpansionDetails
                  billingFieldsToHighlight={billingFieldsToHighlight}
                  nextStepHandler={nextStepHandler}
                  paymentMethod={paymentMethod}
                  paymentPO={paymentPO}
                  project={props.project}
                  saveTokenHandler={saveTokenHandler}
                  setPaymentMethod={setPaymentMethod}
                  setPaymentPO={setPaymentPO}
                  setProjectFieldAndSave={setProjectFieldAndSave}
                  stripe={stripe}
                  subTotal={calculateProjectTotalSale({...props.project}, props.selectedPricingData, shippingOptions)}
                  user={props.user}
                />
              </AccordionSection>

              <AccordionSection
                disabled={checkoutStep < 4}
                expanded={checkoutStep === 4}
                header="4. Review"
                onClick={(e)=>{nextStepHandler(4)}}
              >
                <ReviewExpansionDetails
                  areShippingOptionsLoading={areShippingOptionsLoading}
                  MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
                  selectedPricingData={props.selectedPricingData}
                  postProcessData={props.postProcessData}
                  project={props.project}
                  shippingOptions={getShippingOptions(shippingOptions, props.project)}
                />
              </AccordionSection>
            </div>
            <OrderSummaryDiv
              areShippingOptionsLoading={areShippingOptionsLoading}
              checkoutStep={checkoutStep}
              downloadQuote={downloadQuote}
              makeInvoiceThunk={makeInvoiceThunk}
              navigateToConfirmationPage={props.navigateToConfirmationPage}
              onChangeShippingPriceOverride={onChangeShippingPriceOverride}
              openSnackBarWithMessage={openSnackBarWithMessage}
              orderDate={ORDER_DATE}
              placeOrderThunk={placeOrderThunk}
              project={props.project}
              quotePrefix={quotePrefix}
              selectedPricingData={props.selectedPricingData}
              setProjectFieldAndSave={setProjectFieldAndSave}
              setPromoCodeData={setPromoCodeData}
              setQuotePrefix={setQuotePrefix}
              shippingOptions={getShippingOptions(shippingOptions, props.project)}
              user={props.user}
            />
          </div>
        </div>
      <EditProjectModal
        finishPlacingOrder={finishPlacingOrder}
        isOpen={isEditProjectModalOpen}
        openSnackBarWithMessage={openSnackBarWithMessage}
        project={props.project}
        projectToMaybeSave={projectToMaybeSave}
        setIsEditProjectModalOpen={setIsEditProjectModalOpen}
      />
    </Fragment>
  )
}

function EditProjectModal({finishPlacingOrder, isOpen, openSnackBarWithMessage, project, projectToMaybeSave, setIsEditProjectModalOpen}){
  if(!isOpen){
    return null //prevent rendering as hidden modal
  }

  const previouslyBookedProject = JSON.parse(project.previouslyBookedProject)
  const projectDifferences = getProjectDifferences(previouslyBookedProject, projectToMaybeSave)
  const partDifferences = getPartGroupDifferences(previouslyBookedProject.partGroups, projectToMaybeSave.partGroups)

  return(
    <Modal
      onClose={() => setIsEditProjectModalOpen(false)}
      open={isOpen}
      style={{ overflow: 'scroll' }}
    >
      <Paper style={{
        marginLeft: '20%',
        marginTop: '10%',
        padding: '2em',
        width: '60%',
      }}>
        <Typography variant="h5">
          Project Differences
        </Typography>
        <DiffTable
          diffs={projectDifferences}
        />
        <br/>
        <DiffsTable
          itemType="Part"
          nestedDiffs={partDifferences}
        />
        <br/>
        <div style={{ display: 'flex', justifyContent: 'end' }} >
          <UpdateProjectButton
            onClickProm={finishPlacingOrder}
            openSnackBarWithMessage={openSnackBarWithMessage}
          />
        </div>
      </Paper>
    </Modal>
  )
}

function UpdateProjectButton({ onClickProm, openSnackBarWithMessage }){
  const [ isUpdating, setIsUpdating ] = useState(false)

  const onClick = () => {
    setIsUpdating(true)
    onClickProm()
      .catch(e => openSnackBarWithMessage(e.message))
      .finally(() => setIsUpdating(true))
  }

  return(
    <Button
      color="secondary"
      onClick={onClick}
      variant="contained"
    >
      {isUpdating ? 'Updating...' : 'Update Project'}
    </Button>
  )
}

function DiffsTable({ itemType, nestedDiffs }){
  if(nestedDiffs.flatMap(nd => nd.diffs).length === 0){
    return null // Dont show header if there are no items
  }
  return(
    <>
      <Typography variant="h5">
        {itemType} Differences
      </Typography>
      {nestedDiffs.map(({diffs, partName}) => {
        if(diffs.length === 0){
          return null
        }
        return(
          <NestedDiffTable
            diffs={diffs}
            itemName={partName}
            itemType={itemType}
          />
        )
      })}
    </>
  )
}

function NestedDiffTable({diffs, itemName, itemType}){
  return(
    <>
      <Typography variant="h6">
        {itemType} {itemName}
      </Typography>
      <DiffTable
        diffs={diffs}
      />
    </>
  )
}

function DiffTable({diffs}){
  return(
    <Table>
      <TableHead>
        <TableRow style={{ display: 'flex', justifyContent: 'space-evenly', background: 'lightgray' }}>
          <TableCell style={{width:'10em'}}> Field </TableCell>
          <TableCell style={{width:'30em'}}> Before </TableCell>
          <TableCell style={{width:'30em'}}> After </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {diffs.map(({field, oldVal, newVal}) => {
          return(
            <TableRow key={field} style={{ display: 'flex', justifyContent: 'space-evenly'}}>
              <TableCell style={{width:'10em', wordWrap:'break-word'}}> {field} </TableCell>
              <TableCell style={{width:'30em', wordWrap:'break-word'}}> {oldVal} </TableCell>
              <TableCell style={{width:'30em', wordWrap:'break-word'}}> {newVal} </TableCell>
            </TableRow>
          )
        })
        }
      </TableBody>
    </Table>
  )
}

function getShippingOptionsData(project, selectedPricingData){
  let firstShipDate
  if(project.isLeadTimeLocked){
    const dateFormat = "MM/DD/YYYY"
    const orderDate = project.orderDate ? project.orderDate : getOrderDateFromCurrentTime()
    const leadTime = project.lockedSampleLeadTimeDays > 0 ? project.lockedSampleLeadTimeDays : project.lockedProductionLeadTimeDays
    firstShipDate = calculateShipDateFromOrderDateAndLeadTimeDays(orderDate, dateFormat, leadTime).format(dateFormat)
  } else {
    const leadTimePricePair = selectedPricingData.pricingData.leadTimePricePairs
          .find(ltpp => ltpp.price === selectedPricingData.selectedLeadTime)
    firstShipDate = leadTimePricePair.productionShipDate ? leadTimePricePair.productionShipDate : leadTimePricePair.sampleShipDate
  }

  return getShippingOptionsArray(project, firstShipDate)
}

let latestTask
function debounceProm(prom, timeout = 300){
  return new Promise (async function (resolve, reject) {
    const taskId = uuid()
    latestTask = taskId
    await sleep(timeout)
    if(latestTask === taskId){
      resolve(prom())
    }
  })
}

function decorateProjectWithDates(project, orderDate, shippingOptions){
  const shipOptionSelected = shippingOptions.find(option => {
    const method = project.isCustomShippingMethodUsed ? 'custom' : project.paidShipMethod
    return (option.shipMethod === method)
  })

  project.orderDate = orderDate
  project.paidShipDate = shipOptionSelected.shipDate
  project.paidArrivalDate = shipOptionSelected.arrivalDate

  return project
}

function decorateProjectWithPaymentLocation(project, paymentMethod){
  if(paymentMethod === 'credit'){
    project.paymentLocation = 'CC - Quote Tool'
  }
  if(paymentMethod === 'po'){
    project.paymentLocation = 'PO'
  }

  return project
}

function decorateProjectWithPricingData(project, selectedPricingData, shippingOptions){
  project.partGroups = decoratePartGroupsWithToleranceClassifications(project.partGroups, selectedPricingData.pricingData.toleranceClassifications)
  project.partGroups = decoratePartGroupsWithPrices(project.partGroups, selectedPricingData.pricingData, project.isLeadTimeLocked)

  project.totalSale = calculateProjectTotalSale(project, selectedPricingData, shippingOptions)

  return project
}

function calculateProjectTotalSale(project, selectedPricingData, shippingOptions){
  if(project.isLeadTimeLocked){
    project.samplesLeadTime = project.lockedSampleLeadTimeDays
    project.paidLeadTime = project.lockedProductionLeadTimeDays

    project.expediteFee = project.lockedLeadTimeExpediteFee

    project.subTotal = calculateLockedProjectTotal(project)
  } else {
  const leadTimePricePair = selectedPricingData.pricingData.leadTimePricePairs
        .find(ltpp => ltpp.price === selectedPricingData.selectedLeadTime)

  const cheapestLtpp = selectedPricingData.pricingData.leadTimePricePairs[0] // pricing engine returns sorted array
  const expediteFee = leadTimePricePair.price - cheapestLtpp.price

  project.samplesLeadTime = leadTimePricePair.sampleLeadTime // locked sample lead time
  project.paidLeadTime = leadTimePricePair.productionLeadTime // locked prod lead time

  project.expediteFee = expediteFee // lockedExpediteFee
  project.subTotal = leadTimePricePair.price // calculatedLockedTotal(project)
  }

  const shippingOption = shippingOptions.find(option => {
    const method = project.isCustomShippingMethodUsed ? 'custom' : project.paidShipMethod
    return (option.shipMethod === method)
  })
  const shippingPrice = project.overrides.shippingPrice === 'custom' ?
        project.customShippingPrice : (shippingOption ? shippingOption.cost : 0)

  project.shippingRevenue = shippingPrice

  project.discount = 0
  if(project.promoCodePercentOff > 0){
    project.discount = (project.subTotal * project.promoCodePercentOff / 100).toFixed(2)
  }

  return +project.subTotal + +shippingPrice - project.discount
}

function decoratePartGroupsWithPrices(partGroups, pricingData, isLeadTimeLocked){
  return partGroups.map(ptg => {
    const partGroupPrice = pricingData.partGroupPrices
          .find(pgp => pgp.partGroupNumber === ptg.partGroupNumber)

    ptg.unitPrice = isLeadTimeLocked ? ptg.lockedUnitPrice : partGroupPrice.unitPrice
    if(partGroupPrice.cavityCount){
      ptg.cavityCount = partGroupPrice.cavityCount
    }
    if(partGroupPrice.material){
      ptg.materialId = partGroupPrice.material
      ptg.material = ptg.materialId
    }
    if(partGroupPrice.guaranteedLife){
      ptg.actualGuaranteedLife = partGroupPrice.guaranteedLife
    }

    ptg.productionToolPartConfigurations = ptg.productionToolPartConfigurations
      .filter(ptpc => {
        return ptpc.processId === ptg.processId
      })
      .map(ptpc => {
        const ptpcPrice = pricingData.ptpcPrices
              .find(ptpcp => ptpcp.productionToolPartConfigurationId === ptpc.productionToolPartConfigurationId)

        ptpc.unitPrice = isLeadTimeLocked ? ptpc.lockedUnitPrice : ptpcPrice.unitPrice

        return ptpc
      })

    return ptg
  })
}

function decoratePartGroupsWithToleranceClassifications(partGroups, toleranceClassifications){
  return partGroups.map(partGroup => {
    partGroup.tolerances = decorateTolerancesWithClassificationData(partGroup.tolerances, toleranceClassifications)
    return partGroup
  })

  function decorateTolerancesWithClassificationData(tolerances, toleranceClassifications){
    return tolerances.map(tolerance => {
      const toleranceClassification = toleranceClassifications
            .find(tc => tc.id === tolerance.id)

      tolerance.classification = toleranceClassification.classification
      tolerance.revisedLowerBound = toleranceClassification.revisedLowerBound
      tolerance.revisedUpperBound = toleranceClassification.revisedUpperBound

    return tolerance
    })
  }
}

function getShippingOptions(shippingOptions, project){
  return shippingOptions.map(option => {
    const optionCopy = {...option}
    if(project.shipAccountNumber !== 'Autotiv'){
      optionCopy.cost = 0
    }
    return optionCopy
  })
}

const AccordionSection = ({ children, disabled, expanded, header, onClick }) => {
  return(
    <Accordion expanded={expanded} disabled={disabled}>
      <AccordionSummary onClick={onClick}>
        <Typography variant="h6">{ header }</Typography>
      </AccordionSummary>
      <AccordionDetails>
      { children }
      </AccordionDetails>
    </Accordion>
  )
}

const STYLES = theme => ({
  container:{
    marginBottom: theme.spacing(4),
    marginLeft: '10vw',
    marginRight: '10vw',
    marginTop: '5vh'
  },
  logo:{
    height: '40px'
  }
})

export default withStyles(STYLES)(withProject(Checkout))
