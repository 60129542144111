import modelViewerInstance from './modelViewerInstance.js'
import { downloadWallThicknessFiles } from '../downloadWallThicknessFiles.js'
import calculateWallThicknessPercentage from './calculateWallThicknessPercentage.js'
import {TAB_LABELS} from './optionsConfig.js'
import {partAnalysisStatus} from '../../../utils'

const MATERIAL_TYPES = {
  model:'model',
  wallThickness:'wallThickness',
  wallThicknessWithArrows:'wallThicknessWithArrows',
}

export default async function partGroupViewerInstance(el, partGroup, postProcessData){
  const { plainGlbArrayBuffer, glbArrayBuffer } = await downloadWallThicknessFiles(partGroup, postProcessData)

  const { toggleArrowViewObjectsOn, toggleArrowViewObjectsOff, updateModelWithDefaultMaterial, updateModelWithMaterial, ...npv } = await modelViewerInstance(el, partGroup.part.units, plainGlbArrayBuffer, glbArrayBuffer)

  const defaultMaterial = glbArrayBuffer ? MATERIAL_TYPES.wallThickness : MATERIAL_TYPES.model
  const defaultMode = await changeMaterialType(defaultMaterial) // set the default material to show before returning to the user

  const {
    NEEDS_WALL_THICKNESS: needsWallThickness,
    WALL_THICKNESS_TASK_FINISHED: wallThicknessTaskFinished,
    HAD_ISSUES_ANALYZING_WALL_THICKNESS: wallThicknessTaskHadError,
  } = partAnalysisStatus(partGroup, postProcessData.POST_PROCESS_OPTIONS)
  const wallThicknessPercentage = calculateWallThicknessPercentage(partGroup)
  const wallThicknessDisplay = (wallThicknessPercentage > 0 && wallThicknessPercentage < 0.01) ? "<0.01" : wallThicknessPercentage.toFixed(2)

  const listItemsData = [
    {
      content: null,
      contentStyle: null,
      isDisplayed: true,
      label: 'Model View',
      materialType: MATERIAL_TYPES.model,
    },
    {
      content: `${wallThicknessDisplay}% of part has thin walls`,
      contentStyle: (wallThicknessPercentage > 0) ? {color:'red'} : null,
      isDisplayed: needsWallThickness && wallThicknessTaskFinished && !wallThicknessTaskHadError,
      label: 'Wall Thickness',
      materialType: MATERIAL_TYPES.wallThickness,
    },
    {
      content: `Arrows point out thin wall sections`,
      contentStyle: {color:'red'},
      isDisplayed: needsWallThickness && wallThicknessTaskFinished && !wallThicknessTaskHadError && wallThicknessPercentage > 0,
      label: 'Arrows',
      materialType: MATERIAL_TYPES.wallThicknessWithArrows,
    },
  ]

  return {
    defaultMaterial,
    defaultMode,
    newPartViewer: {
      ...npv,
      changeMaterialType,
      listItemsData,
      viewingModes: Object.values(TAB_LABELS)
    }
  }

  async function changeMaterialType(materialType){
    if(materialType === MATERIAL_TYPES.wallThicknessWithArrows){
      toggleArrowViewObjectsOn()
    } else {
      toggleArrowViewObjectsOff()
    }

    if(materialType === MATERIAL_TYPES.wallThickness || materialType === MATERIAL_TYPES.wallThicknessWithArrows){
      updateModelWithMaterial()
    }
    else if(materialType === MATERIAL_TYPES.model){
      updateModelWithDefaultMaterial()
    }

    const defaultTabIndexWhenChangingMaterials = TAB_LABELS.solid.tabIndex
    return defaultTabIndexWhenChangingMaterials
  }
}

