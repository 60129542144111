import {useEffect,useState} from 'react'
import {
  Button,
  IconButton,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import ActionLink from '../Common/ActionLink.js'
import createSourcingAttempt from '../../utils/createSourcingAttempt.js'
import DeleteIcon from '@material-ui/icons/Delete'
import deleteSourcingAttempt from '../../utils/deleteSourcingAttempt.js'
import downloadFromS3AndSaveFile from '../../utils/downloadFromS3AndSaveFile.js'
import FORMS from './FORMS.js'
import generatePurchasingReport from './PaperworkForm/generatePurchasingReport.js'
import getFileFromBase64 from '../../utils/getFileFromBase64.js'
import { LoadingPage } from '../Common'
import loadSourcingAttempts from '../../utils/loadSourcingAttempts.js'
import loadSuppliers from './loadSuppliers.js'
import {navigate} from "@reach/router"
import { NumberField } from "../QuoteTool/NumberField.js"
import PartGroupsSummaryTableBody from '../Common/PartGroupsSummaryTableBody.js'
import PdfViewer from '../Common/PdfViewer.js'
import updateSourcingAttempt from '../../utils/updateSourcingAttempt.js'
import * as s3 from '../QuoteTool/cloudObjectStorage'

import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'

const TABLE_HEADERS = [
  'Supplier',
  'Next Five Days Open',
  'Working Start Time',
  'Working End Time',
  'Status',
  'Counter-Bid',
  'Hours To Source',
  'Time-out Start Time',
  'Time-out End Time',
  'Job Offer File Name',
  'G-Drive',
  'Supplier Link',
  'Delete',
  'Sort',
]

export default function SourcingPage({
  changeForms,
  errorMessage,
  MATERIAL_TRANSLATIONS,
  onChangeProjectFieldHandler,
  postProcessData,
  project,
  sourcingMakeOrderId,
  sourcingMakeOrderNumber,
}){
  const [ hoursToSource, setHoursToSource ] = useState('')
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ isPartTableCollapsed, setIsPartTableCollapsed ] = useState(true)
  const [ jobOfferPdf, setJobOfferPdf ] = useState(null)
  const [ sourcingAttempts, setSourcingAttempts ] = useState([])
  const [ suppliers, setSuppliers ] = useState([])
  const [ supplierId, setSupplierId ] = useState('')

  useEffect(() => {
    if(suppliers.length > 0){ return }

    loadSuppliers()
      .then(suppliers => {
        setSuppliers(suppliers)
        setSupplierId(suppliers[0].id)
      })
      .catch(err => {
        errorMessage(err.message)
      })
  }, ['ON-MOUNT'])

  const setSortedSourcingAttempts = (sourcingAttempts) => {
    const startedSourtingAttempts = sourcingAttempts
          .filter(sa => sa.status !== 'Waiting')

    const waitingSourtingAttempts = sourcingAttempts
          .filter(sa => sa.status === 'Waiting')
          .sort((saA, saB) => saA.sortOrder - saB.sortOrder)

    const sortedSourceAttempts = [...startedSourtingAttempts, ...waitingSourtingAttempts]

    const decoratedSourcingAttempts = sortedSourceAttempts
          .map((sa, index) => {
            sa.sortOrder = +index + 1
            return sa
          })

    setSourcingAttempts(decoratedSourcingAttempts)
  }

  const {canSortDown, canSortUp, sortDown, sortUp} = sortFactory(sourcingAttempts, setSortedSourcingAttempts, errorMessage)

  useEffect(() => {
    loadSourcingAttempts(sourcingMakeOrderId)
      .then(sourcingAttempts => {
        setSortedSourcingAttempts(sourcingAttempts)
        setIsLoaded(true)
      })
      .catch(err => {
        errorMessage(err.message)
      })
  }, ['ON-MOUNT'])

  const getJobOfferFileName = (supplierName) => {
    return `Job Offer ${sourcingMakeOrderNumber}.pdf`
  }

  const makeJobOfferHandler = async () => {
    const supplier = suppliers.find(s => s.id === supplierId)

    const file = await getFileFromBase64(jobOfferPdf, getJobOfferFileName(supplier.supplierName))
    const jobOfferS3Id = await s3.uploadFileToS3(file)

    const newSourcingAttemptPayload = {
      makeOrderId: sourcingMakeOrderId,
      name: sourcingMakeOrderNumber,
      supplierId,
      status: 'Waiting',
      hoursToSource,
      jobOfferS3Id,
      sortOrder: sourcingAttempts.length + 1,
      supplier,
    }

    const newSourcingAttempt = await createSourcingAttempt(newSourcingAttemptPayload)

    const sourcingAttemptsCopy = [...sourcingAttempts]
    sourcingAttemptsCopy.push(newSourcingAttempt)
    setSortedSourcingAttempts(sourcingAttemptsCopy)
    setIsModalOpen(false)
  }

  const makeJobOfferPdf = async () => {
    const projectWithThisSelectedSupplier = project
    const makeOrders = projectWithThisSelectedSupplier.makeOrders
    const makeOrder = makeOrders.find(mo => mo.id === sourcingMakeOrderId)
    const supplier = suppliers.find(s => s.id === supplierId)
    makeOrder.supplier = [{
      id: supplier.id,
      identifier: supplier.supplierName,
    }]

    return generatePurchasingReport(projectWithThisSelectedSupplier, sourcingMakeOrderNumber)
  }

  const onChangeSourcingNotes = (sourcingNotes) => {
    // TODO
    const name = 'makeOrders'
    const value = project.makeOrders
          .map(mo => {
            if(mo.id === sourcingMakeOrderId){
              mo.sourcingNotes = sourcingNotes
            }
            return mo
          })

    onChangeProjectFieldHandler({target:{name,value}}, true)
  }

  const deleteSourcingAttemptHandler = async (sourcingAttemptId) => {
    await deleteSourcingAttempt(sourcingAttemptId)
      .then(() => {
        const newSourcingAttempts = sourcingAttempts
              .filter(sa => sa.id !== sourcingAttemptId)
        setSortedSourcingAttempts(newSourcingAttempts)
      })
      .catch(err => errorMessage(err.message))
  }

  if(!isLoaded || !supplierId){
    return (
      <LoadingPage/>
    )
  }

  return(
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div>
        <Button onClick={() => changeForms(FORMS.activities)} variant="contained" style={{margin: '0.5em'}}>
          Back to Shipment Tracking Page
        </Button>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1em'}}>
        <Typography variant="h5">
          Sourcing Attempts for Make Order {sourcingMakeOrderNumber}
        </Typography>
        <NewSourcingAttemptPaper
          hoursToSource={hoursToSource}
          isModalOpen={isModalOpen}
          jobOfferPdf={jobOfferPdf}
          makeJobOfferHandler={makeJobOfferHandler}
          makeJobOfferPdf={makeJobOfferPdf}
          setIsModalOpen={setIsModalOpen}
          setHoursToSource={setHoursToSource}
          setJobOfferPdf={setJobOfferPdf}
          setSupplierId={setSupplierId}
          sourcingAttempts={sourcingAttempts}
          supplierId={supplierId}
          suppliers={suppliers}
        />
        <div style={{backgroundColor:'lightgray', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Button
            color="primary" onClick={() => setIsPartTableCollapsed(!isPartTableCollapsed)}
          >
            Toggle Parts Table
          </Button>
          {isPartTableCollapsed ? null :
           <PartGroupsSummaryTableBody
             MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
             partGroups={project.partGroups}
             postProcessData={postProcessData}
           />
          }
        </div>
        <div
          style={{border: '1px solid gray', padding: '1em'}}
        >
          <TextField
            label="Sourcing Notes"
            multiline
            onChange={e => onChangeSourcingNotes(e.target.value)}
            rows={1}
            rowsMax={6}
            style={{width: '41vw'}}
            value={project.makeOrders.find(m => m.id === sourcingMakeOrderId).sourcingNotes}
          />
        </div>
        <div>
          <Table style={{border:'2px solid black'}}>
            <TableHead style={{backgroundColor:'gray'}}>
              <TableRow>
                {TABLE_HEADERS.map(h => <TableCell children={h} key={h} align="center"/>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {sourcingAttempts
               .sort((saA, saB) => saA.sortOrder - saB.sortOrder)
               .map((sa,index) => {

                const supplier = suppliers.find(s => s.id === sa.supplierId)
                const style = {}
                if(index % 2 !== sourcingAttempts.length % 2){
                  style.backgroundColor = 'lightgray'
                }
                return(
                  <TableRow style={style}>
                    <TableCell align="center">
                      {supplier.supplierName}
                    </TableCell>
                    <TableCell align="center">
                      <div style={{height: '1.5em', display: 'flex', justifyContent: 'space-evenly'}}>
                        {supplier.nextFiveDaysOpen.map(
                          (day) => <div style={{width: '1.5em', border: '1px solid black', fontWeight: 'bold', backgroundColor: day.isOpen ? 'green' : 'red'}}>{day.day}</div>
                        )}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {getWorkingHoursString(supplier.workingHoursStartTime, supplier.timezone, true)}
                    </TableCell>
                    <TableCell align="center">
                      {getWorkingHoursString(supplier.workingHoursEndTime, supplier.timezone, true)}
                    </TableCell>
                    <TableCell align="center">
                      <b style={sa.status === 'Declined' ? {color:'red'} : {}}>{sa.status}</b>
                    </TableCell>
                    <TableCell align="center">
                      { sa.status === 'Counter-Bid' ?
                        <div style={{display:'flex',alignItems:'baseline',gap:'0.25em'}}>
                          $
                          <TextField
                            /* onChange={() => {}} */
                            disabled
                            style={{width: '5.25em'}}
                            value={sa.counterOfferValue}
                          />
                        </div>
                       : null }
                    </TableCell>
                    <TableCell align="center">
                      {sa.hoursToSource}
                    </TableCell>
                    <TableCell align="center">
                      {formatToLocalTime(sa.supplierReviewStartTime)}
                    </TableCell>
                    <TableCell align="center">
                      {formatToLocalTime(sa.supplierReviewEndTime)}
                    </TableCell>
                    <TableCell align="center">
                      <ActionLink
                        action={() => downloadFromS3AndSaveFile(sa.jobOfferS3Id, getJobOfferFileName(supplier.supplierName))}
                        label={getJobOfferFileName(supplier.supplierName)}
                        theme="secondary"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <a href={project.projectFolderLink} target="_blank" rel="noopener noreferrer">
                        Drive
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      <a href={`/job/${sa.id}?code=${sa.code}`} target="_blank" rel="noopener noreferrer">
                        Supplier Page
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      { sa.status === 'Waiting' ?
                        <IconButton onClick={() => deleteSourcingAttemptHandler(sa.id)}>
                          <DeleteIcon/>
                        </IconButton>
                        : null }
                    </TableCell>
                    <TableCell align="center" style={{display: 'flex', flexDirection: 'column'}}>
                      { canSortUp(index) ?
                        <IconButton onClick={() => sortUp(index)} style={{ padding: 0 }}>
                          <ExpandLess/>
                        </IconButton>
                        : null }
                      { canSortDown(index) ?
                        <IconButton onClick={() => sortDown(index)} style={{ padding: 0 }}>
                          <ExpandMore/>
                        </IconButton>
                        : null }
                    </TableCell>
                  </TableRow>
                )})
              }
            </TableBody>
          </Table>
        </div>
      </div>
      <div style={{height: '25vh'}}/>
    </div>
  )
}

const localTimeZone = 'America/New_York'

function formatToLocalTime(timeString){
  if(timeString){
    console.log({timeString})
    return formatInTimeZone(timeString, localTimeZone, 'E h:mm b zzz')
  }
  return ''
}

function sortFactory(sourcingAttempts, setSourcingAttempts, errorMessage){
  const sortableAttemptIds = sourcingAttempts
        .map((_sa, index) => index)
        .filter(index => {
          const sourcingAttempt = sourcingAttempts[index]
          return sourcingAttempt.status === 'Waiting'
        })

  const canSortDownIds = sortableAttemptIds.slice(0, sortableAttemptIds.length - 1)
  const canSortUpIds = sortableAttemptIds.slice(1, sortableAttemptIds.length)

  const canSortDown = index => canSortDownIds.includes(index)
  const canSortUp = index => canSortUpIds.includes(index)

  const swapSortOrders = async (indexA, indexB) => {
    const sourcingAttemptsCopy = JSON.parse(JSON.stringify(sourcingAttempts))

    const sortOrderA = sourcingAttemptsCopy[indexA].sortOrder
    const sortOrderB = sourcingAttemptsCopy[indexB].sortOrder

    sourcingAttemptsCopy[indexA].sortOrder = sortOrderB
    sourcingAttemptsCopy[indexB].sortOrder = sortOrderA

    await (
      Promise.all([
        updateSourcingAttempt(sourcingAttemptsCopy[indexA]),
        updateSourcingAttempt(sourcingAttemptsCopy[indexB])
      ])
        .catch(e => errorMessage(e.message))
    )

    setSourcingAttempts(sourcingAttemptsCopy)
  }

  const sortDown = index => {
    // swap the sort order of this one and the one beneath (1 higher) than it
    swapSortOrders(index, index+1)
  }
  const sortUp = index => {
    // swap the sort order of this one and the one above (1 lower) than it
    swapSortOrders(index, index-1)
  }

  return {
    canSortDown,
    canSortUp,
    sortDown,
    sortUp
  }
}

function NewSourcingAttemptPaper({hoursToSource, isModalOpen, jobOfferPdf, makeJobOfferHandler, makeJobOfferPdf, setHoursToSource, setIsModalOpen, setJobOfferPdf, setSupplierId, sourcingAttempts, supplierId, suppliers}){
  let supplier = suppliers.find(s => s.id === supplierId)
  supplier = supplier ? supplier : {}

  return(
    <Paper style={{padding: '1em', border: '1px solid black', display: 'flex', flexDirection: 'column', gap: '1em'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Typography variant="h6">
          Create Sourcing Attempt
        </Typography>
      </div>
      <div style={{}} className="st-sourcing-page-supplier-data-row">
        <Typography>
          <b>Supplier:</b>
        </Typography>
        <Select
          native
          style={{width:"100%"}}
          onChange={e => setSupplierId(e.target.value)}
          value={supplierId}
        >
          {
            suppliers.map(s => <option key={s.id} value={s.id}>{s.supplierName}</option>)
          }
      </Select>
      </div>
      <div className="st-sourcing-page-supplier-data-row">
        <Typography>
          <b>Sourcing Email:</b> {supplier.supplierEmail ? supplier.supplierEmail : 'data missing'}
        </Typography>
      </div>
      <div className="st-sourcing-page-supplier-data-row">
        <Typography>
          <b>Workday Start Time:</b> {getWorkingHoursString(supplier.workingHoursStartTime, supplier.timezone, true)}
        </Typography>
      </div>
      <div className="st-sourcing-page-supplier-data-row">
        <Typography>
          <b>Workday End Time:</b> {getWorkingHoursString(supplier.workingHoursEndTime, supplier.timezone, true)}
        </Typography>
      </div>
      <div className="st-sourcing-page-supplier-data-row">
        <Typography>
          <b>Typical Workdays:</b> { supplier.daysOfTheWeekOpen ? supplier.daysOfTheWeekOpen.split(',').join(', ') : 'data missing' }
        </Typography>
      </div>
      <div style={{}} className="st-sourcing-page-supplier-data-row">
        <Typography>
          <b>Number of Hours to Source:</b>
        </Typography>
        <NumberField
          onChange={e => setHoursToSource(e.target.value)}
          variant="standard"
          value={hoursToSource}
        />

      </div>
      <JobOfferPaperwork
        isModalOpen={isModalOpen}
        jobOfferPdf={jobOfferPdf}
        makeJobOfferHandler={makeJobOfferHandler}
        makeJobOfferPdf={makeJobOfferPdf}
        setIsModalOpen={setIsModalOpen}
        setJobOfferPdf={setJobOfferPdf}
      />
    </Paper>
  )
}

function dateToday(){
  const today = new Date()
  return today.getDate()
}

function getWorkingHoursString(hourOfTheDay, timeZone, includeLocalTimezone){
  if(!hourOfTheDay || !timeZone){
   return 'data missing'
  }

  const timeZoneHoursOffset = getTimeZoneHoursOffset(localTimeZone, timeZone)

  if(includeLocalTimezone){
    return `${getTimeFromStartTime(hourOfTheDay, localTimeZone, timeZoneHoursOffset)} (${getTimeFromStartTime(hourOfTheDay, timeZone, timeZoneHoursOffset)})`
  } else {
    return getTimeFromStartTime(hourOfTheDay, timeZone, timeZoneHoursOffset)
  }
}

function getTimeZoneHoursOffset(localTimeZone, timeZone){
  const timeZoneOffsetMs = getTimezoneOffset(timeZone) - getTimezoneOffset(localTimeZone)
  const timeZoneOffsetHours = timeZoneOffsetMs / 3600000

  return timeZoneOffsetHours
}

function getTimeFromStartTime(hour = 0, timeZone, hourOffset){
  const now = new Date()

  const hourTime = getTimeAtHour(hour, timeZone, 0, hourOffset)
  const hourTimeTomorrow = getTimeAtHour(hour, timeZone, 1, hourOffset)

  const time = hourTime > now ? hourTime : hourTimeTomorrow

  const tz = timeZone ? timeZone : 'America/New_York'
  return formatInTimeZone(time, tz, 'h:mm b zzz')
}

function getTimeAtHour(hour, timeZone, dayOffset = 0, hourOffset = 0){
  const now = new Date()

  const year = now.getFullYear()
  const monthIndex = now.getMonth()
  const day = +now.getDate() + +dayOffset
  const hours = +hour - +hourOffset
  const minutes = 0
  const seconds = 0
  const milliseconds = 0

  const newTime = new Date(year, monthIndex, day, hours, minutes, seconds, milliseconds)

  return newTime
}

function JobOfferPaperwork({isModalOpen, jobOfferPdf, makeJobOfferHandler, makeJobOfferPdf, setIsModalOpen, setJobOfferPdf}){
  const [ isLoading, setIsLoading ] = useState(false)
  const onClickMakeJobOffer = async () => {
    setIsLoading(true)
    const jobOfferPdf = await makeJobOfferPdf()
    setJobOfferPdf(jobOfferPdf)
    setIsModalOpen(true)
    setIsLoading(false)
  }

  return(
    <>
      <JobOfferPaperworkModal
        isOpen={isModalOpen}
        jobOfferPdf={jobOfferPdf}
        makeJobOfferHandler={makeJobOfferHandler}
        onClose={() => setIsModalOpen(false)}
      />
      <Button
        color="secondary"
        disabled={isLoading}
        onClick={onClickMakeJobOffer}
        variant="contained"
      >
        { isLoading ? 'Loading' : 'Make Job Offer' }
      </Button>
    </>
  )
}

function JobOfferPaperworkModal({isOpen,jobOfferPdf,makeJobOfferHandler,onClose}){
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{alignItems:'center',display:'flex',justifyContent:'center',overflowY:'auto',marginTop:'2em'}}
    >
      <Paper style={{margin: '2em', backgroundColor: 'lightgray', padding: '2em', display: 'flex', flexDirection: 'column', gap: '1em'}}>
        <PdfViewer
          classes={{}}
          currentPage={1}
          currentPDF={jobOfferPdf}

          /* nextPageHandler={this.nextPageHandler} */
          /* onDocumentLoadSuccess={this.onDocumentLoadSuccess} */
          /* previousPageHandler={this.previousPageHandler} */
          totalPages={1}
        />
        <Button onClick={makeJobOfferHandler} variant="contained" color="secondary">
          Save
        </Button>
      </Paper>
    </Modal>
  )
}

