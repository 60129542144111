import {Typography} from '@material-ui/core'
import PdfPagingBar from './PdfPagingBar.js'
import {Document,Page} from 'react-pdf/dist/esm/entry.webpack5'

export default function PdfViewer({
  classes,
  currentPage,
  currentPDF,
  nextPageHandler,
  onDocumentLoadSuccess,
  previousPageHandler,
  totalPages,
}){
  const loadingText = <Typography variant="h5">PDF is currently loading...</Typography>
  return (
    <>
      <div align='center' className={classes.pdf}>
        <Document
          file={`data:application/pdf;base64,${currentPDF}`}
          loading={loadingText}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={currentPage} />
        </Document>
      </div>
      <div className={classes.pdfPagingBar}>
        <PdfPagingBar
          currentPage={currentPage}
          nextPage={nextPageHandler}
          previousPage={previousPageHandler}
          totalPages={totalPages}
        />
      </div>
    </>
  )
}
