import React,{Component,Fragment} from 'react'
import {UnitSelectionModal} from '../QuoteTool' // delete ptr with frgr:partRowUpgrade
import {FileDragAndDrop} from '../Common'
import AppToolBar from '../Common/AppToolBar'
import {
  AppBar,
} from '@material-ui/core'
import {PartViewerModal} from './PartViewer'
import {Link} from '@reach/router'
import ProjectBar from './ProjectBar.js'
import {
  getFileExtension,
  MAX_ALLOWED_UPLOAD_FILE_SIZE_IN_MB,
} from '../../utils'
import createFile from '../../utils/createFile.js'
import downloadPdfToLocalMachineFromBase64 from '../../utils/downloadPdfToLocalMachineFromBase64.js'
import downloadFileToLocalMachineFromBlob from '../../utils/downloadFileToLocalMachineFromBlob.js'
import downloadAllProjectFilesAsZip from '../../utils/downloadAllProjectFilesAsZip.js'
import downloadAllQuotesAsZip from '../../utils/downloadAllQuotesAsZip.js'
import downloadInternalFilesAsZip from '../../utils/downloadInternalFilesAsZip.js'
import PartTable from './PartTable.js'
import projectHasMarkupValues from './projectHasMarkupValues.js'
import projectIsAleadyBooked from '../Common/projectIsAleadyBooked.js'
import { PurchasedToolsButtonAndModal } from "../QuoteTool/PurchasedToolsButtonAndModal"
import getFileNameFromProject from './getFileNameFromProject.js'
import getProjectQuotePdf from './getProjectQuotePdf.js'
import getManualRfqQuote from './getManualRfqQuote.js'
import { uploadFileToS3 } from './cloudObjectStorage/uploadFileToS3'
import getFileFromBase64 from '../../utils/getFileFromBase64.js'
import refreshFiles from '../Common/refreshFiles.js'

const BYTES_IN_A_MB = 1048576

class QuoteTool extends Component{
  constructor(props){
    super(props)
    this.state = {
      isMarkupProject: projectHasMarkupValues(this.props.project),
      quotePrefix: '', // temp value to help DMEs make more pdfs quicker
      unitModalOpen: false, //used to open/close the part unit selection modal

      // partModalState
      partModelModalOpen: false,    // Modal window control, used to view 3D part model
      partGroupInViewer: "",
    }
  }

  componentDidMount(){
    // this prevents users from using browser forward/back pages to navigate to Checkout page
    this.props.disableUserNavigationToCheckoutPage()
  }

  componentDidUpdate(prevProps, _prevState){
    // if the customer opens a CNC project that has no parts
    // open a snackbar reminding them to attach drawings
    const newProjectWasJustLoaded = prevProps.project.projectNumber !== this.props.project.projectNumber
    const isProjectEmpty = this.props.project.partGroups.length === 0
    const isProjectCnc = this.props.project.customerAppQuoteType === "cnc"
    if (newProjectWasJustLoaded && isProjectEmpty && isProjectCnc) {
      this.props.openSnackBarHandler('If your project requires threads or inserts be sure to upload a technical drawing indicating these features')
    }
  }

  /*
    openPartModalHandler updates state to open the part viewer modal
  */
  openPartModalHandler = async partGroup => {
    if (!partGroup.part.s3ObjFileId && !partGroup.part.s3ObjFileIdNew){
      this.props.openSnackBarHandler(`Missing file. Please contact us at contact@autotiv.com to receive your quote!`)
      return // Dont try to open modal
    }

    // open the model viewing modal with the appropriate wall thickness files
    this.setState({
      partModelModalOpen: true,
      partGroupInViewer: partGroup,
    })
  }

  /*
    closeAlertModalHandler updates state to close the part viewer modal
  */
  closePartModalHandler = () => {
    this.setState({partModelModalOpen: false})
  }

  /*
    closeUnitSelectionModal is called explicitly when an uploaded part unit (mm or inches) has been selected
  */
  closeUnitSelectionModal = () => {
    this.setState({unitModalOpen:false})
  }

  /*
    submitUnitDefaultHandler takes a default unit string('mm','inches', or '')
    updates state with the new default unit
  */
  submitUnitDefaultHandler = defaultUnit => {
    if(defaultUnit === 'mm' || defaultUnit === 'inches'){
      //create a fake event so we can use the existing 3D tool onChange handler
      let syntheticEvent = {
        target:{
          name:"forAllUnits",
          value:defaultUnit
        }
      }
      this.props.onChangeApplyAllFieldQuoteToolHandler(syntheticEvent) //pass new default unit value to parent onChange handler
    }
  }

  checkIfObjOrStlFileType = filesToCheck => {
    const objOrStlFilesPresent = filesToCheck.some(file => {
      let ext = getFileExtension(file.name)
      if (ext === 'OBJ' || ext === 'STL'){ return true }
      else return false
    })
    return objOrStlFilesPresent
  }

  /*
    uploadPartsHandler checks if these are the first parts added to a project,
    if so open a modal window to query user for their desired unit (mm or inches)
  */
  uploadPartsHandler = (acceptedFiles,rejectedFiles) => {
    if(this.props.project.partGroups.length === 0){ //if first files uploaded
      // check if any of the uploaded files are stl or obj AKA don't have built in units
      const areObjOrStlFilesPresent = this.checkIfObjOrStlFileType(acceptedFiles)
      if(areObjOrStlFilesPresent){
        this.setState({unitModalOpen:true}) //open modal to query user for default unit
      }
    }
    this.props.uploadParts(acceptedFiles,rejectedFiles) //pass on uploaded files to parent
  }

  setIsMarkupProject = isMarkupProject => this.setState({isMarkupProject})

  setQuotePrefix = quotePrefix => this.setState({quotePrefix})

  downloadQuote = async () => {
    let fileContents
    if(this.props.reviewReasons && this.props.reviewReasons.projectReasons.length && this.props.reviewReasons.partsReasons.length){
      fileContents = await getManualRfqQuote(this.props.project, this.props.pricingData, this.props.selectedLeadTime, this.props.reviewReasons)
    } else {
      fileContents = await getProjectQuotePdf(this.props.project, this.props.pricingData, this.props.selectedLeadTime)
    }

    let currentQuoteRevision = +this.props.project.lastQuoteRevision + 1
    currentQuoteRevision = Number.isNaN(currentQuoteRevision) ? 0 : currentQuoteRevision

    const fileName = getFileNameFromProject(this.props.project, this.state.quotePrefix, currentQuoteRevision)
    const pdfFileName = `${fileName}.pdf`

    const pdfAsFile = await getFileFromBase64(fileContents, pdfFileName)

    // uploadFileToS3 takes in a File object
    const s3Id = await uploadFileToS3(pdfAsFile)

    const file = {
      s3Id: s3Id,
      fileName: pdfFileName,
      projectId: this.props.project.projectNumber,
      fileType: 'quote',
      active: true,
      userUploaded: this.props.user.email,
      isActive: true,
    }

    const fileInDb = await createFile(file)

    await refreshFiles(this.props.project.projectNumber)

    this.props.onChangeProjectFieldQuoteToolHandler({ target: { name: 'lastQuoteRevision', value: currentQuoteRevision}})

    downloadPdfToLocalMachineFromBase64(fileContents, pdfFileName)
  }

  approveRiskFromViewer = () => {
    const partGroup = JSON.parse(JSON.stringify(this.state.partGroupInViewer))
    partGroup.part.riskAccepted = true
    this.setState({
      partGroupInViewer: partGroup,
    })
    const target = { name: 'riskAccepted', value: true }
    this.props.onChangePartFieldQuoteToolHandler({target}, partGroup.partGroupNumber)
  }

  // downloads all part files and attachments in a single zip
  downloadAndSaveAllProjectFilesToLocalMachineAsZip = async () => {
    const fileName = `${this.props.project.projectNumber}-files.zip`
    try{
      const zipFile = await downloadAllProjectFilesAsZip(this.props.project)
      downloadFileToLocalMachineFromBlob(zipFile, fileName)
    } catch(e) {
      this.props.openSnackBarHandler(e.message)
    }
  }

  downloadAllQuotes = async () => {
    const fileName = `${this.props.project.projectNumber}-quotes.zip`
    const zipFile = await downloadAllQuotesAsZip(this.props.project)
    downloadFileToLocalMachineFromBlob(zipFile, fileName)
  }

  downloadInternalFiles = async () => {
    const fileName = `${this.props.project.projectNumber}-internal-files.zip`
    const zipFile = await downloadInternalFilesAsZip(this.props.project.internalFiles)
    downloadFileToLocalMachineFromBlob(zipFile, fileName)
  }

  render(){
    return(
      <Fragment>
        <AppBar position="sticky">
          <AppToolBar changeUserType={this.props.changeUserType} changeUserloggedIn={true} showHomeLink={true} user={this.props.user}/>
          {projectIsAleadyBooked(this.props.project) ?
           <div className="editing-project-bar">
             EDITING BOOKED PROJECT - you must checkout again
           </div>
           : null}
        </AppBar>
        <AppBar position="static">
          <ProjectBar
            cloneProject={this.props.cloneProject}
            currentlyLoading={this.props.currentlyLoading}
            deleteInternalFile={this.props.deleteInternalFile}
            downloadAllProjectFiles={this.downloadAndSaveAllProjectFilesToLocalMachineAsZip}
            downloadAllQuotes={this.downloadAllQuotes}
            downloadInternalFiles={this.downloadInternalFiles}
            downloadQuote={this.downloadQuote}
            getCncToolsDataAllParts={this.props.getCncToolsDataAllParts}
            leadTimePricePairs={this.props.pricingData.leadTimePricePairs}
            isCalculatingPrices={this.props.isCalculatingPrices}
            isMarkupProject={this.state.isMarkupProject}
            onChange={this.props.onChangeProjectFieldQuoteToolHandler}
            pauseCalculations={this.props.pauseCalculations}
            postProcessData={this.props.postProcessData}
            priceLockProject={this.props.priceLockProject}
            project={this.props.project}
            quotePrefix={this.state.quotePrefix}
            removeAttachment={this.props.removeAttachment}
            reviewReasons={this.props.reviewReasons}
            saveAndNavigateToCheckout={this.props.saveAndNavigateToCheckout}
            selectedLeadTime={this.props.selectedLeadTime}
            sendProjectToCustomer={this.props.sendProjectToCustomer}
            sendQuoteToLogistics={this.props.sendQuoteToLogistics}
            setIsMarkupProject={this.setIsMarkupProject}
            setPauseCalculations={this.props.setPauseCalculations}
            setSelectedLeadTime={this.props.setSelectedLeadTime}
            setQuotePrefix={this.setQuotePrefix}
            setUserAsDme={this.props.setUserAsDme}
            submitForManualRfq={this.props.submitForManualRfq}
            submittingForRfq={this.props.submittingForRfq}
            updateCustomer={this.props.updateCustomer}
            uploadInternalFile={this.props.uploadInternalFile}
            uploadNewAttachment={this.props.uploadNewAttachment}
            user={this.props.user}
          />
        </AppBar>
        <div className = 'quote-configuration-container'>
          <PartTable
            addPartConfigurationToPartGroup={this.props.addPartConfigurationToPartGroup}
            addTool={this.props.addTool}
            applyValuesToAll={this.props.applyValuesToAll}
            crud={this.props.crud}
            currentlyLoading={this.props.currentlyLoading}
            deletePartGroup={this.props.deletePartGroup}
            deleteProductionToolPartConfiguration={this.props.deleteProductionToolPartConfiguration}
            deleteTool={this.props.deleteTool}
            getCncToolsData={this.props.getCncToolsData}
            isCalculatingPrices={this.props.isCalculatingPrices}
            isLeadTimeLocked={this.props.project.isLeadTimeLocked}
            isMarkupProject={this.state.isMarkupProject}
            MATERIAL_TRANSLATIONS={this.props.MATERIAL_TRANSLATIONS}
            onChangeApplyAllFieldQuoteToolHandler={this.props.onChangeApplyAllFieldQuoteToolHandler}
            onChangePartFieldQuoteToolHandler={this.props.onChangePartFieldQuoteToolHandler}
            onChangeToolField={this.props.onChangeToolField}
            openPartModal={this.openPartModalHandler}
            partGroups={this.props.project.partGroups}
            postProcessData={this.props.postProcessData}
            pricingData={this.props.pricingData}
            removeAllTolerances={this.props.removeAllTolerances}
            removeAttachment={this.props.removeAttachment}
            replacePartFile={this.props.replacePartFile}
            reviewReasons={this.props.reviewReasons}
            toleranceClassifications={this.props.toleranceClassifications}
            TOOL_MATERIALS={this.props.TOOL_MATERIALS}
            updateCncStockValues={this.props.updateCncStockValues}
            updateProductionToolPartConfiguration={this.props.updateProductionToolPartConfiguration}
            uploadNewAttachment={this.props.uploadNewAttachment}
            user={this.props.user}
            projectNumber={this.props.project.projectNumber}
          />

          {/* Allowed file type list is limited, and not progromatically linked
          to the full list of allowed file types. This was a design decision
          because the full list is too long */}
          {this.props.project.isLeadTimeLocked ?
           <div style={{height: '4em'}}/>
           :
           <FileDragAndDrop
             currentlyLoading={this.props.currentlyLoading}
             dragAndDropType={'parts'}
             maxFileSizeAllowedInBytes={MAX_ALLOWED_UPLOAD_FILE_SIZE_IN_MB*BYTES_IN_A_MB}
             uploadParts={this.uploadPartsHandler}
           />
          }

          <PurchasedToolsButtonAndModal
            addPurchasedToolToProject={this.props.addPurchasedToolToProject}
            customerEmail={this.props.project.customer.email}
            MATERIAL_TRANSLATIONS={this.props.MATERIAL_TRANSLATIONS}
            user={this.props.user}
          />

          <Link to="../../../">Back to home</Link>
        </div>

        <UnitSelectionModal
          close={this.closeUnitSelectionModal}
          open={this.state.unitModalOpen}
          submit={this.submitUnitDefaultHandler}
        />
        {/* 
            This could maybe be a HOC, to keep things coupled in layers,
          where the PartViewerModal decorate with both the necessary jsx and
          handling logic. 
        */}
        <PartViewerModal
          handleClose={this.closePartModalHandler}
          onChange={() => this.approveRiskFromViewer()}
          open={this.state.partModelModalOpen}
          partGroup={this.state.partGroupInViewer}
          postProcessData={this.props.postProcessData}
        />
      </Fragment>
    )
  }
}

export default QuoteTool

QuoteTool.exampleProps = {
  "addPartConfigurationToPartGroup": async () => {},
  "disableUserNavigationToCheckoutPage": () => {},
  "MATERIAL_TRANSLATIONS": [
    {
      "id": "slsWhiteNylon12",
      "display": "White Nylon 12",
      "processId": "sls",
      "active": "Yes",
      "whiteListed": true,
      "displayGroupId": null,
      "displayTo": "customerAndDme",
      "order": 1,
      "colorSet": null
    },
    {
      "id": "slaWhiteAbs",
      "display": "White ABS-like (Accura 25)",
      "processId": "sla",
      "active": "Yes",
      "whiteListed": true,
      "displayGroupId": null,
      "displayTo": "customerAndDme",
      "order": 1,
      "colorSet": null
    }
  ],
  "path": "/:projectNumber",
  "user": {
    "sub": "0cee375f-0342-4c72-8b7a-94c2b9b37d1b",
    "email_verified": true,
    "custom:lastName": "Woodward",
    "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_15fViVABs",
    "cognito:username": "0cee375f-0342-4c72-8b7a-94c2b9b37d1b",
    "aud": "2419hen1jem1oit2m7g46gj7e1",
    "event_id": "3e20950f-4d3b-4342-8313-ed498f4acbc6",
    "custom:firstName": "Nick",
    "token_use": "id",
    "auth_time": 1681500285,
    "name": "Nick Woodward",
    "exp": 1681917708,
    "iat": 1681914108,
    "email": "nick@autotiv.com",
    "type": "dme"
  },
  "uri": "/quote/56804",
  "location": {
    "pathname": "/quote/56804",
    "search": "",
    "hash": "",
    "href": "http://localhost:3000/quote/56804",
    "origin": "http://localhost:3000",
    "protocol": "http:",
    "host": "localhost:3000",
    "hostname": "localhost",
    "port": "3000",
    "state": {
      "key": "1681846253623"
    },
    "key": "1681846253623"
  },
  "isCalculatingPrices": false,
  "pricingData": {
    "partGroupPrices": [
      {
        "partGroupNumber": 260755,
        "unitPrice": 19.93
      }
    ],
    "ptpcPrices": [],
    "leadTimePricePairs": [
      {
        "productionLeadTime": 5,
        "price": 19.93,
        "sampleLeadTime": 0,
        "makeOrders": [
          {
            "supplier": "autotiv_economy",
            "samples": {
              "toAutotivShipMethod": "priorityAir",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 0
            },
            "production": {
              "toAutotivShipMethod": "notShippingToAutotiv",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 5
            },
            "partGroupSupplierPrices": [
              {
                "partGroupNumber": 260755,
                "supplierUnitPrice": 10.4
              }
            ],
            "ptpcSupplierPrices": []
          }
        ],
        "sampleShipDate": "",
        "productionShipDate": "04/26/2023"
      },
      {
        "productionLeadTime": 3,
        "price": 25.6,
        "sampleLeadTime": 0,
        "makeOrders": [
          {
            "supplier": "autotiv_yellow",
            "samples": {
              "toAutotivShipMethod": "priorityAir",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 0
            },
            "production": {
              "toAutotivShipMethod": "notShippingToAutotiv",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 3
            },
            "partGroupSupplierPrices": [
              {
                "partGroupNumber": 260755,
                "supplierUnitPrice": 13.36
              }
            ],
            "ptpcSupplierPrices": []
          }
        ],
        "sampleShipDate": "",
        "productionShipDate": "04/24/2023"
      },
      {
        "productionLeadTime": 2,
        "price": 31.26,
        "sampleLeadTime": 0,
        "makeOrders": [
          {
            "supplier": "autotiv_orange",
            "samples": {
              "toAutotivShipMethod": "priorityAir",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 0
            },
            "production": {
              "toAutotivShipMethod": "notShippingToAutotiv",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 2
            },
            "partGroupSupplierPrices": [
              {
                "partGroupNumber": 260755,
                "supplierUnitPrice": 16.31
              }
            ],
            "ptpcSupplierPrices": []
          }
        ],
        "sampleShipDate": "",
        "productionShipDate": "04/21/2023"
      },
      {
        "productionLeadTime": 1,
        "price": 42.54,
        "sampleLeadTime": 0,
        "makeOrders": [
          {
            "supplier": "autotiv_red",
            "samples": {
              "toAutotivShipMethod": "priorityAir",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 0
            },
            "production": {
              "toAutotivShipMethod": "notShippingToAutotiv",
              "toAutotivShippingLeadTime": 0,
              "supplierLeadTime": 1
            },
            "partGroupSupplierPrices": [
              {
                "partGroupNumber": 260755,
                "supplierUnitPrice": 22.19
              }
            ],
            "ptpcSupplierPrices": []
          }
        ],
        "sampleShipDate": "",
        "productionShipDate": "04/20/2023"
      }
    ],
    "toleranceClassifications": []
  },
  "project": {
    "projectNumber": 56804,
    "visibleSheetNames": "[\"Quote Request\"]",
    "projectManager": {
      "id": "64261030b68a6000273364c3",
      "account_status": "active",
      "approval_status": "approved",
      "profile_keys_raw": [
        {
          "id": "profile_29",
          "identifier": "Autotiv Employees"
        },
        {
          "id": "profile_12",
          "identifier": "Project Managers"
        }
      ],
      "name": {
        "first": "Weston",
        "middle": "",
        "last": "Roberts",
        "title": "",
        "full": "Weston Roberts"
      },
      "email": "weston@autotiv.com",
      "password": "**********",
      "userStatus": "active",
      "userRoles": [
        "profile_29",
        "profile_12"
      ],
      "# of Open Quote follow ups due today or earlier": 38
    },
    "customer": {
      "id": "59c96b2bca418922b2e7ce71",
      "email": "nick@autotiv.com",
      "emailAndName": "nick@autotiv.com - Nick Woodward",
      "customerName": "Nick Woodward",
      "firstName": "Nick",
      "lastName": "Woodward",
      "company": {
        "id": "59dc0aa7ad867f328b253c94",
        "companyDomainAndName": "autotiv.com - Autotiv MFG",
        "companyDomain": "autotiv.com",
        "companyName": "Autotiv MFG",
        "billingEmail": "accounting@autotiv.com",
        "creditTerms": "Net 30",
        "creditLimit": "$10,000",
        "accountStatus": "Bronze",
        "potentialAccountStatus": "Small Team",
        "whiteLabelBool": false,
        "whiteLabelAddress": "",
        "shipOnCustomerAccountBool": false,
        "customerAccountShipCompany": "FedEx",
        "customerAccountShipNumber": "938938938",
        "customerAccountShipZip": "03079",
        "resellerBool": false,
        "companyNotes": "Example notes: foobar",
        "website": "autotiv.com",
        "timeZone": "(GMT-05:00) New York",
        "numberOfMechanicalEngineers": 0,
        "contactEmail": {
          "email": null,
          "label": null
        },
        "coldCampaign": "",
        "bdr": [],
        "bdrWinDup": false,
        "bdrWinDate": "",
        "accountExecutive": [
          {
            "id": "5e56a6b2dbab78001518f5c0",
            "identifier": "Dylan"
          }
        ]
      },
      "phone": {
        "area": "888",
        "number": "3695442",
        "ext": "808",
        "full": "8883695442",
        "country": null,
        "formatted": "(888) 369-5442 x808"
      },
      "shippingCompany": "Autotiv",
      "shippingContact": "Nick Woodward",
      "shippingAddress": {
        "street": "50A Northwestern Drive",
        "street2": "Suite 7",
        "city": "Salem",
        "state": "New Hampshire",
        "zip": "03079",
        "country": "USA",
        "longitude": null,
        "latitude": null,
        "full": "50A Northwestern Drive Suite 7 Salem, New Hampshire 03079 USA"
      },
      "customerNotes": "coolest customer ever. he actually is a god",
      "timeZone": "ET",
      "numberOfOpenCareFollowUpTasks": 0,
      "cellPhoneNumber": {
        "area": "603",
        "number": "4901064",
        "ext": null,
        "full": "6034901064",
        "country": null,
        "formatted": "(603) 490-1064"
      },
      "lastOrderDate": "09/20/2019",
      "lastQuotedDate": "09/19/2019",
      "numClosedProjects": 360,
      "numNonClosedProjects": 656,
      "numDeliveredProjects": 14,
      "notWorthContacting": false,
      "coldCampaign": "",
      "customerTotalSales": 1092.4163260463,
      "numberOfProjects": 113,
      "numberOfProjectsBuilding": 16,
      "customerTotalSalesUnlinked": 859.255
    },
    "contactedThrough": "Email",
    "projectFolderLink": "https://drive.google.com/drive/folders/1iIZJGHXSok1dsdeEa62j8_OE_k5oAWCy",
    "projectStatus": "Quoting",
    "quoteStatus": "Quote Started",
    "operationsPerson": [],
    "totalSale": "",
    "shippingRevenue": "",
    "customerNotes": "",
    "supplierNotes": "",
    "paidExpediteLevel": "",
    "orderDate": null,
    "paidShipDate": null,
    "paidArrivalDate": null,
    "paidShipMethod": "5 Day",
    "labelType": "Autotiv Label",
    "shipProvider": "FedEx",
    "shipAccountNumber": "Autotiv",
    "shipAccountZipCode": "",
    "shipCompany": "Autotiv",
    "shipName": "Nick Woodward",
    "paymentLocation": "",
    "paymentReferenceNumber": "123",
    "onlineOrderNumber": "",
    "billingName": "Nick Woodward",
    "billingEmail": "accounting@autotiv.com",
    "lastQuoteMadeDate": null,
    "quoteNotes": "",
    "expediteLeadTimeOption4": "",
    "expediteLeadTimeOption3": "",
    "expediteLeadTimeOption2": "",
    "expediteLeadTimeOption1": "",
    "lastQuoteRevision": 25,
    "mkCncParam_markup": "2.1",
    "mkCastParam_markup": "1.8",
    "mkInjmdParam_partsMarkup": "1.8",
    "mkInjmdParam_moldMarkup": "1.8",
    "mkShtmlParam_markup": "2.1",
    "asParam_serviceType": "",
    "leadTimeRequest": 5,
    "shippingMethodRequest": "",
    "process": "SLS",
    "materials": "White Nylon 12",
    "partsListForQb": "1 x White Nylon 12: test.stl",
    "asParam_internalServiceShipment": "",
    "asParam_supplier": "",
    "asParam_shipMethodToInt": "",
    "asParam_finishingMarkup": "",
    "asParam_finishingDays": "",
    "customShippingPrice": "",
    "quoteAdjustment": "",
    "numberOfUniqueParts": 1,
    "quoteFollowUpDate": "04/05/2023",
    "reprintRequestShipping": "",
    "reprintRequestLeadTime": "",
    "reprintRequestNotes": "",
    "markupParam_totalFreight": "",
    "markupParam_supplierLeadTime": "",
    "markupParam_additionalBufferDays": "",
    "mkDmlsParam_markup": "2.1",
    "expediteLeadTimeOverride4": "",
    "expediteLeadTimeOverride3": "",
    "expediteLeadTimeOverride2": "",
    "expediteLeadTimeOverride1": "",
    "customerTrackingNumber": "",
    "partsGoogleDriveFolderId": "15m7UklyadutjA2KjQ_yJ6-v3dwNhgxI3",
    "changeRequestNotes": "",
    "calculatedShippingPrice": "",
    "chinaSmallPackageEstimate": "",
    "platformCustomerName": "",
    "platformCustomerPhone": "",
    "platformCustomerCompany": "",
    "customerId": "59c96b2bca418922b2e7ce71",
    "quotingFormulaVersion": "",
    "customerEmail": "nick@autotiv.com",
    "shipPhone": "",
    "shipEmail": "nick@autotiv.com",
    "expediteFee": "",
    "customerReady": false,
    "subTotal": "",
    "fixedPartsGoogleDriveFolderId": "1p6dHVFkjWwWeY-iswOqL9QiI_7Z57NCD",
    "customerAppQuoteType": "3dPrinting",
    "orderSubmittedDate": null,
    "orderSubmittedType": "",
    "isLeadTimeLocked": false,
    "lockedLeadTimeDays": "",
    "lockedLeadTimeExpediteFee": "4.46",
    "markupLeadTimeQuantity1": "",
    "markupLeadTimeQuantityX": "",
    "markupSupplierId": "",
    "paidLeadTime": "",
    "samplesLeadTime": "",
    "selectedPricingData": "",
    "isDmeReviewed": true,
    "lockedProductionLeadTimeDays": 3,
    "lockedSampleLeadTimeDays": 0,
    "lockedMakeOrders": "[{\"supplier\":\"autotiv_yellow\",\"samples\":{\"toAutotivShipMethod\":\"priorityAir\",\"toAutotivShippingLeadTime\":0,\"supplierLeadTime\":0},\"production\":{\"toAutotivShipMethod\":\"notShippingToAutotiv\",\"toAutotivShippingLeadTime\":0,\"supplierLeadTime\":3},\"partGroupSupplierPrices\":[{\"partGroupNumber\":260581,\"supplierUnitPrice\":8.03}],\"ptpcSupplierPrices\":[]}]",
    "createdAt": "04/03/2023",
    "id": "642b2773a77db00028fc2001",
    "shipAddress": {
      "street": "50A Northwestern Drive",
      "street2": "Suite 7",
      "city": "Salem",
      "state": "New Hampshire",
      "zip": "03079",
      "country": "USA"
    },
    "overrides": {
      "standardLeadTime": "calculated",
      "yellowLeadTime": "calculated",
      "orangeLeadTime": "calculated",
      "redLeadTime": "calculated",
      "shippingPrice": "calculated"
    },
    "partGroups": [
      {
        "partGroupNumber": 260755,
        "part": {
          "partIdentifier": "",
          "partNumber": 3971106,
          "knackRecordId": "",
          "fileName": "test.stl",
          "partNotes": "",
          "xDim": "73.99",
          "yDim": "11.00",
          "zDim": "94.95",
          "partVolume": "49708.54",
          "partSurfaceArea": "17601.20",
          "atRiskPartBool": false,
          "partFile": "",
          "partFileS3": "288f8c24-8b76-43af-8495-92a5a79b1c02",
          "partDrawing": "",
          "mfgIssues": [],
          "units": "mm",
          "wallThicknessOBJFile": "",
          "wallThicknessMaterialFile": "",
          "wallThicknessOBJFileMM": "99ffded3-cbf4-4eee-b098-fc8d4be0b90d",
          "wallThicknessMaterialFileMM": "50e3f561-9cc4-441d-b876-59109fa03565",
          "wallThicknessResultsMM": true,
          "wallThicknessOBJFileInches": "",
          "wallThicknessMaterialFileInches": "",
          "wallThicknessResultsInches": "",
          "s3ObjFileId": "55d6af8f-29f0-4aa5-adc3-9d50ed004535",
          "xDimUnits": "73.989998",
          "yDimUnits": "11",
          "zDimUnits": "94.949997",
          "partVolumeUnits": "49708.542969",
          "partSurfaceAreaUnits": "17601.197266",
          "mfgAnalysisIssues": [],
          "riskAccepted": false,
          "wallThicknessTaskIdMM": "gk6robjqmntigJ0sQTBrVabNCSz72R8Y",
          "wallThicknessTaskIdInches": "",
          "paramExtractError": "",
          "meshProperties": {
            "shells": "1",
            "badEdges": "0",
            "boundaryEdges": "0",
            "flippedTriangleCount": "0",
            "isManifold": "true"
          },
          "thumbnailS3id": "7913aab2-14da-49e6-839e-df7978a5f752",
          "thinWallPercentageMM": "",
          "thinWallPercentageInches": "",
          "fileSize": "3484",
          "netfabbWallThicknessTotalSurfaceAreaInches": "",
          "netfabbWallThicknessTotalSurfaceAreaMM": "17601.1999053955",
          "netfabbWallThicknessSurfaceAreaBelowThresholdInches": "",
          "netfabbWallThicknessSurfaceAreaBelowThresholdMM": "0",
          "netfabbWallThicknessNumberOfClustersBelowThresholdInches": "",
          "netfabbWallThicknessNumberOfClustersBelowThresholdMM": 0,
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdInches": "",
          "netfabbWallThicknessAreaOfLargestClusterBelowThresholdMM": "0",
          "wallThicknessErrorMM": null,
          "wallThicknessErrorInches": null,
          "dmeEstimatedRatioOfVolumeToBoundingBox": "0",
          "dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea": "0",
          "cncMillOrTurn": "MILL",
          "cncNumberOfAxes": 3,
          "cncSetupsPerPart": "",
          "cncSmallestToolDiameter": "",
          "sheetMetalCutPathLength": "",
          "sheetMetalStockX": "",
          "sheetMetalStockY": "",
          "sheetMetalStockThickness": "",
          "sheetMetalNumberOfBends": "",
          "sheetMetalNumberOfWelds": "",
          "cncRoughingTools": [
            {
              "diameter": 50,
              "volumePercentage": 100
            }
          ],
          "cncFinishingTools": [
            {
              "diameter": 10,
              "surfaceAreaPercentage": 100
            }
          ],
          "cncStockX": "",
          "cncStockY": "",
          "cncStockZ": "",
          "cncStockInnerDiameter": "",
          "cncStockOuterDiameter": "",
          "cncIsHeldInVice": true,
          "imRoughingTools": [
            {
              "diameter": 50,
              "volumePercentage": 100
            }
          ],
          "imFinishingTools": [
            {
              "diameter": 2,
              "surfaceAreaPercentage": 100
            }
          ],
          "numberOfSlides": "",
          "numberOfCollapsingCores": 0,
          "averageWallThickness": "",
          "standardDeviationWallThickness": "",
          "cnc5AxisMillComplexity": "Medium",
          "dmeMarkedAtRiskPartBool": false,
          "injectionMoldingWallThicknessResultFileId": null,
          "injectionMoldingWallThicknessError": null
        },
        "project": 56804,
        "makeOrder": "",
        "shipment": "",
        "knackRecordId": "",
        "process": "SLS",
        "material": "White Nylon 12",
        "description": "",
        "quantity": 1,
        "finishing": "Standard",
        "additionalServices": "",
        "unitPrice": "0",
        "unitCost": "",
        "customPrice": "",
        "markupPrice": "",
        "dmlsParam_asBuiltX": "",
        "dmlsParam_asBuiltY": "",
        "dmlsParam_asBuiltZ": "",
        "dmlsParam_perPartFinishingTime": "",
        "dmlsParam_perPartSlicedMaterialVolume": "",
        "fdmParam_machine": "",
        "fdmParam_resolution": "",
        "fdmParam_infill": "",
        "fdmParam_perPartSlicedMaterialVolume": "",
        "fdmParam_perPartSupportMaterialVolume": "",
        "fdmParam_perPartFinishingTimeHour": "",
        "fdmParam_perPartFinishingTimeMin": "",
        "cncParam_perPartMachineTime": "",
        "cncParam_perPartDrilledHolesCount": "",
        "cncParam_perPartTappedHolesCount": "",
        "cncParam_toolCount": "",
        "cncParam_setupCount": "",
        "cncParam_perPartMaterialCostOffset": "",
        "cncParam_perPartMachiningCostOffset": "",
        "cncParam_setupCostOffset": "",
        "mkCncParam_totalPartGroupCost": "",
        "mkCastParam_masterCost": "",
        "mkCastParam_moldCost": "",
        "mkCastParam_totalPartGroupCost": "",
        "mkInjmdParam_moldCost": "",
        "mkInjmdParam_moldLife": "",
        "mkInjmdParam_totalPartGroupCost": "",
        "mkShtmlParam_totalPartGroupCost": "",
        "asParam_perPartUnitCost": "",
        "reprintQuantityRequested": "",
        "dmlsParam_useMarkupPrice": "",
        "mkCastParam_useMarkupPrice": "",
        "mkDmlsParam_totalPartGroupCost": "",
        "processId": "injMoldingPart",
        "materialId": "slsWhiteNylon12",
        "finishId": "slsStandard",
        "selectedPrice": "calculated",
        "partGroupNotes": "",
        "atRiskBool": "",
        "riskAccepted": "",
        "isMarkupCosted": "",
        "markupCostQuantity1": "",
        "markupCostQuantityX": "",
        "markupQuantityX": "",
        "guaranteedLife": 2000,
        "isOriginalPartGroup": "",
        "productionToolMaterialId": null,
        "cavityCount": "",
        "materialIdProductionToolIsDesignedFor": "injMoldingPartABSChimeiPA717C",
        "drawingUnits": "mm",
        "isDmeReviewed": true,
        "isPriceLocked": "",
        "lockedUnitPrice": "",
        "pricingMethod": "calculated",
        "productionToolPartConfigurations": [
          {
            id:"1",
            projects:"194056, 205462, 602943"
          },
          {
            id:"2",
            projects:"394247, 215960, 602943"
          },
          {
            id:"3",
            projects:"194058, 205462, 602943"
          }
        ],
        "attachments": [],
        "overmoldedInserts": [],
        "postProcesses": [],
        "tolerances": []
      }
    ],
    "makeOrders": [],
    "shipments": [],
    "quotes": [],
    "attachments": [],
    "calculations": {
      "leadTimePrices": []
    }
  },
  "reviewReasons": {
    "projectReasons": [],
    "partsReasons": []
  },
  "applyValuesToAll": {
    "forAllMaterial": "",
    "forAllProcess": "",
    "forAllUnits": ""
  },
  "crud": {
    partGroups: () => ({})
  },
  "currentlyLoading": false,
  "selectedLeadTime": null,
  "submittingForRfq": false
}

QuoteTool.Showcase = Showcase
function Showcase(){
  return(
    <>
      <QuoteTool { ...QuoteTool.exampleProps } devBackdoors={{}}/>
    </>
  )
}

