import config from "../../../config"
const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function startNewInjectionMoldingWallThicknessTask(part) {
  let response
  try {
    response = await startNewInjectionMoldingWallThicknessTaskRequest(part)
  } catch (err) {
    logUnexpected(err, { part }, 'startNewInjectionMoldingWallThicknessTaskRequest')
    throw err
  }

  let result
  try {
    result = parseStartNewInjectionMoldingWallThicknessTaskResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseStartNewInjectionMoldingWallThicknessTaskResponse', { part })
    throw err
  }

  return result
}

async function startNewInjectionMoldingWallThicknessTaskRequest(part) {
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: '/geom/startImToolsTask',
    data: {
      s3StlFileId: part.s3StlFileIdNew,
      partNumber: part.partNumber,
    },
  })
  return { status: response.status, data: response.data }
}

function parseStartNewInjectionMoldingWallThicknessTaskResponse(response) {
  if (response.status === 200) {
    if (response.data) {
      return response.data
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.respBody = response.data
    throw unexpectedBodyFor200Error
  } else if (response.status === 400) {
    let unexpectedError = new Error(`Unexpected error response message: ${response.data.message}`)
    unexpectedError.respBody = response.data
    throw unexpectedError
  } else {
    let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
    unexpectedStatusCodeError.respBody = response.data
    throw unexpectedStatusCodeError
  }
}
