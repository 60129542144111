import { downloadBlobFromS3, downloadFileFromS3 } from './cloudObjectStorage/downloadFileFromS3'
import { partAnalysisStatus } from '../../utils'

export async function downloadWallThicknessFiles(partGroup, postProcessData) {
  const { plainGlbKey, glbKey } = getFileKeysToDownload(partGroup, postProcessData)
  console.log({plainGlbKey, glbKey})

  const glbBlob = await downloadBlobFromS3(plainGlbKey)
  const plainGlbArrayBuffer = await glbBlob.arrayBuffer()

  let glbArrayBuffer
  if (glbKey) {
    const glbBlob = await downloadBlobFromS3(glbKey)
    glbArrayBuffer = await glbBlob.arrayBuffer()
  }

  console.log({plainGlbArrayBuffer, glbArrayBuffer})
  return { plainGlbArrayBuffer, glbArrayBuffer }
}

function getFileKeysToDownload(partGroup, postProcessData) {
  const part = partGroup.part
  let glbKey

  const plainGlbKey = part.s3GlbFileId

  const {NEEDS_WALL_THICKNESS,WALL_THICKNESS_TASK_FINISHED} = partAnalysisStatus(partGroup, postProcessData.POST_PROCESS_OPTIONS)
  if (NEEDS_WALL_THICKNESS && WALL_THICKNESS_TASK_FINISHED) {

    if (part.units === 'mm') {
      if (part.wallThicknessGlbFileIdMM) {
        glbKey = part.wallThicknessGlbFileIdMM
      }
    } else if (part.units === "inches") {
      if (part.wallThicknessGlbFileIdInches) {
        glbKey = part.wallThicknessGlbFileIdInches
      }
    }
  }

  return { plainGlbKey, glbKey }
}
