import { pollDatabaseForResult } from './pollDatabaseForResult.js'

export default async function pollDatabaseForNewParamExtractionResult(partNumber) {
    const PARAM_FIELDS_SUCCESS = ['xDimUnits','yDimUnits','zDimUnits','partVolumeUnits','partSurfaceAreaUnits']
    const PARAM_FIELD_FAIL = ''

    const throttle = 3000
    const TWO_MINUTES_IN_MS = 2 * 60 * 1000
    const timeout = TWO_MINUTES_IN_MS

    return pollDatabaseForResult(partNumber, PARAM_FIELDS_SUCCESS, PARAM_FIELD_FAIL, throttle, timeout)
}
