const axios = require('axios')
const config = require('../config.js')
const { logUnexpected } = require('./logUnexpected.js')

export default async function updateSourcingAttempt(sourcingAttempt){
  let response
  try{
    response = await updateSourcingAttemptRequest(sourcingAttempt)
  } catch (err){
    logUnexpected(err, { sourcingAttempt }, 'updateSourcingAttemptRequest')
    throw err
  }

  let result
  try{
    result = parseUpdateSourcingAttemptResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseUpdateSourcingAttemptResponse', { sourcingAttempt })
    throw err
  }

  return result
}

function updateSourcingAttemptRequest(sourcingAttempt){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.UPDATE_SOURCING_ATTEMPT_ENDPOINT,
    data: {
      sourcingAttempt,
      token: config.MICROSERVICE_TOKEN,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseUpdateSourcingAttemptResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }

    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
