import React,{useState} from 'react'
import {Checkbox, FormControlLabel, Paper, Typography} from '@material-ui/core'
import {partAnalysisStatus, useToggledBoolean} from '../../utils'
import PartCard from './PartCard.js'
import frg from '../../feature-release-guards.js'

import ApplyAllToolbar from './ApplyAllToolbar.js';

// wrap PartTable to make it optional if you have any parts
const PartTableMaybe = ({partGroups, ...strippedProps}) => {
  return (
    partGroups.length ?
    <PartTable
      partGroups={partGroups}
      {...strippedProps}
    />
    : null
  )
}

const PartTable = ({
  applyValuesToAll,
  isLeadTimeLocked,
  MATERIAL_TRANSLATIONS,
  onChangeApplyAllFieldQuoteToolHandler,
  onChangePartFieldQuoteToolHandler,
  postProcessData,
  TOOL_MATERIALS,
  user,
  ...strippedProps
}) => {
  return (
    <>
      <div className="part-table-container" >
        <Paper className="part-table-paper" elevation={20} >
          {isLeadTimeLocked ?
           <div className="lockedPartTableHeader">
             <Typography variant='h5'>
               Parts
             </Typography>
           </div>
           :
          <ApplyAllToolbar
            applyValuesToAll={applyValuesToAll}
            MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
            onChangeApplyAllFieldQuoteToolHandler={onChangeApplyAllFieldQuoteToolHandler}
            user={user}
          />
          }
          <PartTableBody
            {...strippedProps}
            isLeadTimeLocked={isLeadTimeLocked}
            MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
            onChange={onChangePartFieldQuoteToolHandler}
            postProcessData={postProcessData}
            TOOL_MATERIALS={TOOL_MATERIALS}
            user={user}
          />
        </Paper>
      </div>
    </>
  )
}
const PartTableBody =  ({
  addPartConfigurationToPartGroup,
  addTool,
  crud,
  currentlyLoading,
  deletePartGroup,
  deleteProductionToolPartConfiguration,
  deleteTool,
  getCncToolsData,
  isCalculatingPrices,
  isLeadTimeLocked,
  isMarkupProject,
  MATERIAL_TRANSLATIONS,
  onChange,
  onChangeToolField,
  openPartModal,
  partGroups,
  postProcessData,
  pricingData,
  removeAllTolerances,
  removeAttachment,
  replacePartFile,
  reviewReasons,
  toleranceClassifications,
  TOOL_MATERIALS,
  updateCncStockValues,
  updateProductionToolPartConfiguration,
  uploadNewAttachment,
  user,
  projectNumber,
}) => {
  const defaultPartGroupNumberToEdit = partGroups.length > 0 ? partGroups[0].partGroupNumber : null
  const [partGroupNumberToEdit, setPartGroupNumberToEdit] = useState(defaultPartGroupNumberToEdit)
  return (
    <>
      {partGroups.map((partGroup,index)=>{
        const partReasons = getReasonsAssociatedWithAPart(reviewReasons, partGroup)
        return (
          <PartCard
            addPartConfigurationToPartGroup={addPartConfigurationToPartGroup}
            addTool={addTool}
            crud={crud}
            currentlyLoading={currentlyLoading}
            deletePartGroup={deletePartGroup}
            deleteProductionToolPartConfiguration={deleteProductionToolPartConfiguration}
            deleteTool={deleteTool}
            getCncToolsData={getCncToolsData}
            isMarkupProject={isMarkupProject}
            isCalculatingPrices={isCalculatingPrices}
            isLeadTimeLocked={isLeadTimeLocked}
            key={`${index}${partGroup.partGroupNumber}`}
            MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
            onChange={onChange}
            onChangeToolField={onChangeToolField}
            openPartModal={openPartModal}
            partGroup={partGroup}
            partGroupNumberToEdit={partGroupNumberToEdit}
            partReasons={partReasons}
            postProcessData={postProcessData}
            pricingData={pricingData}
            removeAllTolerances={removeAllTolerances}
            removeAttachment={removeAttachment}
            replacePartFile={replacePartFile}
            setPartGroupNumberToEdit={setPartGroupNumberToEdit}
            status={partAnalysisStatus(partGroup, postProcessData.POST_PROCESS_OPTIONS)}
            toleranceClassifications={toleranceClassifications}
            TOOL_MATERIALS={TOOL_MATERIALS}
            updateCncStockValues={updateCncStockValues}
            updateProductionToolPartConfiguration={updateProductionToolPartConfiguration}
            uploadNewAttachment={uploadNewAttachment}
            user={user}
            projectNumber={projectNumber}
          />
        )
      })}
    </>
  )
}

const getReasonsAssociatedWithAPart = (reviewReasons, partGroup) => {
  //Search list of review reasons for any associated with current part
  const partReasonsObj = reviewReasons ?
  (reviewReasons.partsReasons ? reviewReasons.partsReasons.find((partReasonObj) => {
    return (partReasonObj.partGroupNumber === partGroup.partGroupNumber ? true : false)
  }) : null)
  : null
  const partReasons = partReasonsObj ? partReasonsObj.partReasons : []
  return partReasons
}


PartTableMaybe.exampleProps = require('./PartTable.exampleProps.js')

export default PartTableMaybe
