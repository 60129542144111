import React,{Fragment} from 'react'
import {Button,LinearProgress} from '@material-ui/core'
import './ActivitiesForm.css'

import ActionColumn from './ActionColumn.js'
import ActivityColumnCards from './ActivityColumnCards.js'
import LeftToMakeAndShipTable from './LeftToMakeAndShipTable.js'
import ProjectMakeOrderDataCard from './ProjectMakeOrderDataCard.js'
import ProjectShipmentDataCard from './ProjectShipmentDataCard.js'
import PartGroupsDataCard from './PartGroupsDataCard.js'

import FORMS from '../FORMS.js'


const ActivitiesForm = ({
  activities,
  changeForms,
  changeToSourcingAttemptForm,
  currentlySaving,
  MATERIAL_TRANSLATIONS,
  newActivityHandler,
  onChange,
  onChangeActivityType,
  onChangeProjectFieldHandler,
  onChangePtpc,
  onDoneButtonClick,
  openBoxesForm,
  postProcessData,
  project,
  suppliers,
  SUPPLIER_TYPES,
  updateSupplierPricing,
}) => {
  return (
    <Fragment>
      <ActivitiesFormCards
        activities={activities}
        changeToSourcingAttemptForm={changeToSourcingAttemptForm}
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        newActivityHandler={newActivityHandler}
        onChange={onChange}
        onChangeActivityType={onChangeActivityType}
        onChangeProjectFieldHandler={onChangeProjectFieldHandler}
        onChangePtpc={onChangePtpc}
        onDoneButtonClick={onDoneButtonClick}
        openBoxesForm={openBoxesForm}
        postProcessData={postProcessData}
        project={project}
        suppliers={suppliers}
        SUPPLIER_TYPES={SUPPLIER_TYPES}
        updateSupplierPricing={updateSupplierPricing}
      />
      { currentlySaving ? <LinearProgress color="primary" /> : null }
      <div
        style={{ display:'flex', justifyContent:'center', margin:'1vw' }}
      >
        <Button
          color="primary"
          onClick={()=>{changeForms(FORMS.paperwork)}}
          variant="contained"
        >
          Next
        </Button>
      </div>
    </Fragment>
  )
}

const ActivitiesFormCards = ({
  activities,
  changeToSourcingAttemptForm,
  MATERIAL_TRANSLATIONS,
  newActivityHandler,
  onChange,
  onChangeActivityType,
  onChangeProjectFieldHandler,
  onChangePtpc,
  onDoneButtonClick,
  openBoxesForm,
  postProcessData,
  project,
  suppliers,
  SUPPLIER_TYPES,
  updateSupplierPricing,
}) => {
  return(
    <div className="activities-form">
      <ProjectMakeOrderDataCard
        project={project}
        style={{ gridArea:'makeo' }}
      />
      <ProjectShipmentDataCard
        project={project}
        style={{ gridArea:'shipm' }}
      />
      <PartGroupsDataCard
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        postProcessData={postProcessData}
        project={project}
        style={{ gridArea:'partg' }}
      />
      <ActivityColumnCards
        activities={activities}
        changeToSourcingAttemptForm={changeToSourcingAttemptForm}
        newActivityHandler={newActivityHandler}
        onChange={onChange}
        onChangeActivityType={onChangeActivityType}
        onChangePtpc={onChangePtpc}
        openBoxesForm={openBoxesForm}
        project={project}
        style={{ gridArea:'activ' }}
        suppliers={suppliers}
        SUPPLIER_TYPES={SUPPLIER_TYPES}
        updateSupplierPricing={updateSupplierPricing}
      />
      <ActionColumn
        activities={activities}
        newActivityHandler={newActivityHandler}
        onChangeProjectFieldHandler={onChangeProjectFieldHandler}
        onDoneButtonClick={onDoneButtonClick}
        project={project}
        style={{ gridArea:'newbt' }}
      />
      <LeftToMakeAndShipTable
        activities={activities}
        project={project}
        style={{ gridArea:'reman' }}
      />
    </div>
  )
}

export default ActivitiesForm
