const axios = require('axios')
const config = require('../../config.js')

export default async function downloadJobOfferFilesAsZip(makeOrderId){
  const response = await downloadJobOfferFilesAsZipRequest(makeOrderId)
  const result = await parseDownloadJobOfferFilesAsZip(response)
  return result
}

async function downloadJobOfferFilesAsZipRequest(makeOrderId){
  return axios({
    responseType: 'blob',
    method: 'POST',
    validateStatus: () => true,
    data: {
      makeOrderId,
      token: config.SUPPLIER_DATABUTLER_TOKEN,
    },
    url: config.DOWNLOAD_JOB_OFFER_FILES_ENDPOINT,
  })
}

async function parseDownloadJobOfferFilesAsZip(response){
  if(response.status === 400){
    try{
      const errorMessage = await response.data.text()
      throw new Error(`Error parsing quotes zip response: ${errorMessage}`)
    } catch(e) {
      throw new Error(`Error parsing quotes zip response`)
    }
  }

  return response.data
}
