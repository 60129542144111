import { pollDatabaseForResult } from './pollDatabaseForResult.js'

export default async function pollDatabaseForNewWallThicknessResult(partNumber, units) {
    let PARAM_FIELDS_SUCCESS = []
    if(units === 'inches'){
      PARAM_FIELDS_SUCCESS = ['wallThicknessGlbFileIdInches']
    } else {
      PARAM_FIELDS_SUCCESS = ['wallThicknessGlbFileIdMM']
    }
    const PARAM_FIELD_FAIL = ''

    const throttle = 3000
    const TWO_MINUTES_IN_MS = 2 * 60 * 1000
    const timeout = TWO_MINUTES_IN_MS

    return pollDatabaseForResult(partNumber, PARAM_FIELDS_SUCCESS, PARAM_FIELD_FAIL, throttle, timeout)
}
