import React from 'react'
import ActivityColumn from './ActivityColumn.js'

const ActivityColumnCards = ({
  activities,
  changeToSourcingAttemptForm,
  newActivityHandler,
  onChange,
  onChangeActivityType,
  onChangePtpc,
  openBoxesForm,
  project,
  style,
  suppliers,
  SUPPLIER_TYPES,
  updateSupplierPricing,
}) => {
  return (
    <div
      style={{
        overflow:'auto',
        display: 'flex',
        ...style
      }}>
      <ActivitiesColumns
        activities={activities}
        changeToSourcingAttemptForm={changeToSourcingAttemptForm}
        newActivityHandler={newActivityHandler}
        onChange={onChange}
        onChangeActivityType={onChangeActivityType}
        onChangePtpc={onChangePtpc}
        openBoxesForm={openBoxesForm}
        project={project}
        suppliers={suppliers}
        SUPPLIER_TYPES={SUPPLIER_TYPES}
        updateSupplierPricing={updateSupplierPricing}
      />
      <div className="lastColumn">
        End
      </div>
    </div>
  )
}

const ActivitiesColumns = ({
  activities,
  changeToSourcingAttemptForm,
  newActivityHandler,
  onChange,
  onChangeActivityType,
  onChangePtpc,
  openBoxesForm,
  project,
  suppliers,
  SUPPLIER_TYPES,
  updateSupplierPricing,
}) => {
  return activities.map((activity, index) => {
    return (
      <ActivityColumn
        activity={activity}
        changeToSourcingAttemptForm={changeToSourcingAttemptForm}
        key={`activity${index}`}
        newActivityHandler={newActivityHandler}
        onChange={onChange}
        onChangeActivityType={onChangeActivityType}
        onChangePtpc={onChangePtpc}
        openBoxesForm={openBoxesForm}
        project={project}
        suppliers={suppliers}
        SUPPLIER_TYPES={SUPPLIER_TYPES}
        updateSupplierPricing={updateSupplierPricing}
      />
    )
  })
}

export default ActivityColumnCards
