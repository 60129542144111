import { useContext, useEffect, useState } from 'react'
import { formatInTimeZone } from 'date-fns-tz'
import {
  Button,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import { CloudDownload } from '@material-ui/icons'
import downloadFileToLocalMachineFromBlob from '../../utils/downloadFileToLocalMachineFromBlob.js'
import downloadFromS3AndSaveFile from '../../utils/downloadFromS3AndSaveFile.js'
import downloadJobOfferFilesAsZip from './downloadJobOfferFilesAsZip.js'
import { LoadingPage } from '../Common'
import loadJobOfferData from './loadJobOfferData.js'
import { NumberField } from '../QuoteTool/NumberField.js'
import submitSupplierResponse from './submitSupplierResponse.js'

import LogoOldAndBoring from '../../Images/logo.png'

const RESPONSES = {
  ACCEPT: 'accept',
  COUNTER: 'counter',
  REJECT: 'reject',
}

const STATUSES = {
  ACCEPTED: 'Accepted',
  SOURCING: 'In Progress',
}

export default function SourcingPage({jobOffer, location, snackbar, ...otherProps}){
  const [ file, setFile ] = useState(null)
  const [ isLoaded, setIsLoaded ] = useState(false)
  const [ isLinkBroken, setIsLinkBroken ] = useState(false)
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const [ jobOfferData, setJobOfferData ] = useState(null)
  const [ notes, setNotes ] = useState('')
  const [ responseType, setResponseType ] = useState('')
  const [ counterValue, setCounterValue ] = useState('')
  const [ suppliers, setSuppliers ] = useState(null)

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code')
    loadJobOfferData(jobOffer, code)
      .then(jobOfferData => {
        setJobOfferData(jobOfferData)
        setIsLoaded(true)
      })
      .catch(err => {
        setIsLinkBroken(true)
      })
  }, ['ON-MOUNT'])

  const clickResponse = (responseType) => {
    setResponseType(responseType)
    setIsModalOpen(true)
  }

  const onModalClose = () => setIsModalOpen(false)

  const getJobOfferFileName = () => `Job Offer ${jobOfferData.name}.pdf`

  const getJobOfferZipFileName = () => `Job Offer ${jobOfferData.name} files.zip`

  const downloadJobOfferPartFiles = async () => {
    try{
      const zipFile = await downloadJobOfferFilesAsZip(jobOfferData.makeOrderId)
      downloadFileToLocalMachineFromBlob(zipFile, getJobOfferZipFileName())
    } catch(e) {
      snackbar(e.message)
    }
  }

  const sumbitResponse = responseType => {
    if(!notes && (responseType === RESPONSES.COUNTER || responseType === RESPONSES.REJECT)){
      snackbar('Must supply')
      return
    }

    const responseData = {
      id: jobOffer,
      name: jobOfferData.name,
      supplierName: jobOfferData.supplierName,
      responseComments: notes ? notes : 'None',
      counterOfferValue: counterValue,
    }

    submitSupplierResponse(responseType, responseData, file)
      .then(() => window.location.reload())
      .catch(e => snackbar(e.message))
  }

  if(isLinkBroken){
    return(
      <div style={{padding: '1em'}}>
        Link is expired or broken. If you believe this is a mistake, please email us at projects@autotiv.com
      </div>
    )
  }

  if(!isLoaded){
    return (
      <LoadingPage/>
    )
  }

  return(
    <div style={{backgroundColor: 'gray', minHeight: '100vh', display:'flex', flexDirection: 'column', gap: '1em', justifyContent: 'flex-start'}}>
      <div style={{backgroundColor: '#2C425B', marginBottom: '4em', padding: '0.25em'}}>
        <img alt="logo" style={{height:'60px'}} src={LogoOldAndBoring}/>
      </div>

      <Paper style={{backgroundColor: 'white', width: '50vw', marginLeft: '25vw', paddingTop: '1em', paddingBottom: '1em'}}>
        <div style={{display: 'flex', flexDirection: 'column',  alignItems: 'center', justifyContent: 'space-around', height: '100%', gap: '0.5em'}}>
          <Typography variant="h4" style={{paddingBottom: '0.5em', paddingTop: '0.5em'}}>
            Job Offer {jobOfferData.name}
          </Typography>
          <div style={{display: 'flex', flexDirection: 'column', width: '50%', gap: '1em'}}>

            <div style={{display: 'flex', gap: '0.5em', alignItems: 'center', height: '1.5em'}}>
              <Typography>
                <b> Supplier: </b>
              </Typography>
              <Typography>
                {jobOfferData.supplierName}
              </Typography>
            </div>

            <div style={{display: 'flex', gap: '0.5em', alignItems: 'center', height: '1.5em'}}>
              <Typography>
                <b> Status: </b>
              </Typography>
              <Typography>
                {jobOfferData.status}
              </Typography>
            </div>
            
            <div style={{display: 'flex', gap: '0.5em', alignItems: 'center', height: '1.5em'}}>
              <Typography>
                <b> Job Offer: </b>
              </Typography>
              <Typography>
                {getJobOfferFileName()}
              </Typography>
              { jobOfferData.status === STATUSES.ACCEPTED || jobOfferData.status === STATUSES.SOURCING ?
              <IconButton tabIndex="-1" color="primary" onClick={() => downloadFromS3AndSaveFile(jobOfferData.jobOfferS3Id, getJobOfferFileName())} style={{padding: '0.25em'}}>
                <CloudDownload
                  color="primary"
                  fontSize="small"
                />
              </IconButton>
                : null }
            </div>
            <div style={{display: 'flex', gap: '0.5em', alignItems: 'center', height: '1.5em'}}>
              <Typography>
                <b> Project Files: </b>
              </Typography>
              <Typography>
                {getJobOfferZipFileName()}
              </Typography>
              { jobOfferData.status === STATUSES.ACCEPTED || jobOfferData.status === STATUSES.SOURCING ?
                <IconButton tabIndex="-1" color="primary" onClick={downloadJobOfferPartFiles} style={{padding: '0.25em'}}>
                <CloudDownload
                  color="primary"
                  fontSize="small"
                />
              </IconButton>
                : null }
            </div>
            { jobOfferData.status === STATUSES.SOURCING ?
              <div style={{display: 'flex', gap: '0.5em', alignItems: 'center', height: '1.5em'}}>
                <Typography>
                  <b> Valid until: </b>
                </Typography>
                <Typography>
                  {formatDate(jobOfferData.supplierReviewEndTime, jobOfferData.supplierTimeZone)} ({calculateEndHoursFromNow(jobOfferData.supplierReviewEndTime)})
                </Typography>
              </div>
              : null }
          </div>
        </div>
      </Paper>
      { jobOfferData.status === STATUSES.SOURCING ?
      <Paper style={{backgroundColor: 'white', width: '50vw', marginLeft: '25vw'}}>
        <div style={{display: 'flex', flexDirection: 'column',  alignItems: 'center', justifyContent: 'space-around', height: '100%', padding: '1em 2em', gap: '1em'}}>
          <Typography variant="h4">
            Response
          </Typography>
          <div style={{display: 'flex', justifyContent: 'space-around', gap: '1em', paddingTop: '1em'}}>
            <Button
              onClick={() => clickResponse(RESPONSES.REJECT)}
              style={{backgroundColor:"red"}}
              variant="contained"
            >
              Reject
            </Button>
            <Button
              onClick={() => clickResponse(RESPONSES.COUNTER)}
              style={{backgroundColor:"yellow"}}
              variant="contained"
            >
              Counter-bid
            </Button>
            <Button
              onClick={() => clickResponse(RESPONSES.ACCEPT)}
              style={{backgroundColor:"green"}}
              variant="contained"
            >
              Accept
            </Button>
          </div>
        </div>
      </Paper>
       : null }
      <ConfirmationModal
        counterValue={counterValue}
        file={file}
        isModalOpen={isModalOpen}
        notes={notes}
        onModalClose={onModalClose}
        responseType={responseType}
        setCounterValue={setCounterValue}
        setFile={setFile}
        setNotes={setNotes}
        sumbitResponse={sumbitResponse}
      />
    </div>
  )
}

function formatDate(supplierReviewEndTime, supplierTimeZone){
  const localTimeZone = 'America/New_York'
  const timeZone = supplierTimeZone ? supplierTimeZone : localTimeZone
  console.log({timeZone,supplierTimeZone})

  return formatInTimeZone(supplierReviewEndTime, timeZone, 'E h:mm b zzz')
}

function calculateEndHoursFromNow(supplierReviewEndTime){
  const date = (new Date(supplierReviewEndTime)).getTime()
  const currentUnixTime = Date.now()
  console.log({currentUnixTime, date})

  const msToEndDateTime = date - currentUnixTime
  const MS_PER_MINUTE = 60000
  const minutesToEndDateTime = Math.floor(msToEndDateTime / MS_PER_MINUTE)
  const MS_PER_HOUR = 3600000
  const hoursToEndDateTime = Math.floor(msToEndDateTime / MS_PER_HOUR)

  if(msToEndDateTime < 0){
    return 'Timed out'
  }
  if(hoursToEndDateTime < 1){
    return minutesToEndDateTime + ' minutes from now'
  }
  return hoursToEndDateTime + ' hours from now'
}

function ConfirmationModal({counterValue, file, isModalOpen, notes, onModalClose, responseType, setCounterValue, setFile, setNotes, sumbitResponse}){
  const acceptMessage =
        <>
          <b>Accept the order</b> only if you can deliver it <b>exactly as specified. </b>
          If any changes are needed <b>counterbid and leave a note</b> describing the changes.<br/>
          <br/>
          We will provide you a purchase order within one business day.
          Do not start the order until you receive the PO.
        </>

  const counterMessage =
        <>
          Please <b>attach your quote</b> that best matches the order's
          requirements and <b>advise in the notes fields or with attachments </b>
          to <b>indicate anything that has changed.</b>
        </>

  const rejectMessage =
        <>
          If you cannot accept the order and are not interested in counterbidding, click Confirm to decline the order.
        </>

  const confirmationMessages = {
    [RESPONSES.ACCEPT]: acceptMessage,
    [RESPONSES.COUNTER]: counterMessage,
    [RESPONSES.REJECT]: rejectMessage,
  }

  return(
    <Modal
      open={isModalOpen}
      onClose={onModalClose}
      style={{display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '20vh'}}
    >
      <Paper style={{width: '35%', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1em', gap: '2em'}}>
        <Typography variant="h4">
          Confirmation
        </Typography>

        <Typography>
          {confirmationMessages[responseType]}
        </Typography>

        <TextField
          label="Comments"
          rowsMax={8}
          multiline
          onChange={event => setNotes(event.target.value)}
          required={responseType === RESPONSES.COUNTER || responseType === RESPONSES.REJECT}
          style={{ width: '100%' }}
          value={notes}
          variant="outlined"
        />

        { responseType === RESPONSES.COUNTER ?
          <div style={{width:'100%', display: 'flex', alignItems: 'flex-start'}}>
            <NumberField
              label="Counter Bid Value"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              onChange={e => setCounterValue(e.target.value)}
              size="small"
              variant="outlined"
              value={counterValue}
            />
          </div>
          : null }

        { (responseType === RESPONSES.COUNTER || responseType === RESPONSES.REJECT) ?
          <div style={{width:'100%', display: 'flex', gap: '0.5em', alignItems: 'center'}}>
            <Typography variant="body2">
              <b> Attachment (zip if sending more than 1 file) </b>
            </Typography>
            <input type="file" style={{width: '50%'}} onChange={e => setFile(e.target.files[0])}/>
          </div>
          : null }

        <div style={{display: 'flex', gap: '4em', paddingTop: '1em'}}>
          <Button
            onClick={onModalClose}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            onClick={() => sumbitResponse(responseType)}
            variant="contained"
          >
            Confirm
          </Button>
        </div>
      </Paper>
    </Modal>
  )
}
