import frg from '../feature-release-guards.js'

// max file size that can be uploaded to our system
export const MAX_ALLOWED_UPLOAD_FILE_SIZE_IN_MB = 100

// maximum file size that will be sent for param/wall thickness analysis
export const MAX_ALLOWED_FILE_SIZE_IN_BYTES = 104857600 // 100 MB

// maximum attachment file size allowed
export const MAX_ALLOWED_ATTACHMENT_FILE_SIZE_IN_BYTES = 31457280 // 30 MB
