const axios = require('axios')
const config = require('../../config.js')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function submitSupplierResponse(responseType, responseData, file){
  let response
  try{
    response = await submitSupplierResponseRequest(responseType, responseData, file)
  } catch (err){
    logUnexpected(err, { responseType, responseData, file }, 'submitSupplierResponseRequest')
    throw err
  }

  let result
  try{
    result = parseSubmitSupplierResponseResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseSubmitSupplierResponseResponse', { responseType, responseData, file })
    throw err
  }

  return result
}

function submitSupplierResponseRequest(responseType, responseData, file){
  const formData = new FormData();
  formData.append("file", file)
  formData.append("responseType", responseType)
  formData.append("responseData", JSON.stringify(responseData))

  return axios.post('/jobs/submitJobOffer', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    validateStatus: () => true,
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseSubmitSupplierResponseResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }

    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
