import React,{Component,Fragment} from 'react'
import {Button,LinearProgress,Paper,Tooltip,Typography,withStyles} from '@material-ui/core'
import { ActionLink } from '../../Common'
import {ArrowBack,ArrowForward} from '@material-ui/icons'
import generateCertificateOfConformance from './generateCertificateOfConformance.js'
import { generatePackslipPdf } from './generatePackslipPdf.js'
import { GeneratePaperworkOptionsForm } from './GeneratePaperworkOptionsForm.js'
import { generatePoPdf } from './generatePoPdf'
import generatePurchasingReport from './generatePurchasingReport.js'
import { getActivityByActivityNumber } from '../getActivityByActivityNumber.js'
import {navigate} from '@reach/router'
import ProjectFolderLinkButton from '../../Common/ProjectFolderLinkButton.js'
import { savePdfFileToGoogleDrive } from './savePdfFileToGoogleDrive.js'
import { updateProjectWithActivities } from '../updateProjectWithActivities'
import FORMS from '../FORMS.js'
import downloadPdfToLocalMachineFromBase64 from '../../../utils/downloadPdfToLocalMachineFromBase64.js'
import ClickableLink from './ClickableLink.js'
import PdfViewer from '../../Common/PdfViewer.js'

const DOCUMENT_TYPES = {
  COC: 'Certificate of Conformance',
  PO: 'Po',
  PACKSLIP: 'Packslip',
  PART_REPORT: 'PartReport',
  PURCHASING_REPORT: 'PurchasingReport'
}

class PaperworkForm extends Component {
  constructor(props){
    super(props)
    let activities = this.props.activities
    let defaultActivityNumber = activities[0].activityNumber
    this.state={
      activityNumber: defaultActivityNumber, //tracks activity to generate paperwork for
      currentPage: 1, //keeps track of current pdf page
      encodedCoc: undefined,
      encodedPackslip: undefined,
      encodedPartReport: undefined,
      encodedPO: undefined,
      encodedPurchaseReport: undefined,
      includeBoxesPaperwork: Boolean(activities[0].shipment && activities[0].shipment.boxes.length),
      isCocApproved: false,
      isCocChecked: Boolean(activities[0].shipment),
      isLoading: false,
      isPackslipApproved: false,
      isPackslipChecked: Boolean(activities[0].shipment),
      isPartReportApproved: false,
      isPartReportChecked: Boolean(activities[0].makeOrder),
      isPoApproved: false,
      isPoChecked: Boolean(activities[0].makeOrder),
      isPurchasingReportApproved: false,
      isPurchasingReportChecked: false,
      poTotal: 0,
      totalPages: 0, //counts number of pdf pages
      currentPDF: undefined, // current PDF being viewed on the page
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({totalPages: numPages})
  }

  previousPageHandler = () => {
    let nextPage = this.state.currentPage
    this.setState({currentPage: --nextPage})
  }

  nextPageHandler = () => {
    let nextPage = this.state.currentPage
    this.setState({currentPage: ++nextPage})
  }

  generatePaperworkHandler = async () => {
    if(this.state.currentPDF){
      this.setState({
        isCocApproved: false,
        isPackslipApproved: false,
        isPartReportApproved: false,
        isPoApproved: false,
        isPurchasingReportApproved: false,
      })
    }
    this.generatePaperwork()
  }

  generatePaperwork = async () => {
    const paperworkType = this.getCurrentDocumentType()
    let relevantActivity = getActivityByActivityNumber([...this.props.activities], this.state.activityNumber)

      if(paperworkType === DOCUMENT_TYPES.PURCHASING_REPORT){
        this.setState({ isLoading: true })
        await this.generatePurchasingReport(relevantActivity.makeOrder.makeOrderNumber)
        this.setState({currentPage: 1, currentPDF: this.state.encodedPurchaseReport})
      }
      else if(paperworkType === DOCUMENT_TYPES.PO){  //If user chooses to only generate PO
        this.setState({ isLoading: true })
        await this.generatePO(relevantActivity.makeOrder.makeOrderNumber)
        this.setState({currentPage: 1, currentPDF: this.state.encodedPO})
      }
      else if(paperworkType === DOCUMENT_TYPES.PART_REPORT){
        this.setState({ isLoading: true })
        await this.generatePartReport(relevantActivity.makeOrder.makeOrderNumber)
        this.setState({currentPage: 1, currentPDF: this.state.encodedPartReport})
      }
      else if(paperworkType === DOCUMENT_TYPES.PACKSLIP){
        this.setState({ isLoading: true })
        await this.generatePackslip(relevantActivity.shipment.shipmentNumber)
        this.setState({currentPage: 1, currentPDF: this.state.encodedPackslip})
      }
      else if(paperworkType === DOCUMENT_TYPES.COC){
        this.setState({ isLoading: true })
        await this.generateCertificateOfConformance(relevantActivity.shipment.shipmentNumber)
        this.setState({currentPage: 1, currentPDF: this.state.encodedCoc})
      }
  }

  generatePartReport = (moNumber) => {
    const UPDATED_PROJECT = updateProjectWithActivities({ ...this.props.project }, [...this.props.activities], this.props.MATERIAL_TRANSLATIONS)
    return generatePoPdf(UPDATED_PROJECT, moNumber)
      .then(({ encodedPartReport }) => {
        this.setState({ isLoading: false })
        this.setState({ encodedPartReport })
      })
      .catch(error => { //In case of error notify user
        this.setState({ isLoading: false })
        this.props.errorMessage(`An error occurred while generating Part Report PDF : ${error}`)
      })
  }

  generatePurchasingReport = (moNumber) => {
    const UPDATED_PROJECT = updateProjectWithActivities({ ...this.props.project }, [...this.props.activities], this.props.MATERIAL_TRANSLATIONS)
    return generatePurchasingReport(UPDATED_PROJECT, moNumber)
      .then(encodedPurchaseReport => {
        this.setState({ encodedPurchaseReport })
      })
      .catch(error => {
        this.props.errorMessage(`An error occurred generating Purchasing Report PDF : ${error}`)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  generatePackslip = (shipmentNumber) => {
    const UPDATED_PROJECT = updateProjectWithActivities({ ...this.props.project }, [...this.props.activities], this.props.MATERIAL_TRANSLATIONS)
    return generatePackslipPdf(UPDATED_PROJECT,shipmentNumber, this.state.includeBoxesPaperwork)
    .then(encodedPackslip => {
      this.setState({ isLoading: false })
      this.setState({ encodedPackslip })
    })
    .catch(error =>{  //In case of error notify user
      this.props.errorMessage(`An error occurred while generating Packslip PDF : ${error}`)
    })
  }

  generateCertificateOfConformance = (shipmentNumber) => {
    const UPDATED_PROJECT = updateProjectWithActivities({ ...this.props.project }, [...this.props.activities], this.props.MATERIAL_TRANSLATIONS)
    return generateCertificateOfConformance(UPDATED_PROJECT, shipmentNumber)
      .then(encodedCoc => {
        this.setState({ encodedCoc })
      })
      .catch(error => {
        this.props.errorMessage(`An error occurred while generating CofC Pdf : ${error.message}`)
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  generatePO = (moNumber) => {
    const UPDATED_PROJECT = updateProjectWithActivities({ ...this.props.project }, [...this.props.activities], this.props.MATERIAL_TRANSLATIONS)
    return generatePoPdf(UPDATED_PROJECT, moNumber)
      .then(({ encodedPO, encodedPartReport, poTotal }) => {
        this.setState({ isLoading: false })
        this.setState({ encodedPO, poTotal })
      })
      .catch(error => { //In case of error notify user
        this.setState({ isLoading: false })
        this.props.errorMessage(`An error occurred while generating PO PDF : ${error}`)
      })
  }

  savePdfHandler = async () => {
    try{
      let activities = [...this.props.activities]
      let activity = getActivityByActivityNumber(activities, this.state.activityNumber)

      if(this.state.isPurchasingReportChecked){
        await this.savePdfToGdrive(DOCUMENT_TYPES.PURCHASING_REPORT, this.state.activityNumber)
        this.props.errorMessage(`Purchasing Report saved to GDrive!`)
      }
      if(this.state.isPoChecked){
        await this.savePdfToGdrive(DOCUMENT_TYPES.PO, this.state.activityNumber)
        activity.makeOrder.poRevision++
        activity.makeOrder.poTotal = this.state.poTotal
        this.props.errorMessage(`Po saved to GDrive!`)
      }
      if(this.state.isPartReportChecked){
        await this.savePdfToGdrive(DOCUMENT_TYPES.PART_REPORT, this.state.activityNumber)
        this.props.errorMessage('Part Report saved to GDrive!')
      }
      if(this.state.isPackslipChecked){
        await this.savePdfToGdrive(DOCUMENT_TYPES.PACKSLIP, this.state.activityNumber)
        activity.shipment.packslipRevision++
        this.props.errorMessage(`Packslip saved to GDrive!`)
      }
      if(this.state.isCocChecked){
        await this.savePdfToGdrive(DOCUMENT_TYPES.COC, this.state.activityNumber)
        this.props.errorMessage(`CofC saved to GDrive!`)
      }

      this.props.saveActivities(activities)
      this.setState({
        currentPDF: undefined,
        encodedCoc: undefined,
        encodedPackslip: undefined,
        encodedPartReport: undefined,
        encodedPO: undefined,
        encodedPurchaseReport: undefined,
        isCocApproved: false,
        isPackslipApproved: false,
        isPartReportApproved: false,
        isPoApproved: false,
        isPurchasingReportApproved: false,
      })
    } catch(err){
      this.props.errorMessage(err.message)
    }
  }

  savePdfToGdrive = (documentType,activityNumber) => {
    validateSavePdfToGdriveArguments(documentType,activityNumber)
    this.setState({ isLoading: true })
    const activity = getActivityByActivityNumber([...this.props.activities],activityNumber)

    const {
      fileName,
      fileContents,
      fileLocation,
    } = this.getFileNameContentsAndLocation(activity, documentType)

    return savePdfFileToGoogleDrive(fileName, fileContents, fileLocation)
    .then(()=>{
      this.setState({ isLoading: false })
    })
    .catch((e)=>{
      this.setState({ isLoading: false })
      throw new Error(`There was an error saving the PDF to GDrive : ${JSON.stringify(e)}`)
    })
  }

  getFileNameContentsAndLocation = (activity,documentType) => {
    let fileName, fileContents, fileLocation
    if (documentType === DOCUMENT_TYPES.PURCHASING_REPORT) {
      fileName = `Purchasing Report ${activity.makeOrder.makeOrderNumber}`
      fileContents = this.state.encodedPurchaseReport
      fileLocation = activity.makeOrder.makeOrderFolderId
    }
    else if (documentType === DOCUMENT_TYPES.PO) {
      fileName = `PO ${activity.makeOrder.makeOrderNumber} r${activity.makeOrder.poRevision}`
      fileContents = this.state.encodedPO
      fileLocation = activity.makeOrder.makeOrderFolderId
    }
    else if (documentType === DOCUMENT_TYPES.PART_REPORT) {
      fileName = `Part Report ${activity.makeOrder.makeOrderNumber} r${activity.makeOrder.poRevision}`
      fileContents = this.state.encodedPartReport
      fileLocation = activity.makeOrder.makeOrderFolderId
    }
    else if (documentType === DOCUMENT_TYPES.PACKSLIP) {
      fileName = `Packslip ${activity.shipment.shipmentNumber} r${activity.shipment.packslipRevision}`
      fileContents = this.state.encodedPackslip
      fileLocation = activity.shipment.shipmentFolderId
    }
    else if (documentType === DOCUMENT_TYPES.COC) {
      fileName = `Certificate of Conformance ${activity.shipment.shipmentNumber}`
      fileContents = this.state.encodedCoc
      fileLocation = activity.shipment.shipmentFolderId
    }

    return({
      fileName,
      fileContents,
      fileLocation,
    })
  }

  onChange = event => {
    const target = event.target
    const {name,value} = target
    let newState = {
      [name]: value,
    }
    this.setState(newState)
  }

  onChangeActivityNumber = activityNumber => {
    const activity = getActivityByActivityNumber([...this.props.activities], activityNumber)
    const newState = {
      activityNumber,
      isCocChecked: Boolean(activity.shipment),
      isPackslipChecked: Boolean(activity.shipment),
      isPartReportChecked: Boolean(activity.makeOrder),
      isPoChecked: Boolean(activity.makeOrder),
      isPurchasingReportChecked: false,
    }
    this.setState(newState)
  }

  setIncludeBoxesPaperwork = includeBoxesPaperwork => this.setState({ includeBoxesPaperwork })

  setIsCocChecked = isCocChecked => this.setState({ isCocChecked })

  setIsPackslipChecked = isPackslipChecked => {
    if(isPackslipChecked){
      const activity = getActivityByActivityNumber([...this.props.activities], this.state.activityNumber)
      this.setState({ includeBoxesPaperwork: Boolean(activity.shipment.boxes.length) })
    } else {
      this.setState({ includeBoxesPaperwork: false })
    }
    this.setState({ isPackslipChecked })
  }

  setIsPoChecked = isPoChecked => this.setState({ isPoChecked })

  setIsPurchasingReportChecked = isPurchasingReportChecked => this.setState({ isPurchasingReportChecked })

  setIsPartReportChecked = isPartReportChecked => this.setState({ isPartReportChecked })

  downloadPdf = async () => {
    const documentType = this.getCurrentDocumentType()

    const activity = getActivityByActivityNumber([...this.props.activities],this.state.activityNumber)
    const { fileName, fileContents, fileLocation } = this.getFileNameContentsAndLocation(activity, documentType)

    await downloadPdfToLocalMachineFromBase64(fileContents, `${fileName}.pdf`)

    this.props.errorMessage(
      ClickableLink('Google Drive Folder', `https://drive.google.com/drive/folders/${fileLocation}`)
    )
  }

  approvePdf = () => {
    if(this.state.isPurchasingReportChecked && !this.state.isPurchasingReportApproved){
      if(!this.state.isPoChecked && !this.state.isPartReportChecked && !this.state.isPackslipChecked && !this.state.isCocChecked){
        this.setState({currentPDF: undefined})
      }
      this.setState({isPurchasingReportApproved: true}, () => this.generatePaperwork())
      return 
    }
    if(this.state.isPoChecked && !this.state.isPoApproved){
      if(!this.state.isPartReportChecked && !this.state.isPackslipChecked && !this.state.isCocChecked){
        this.setState({currentPDF: undefined})
      }
      this.setState({isPoApproved: true}, () => this.generatePaperwork())
      return 
    }
    if(this.state.isPartReportChecked && !this.state.isPartReportApproved){
      if(!this.state.isPackslipChecked && !this.state.isCocChecked){
        this.setState({currentPDF: undefined})
      }
      this.setState({isPartReportApproved: true}, () => this.generatePaperwork())
      return
    }
    if(this.state.isPackslipChecked && !this.state.isPackslipApproved){
      if(!this.state.isCocChecked){
        this.setState({currentPDF: undefined})
      }
      this.setState({isPackslipApproved: true}, () => this.generatePaperwork())
      return 
    }
    if(this.state.isCocChecked && !this.state.isCocApproved){
      this.setState({currentPDF: undefined})
      this.setState({isCocApproved: true}, () => this.generatePaperwork())
      return
    }
  }

  areAllApproved = () => {
    const atLeastOneIsSelected = this.state.isPackslipChecked || this.state.isPartReportChecked || this.state.isPoChecked || this.state.isPurchasingReportChecked || this.state.isCocChecked

    const allSelectedAreApproved = (
      (!this.state.isPurchasingReportChecked || (this.state.isPurchasingReportChecked && this.state.isPurchasingReportApproved))
        &&
        (!this.state.isPoChecked || (this.state.isPoChecked && this.state.isPoApproved))
        &&
        (!this.state.isPartReportChecked || (this.state.isPartReportChecked && this.state.isPartReportApproved))
        &&
        (!this.state.isPackslipChecked || (this.state.isPackslipChecked && this.state.isPackslipApproved))
        &&
        (!this.state.isCocChecked || (this.state.isCocChecked && this.state.isCocApproved))
    )

    return atLeastOneIsSelected && allSelectedAreApproved
  }

  getCurrentDocumentType = () => {
    if(this.state.isPurchasingReportChecked && !this.state.isPurchasingReportApproved){
      return DOCUMENT_TYPES.PURCHASING_REPORT
    }
    if(this.state.isPoChecked && !this.state.isPoApproved){
      return DOCUMENT_TYPES.PO
    }
    if(this.state.isPartReportChecked && !this.state.isPartReportApproved){
      return DOCUMENT_TYPES.PART_REPORT
    }
    if(this.state.isPackslipChecked && !this.state.isPackslipApproved){
      return DOCUMENT_TYPES.PACKSLIP
    }
    if(this.state.isCocChecked && !this.state.isCocApproved){
      return DOCUMENT_TYPES.COC
    }
  }

  restart = () => {
    this.setState({
      currentPDF: undefined,
      encodedPackslip: undefined,
      encodedPartReport: undefined,
      encodedPO: undefined,
      encodedPurchaseReport: undefined,
      isCocApproved: false,
      isPackslipApproved: false,
      isPartReportApproved: false,
      isPoApproved: false,
      isPurchasingReportApproved: false,
    })
  }

  render(){
    return (
      <Fragment>
        <div align="center" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2em' }}>
          <div className={this.props.classes.topTable}>
          <div className={this.props.classes.flexRow}>
            <GeneratePaperworkOptionsForm
              activities={this.props.activities}
              activityNumber={this.state.activityNumber}
              classes={this.props.classes}
              currentPDF={this.state.currentPDF}
              disabled={this.state.isLoading || this.state.currentPDF || this.state.encodedPartReport || this.state.encodedPO || this.state.encodedPurchaseReport}
              generatePaperwork={this.generatePaperworkHandler}
              includeBoxesPaperwork={this.state.includeBoxesPaperwork}
              isCocApproved={this.state.isCocApproved}
              isCocChecked={this.state.isCocChecked}
              isPackslipApproved={this.state.isPackslipApproved}
              isPackslipChecked={this.state.isPackslipChecked}
              isPartReportApproved={this.state.isPartReportApproved}
              isPartReportChecked={this.state.isPartReportChecked}
              isPoApproved={this.state.isPoApproved}
              isPoChecked={this.state.isPoChecked}
              isPurchasingReportApproved={this.state.isPurchasingReportApproved}
              isPurchasingReportChecked={this.state.isPurchasingReportChecked}
              onChange={this.onChange}
              onChangeActivityNumber={this.onChangeActivityNumber}
              setIncludeBoxesPaperwork={this.setIncludeBoxesPaperwork}
              setIsCocChecked={this.setIsCocChecked}
              setIsPackslipChecked={this.setIsPackslipChecked}
              setIsPartReportChecked={this.setIsPartReportChecked}
              setIsPoChecked={this.setIsPoChecked}
              setIsPurchasingReportChecked={this.setIsPurchasingReportChecked}
            />
            <div className={this.props.classes.buttonsTable}>
              <div className={this.props.classes.buttonsSection}>
                <EmojiLink
                  emoji="🏠"
                  emojiLabel="house"
                  label="Return to Home"
                  onClick={this.props.onDoneButtonClick}
                />
                <EmojiLink
                  emoji="📦"
                  emojiLabel="package"
                  label="Return to Shipment Tracking"
                  onClick={()=>{this.props.changeForms(FORMS.activities)}}
                />
                <EmojiLink
                  emoji="📂"
                  emojiLabel="folder"
                  label="Google Drive Folder"
                  onClick={() => {window.open(this.props.project.projectFolderLink)}}
                />
                <Button
                  color="primary"
                  disabled={!this.state.currentPDF}
                  onClick={this.downloadPdf}
                  variant="contained"
                >
                  Download PDF
                </Button>
              </div>
              {this.state.currentPDF || this.state.encodedPartReport || this.state.encodedPackslip || this.state.encodedPO || this.state.encodedPurchaseReport || this.areAllApproved() ?
               <Button
                 className={this.props.classes.paperworkButton}
                 onClick={this.restart}
                 variant="contained"
               >
                 Restart
               </Button>
               : null}
            </div>
          </div>

          <div className={this.props.classes.topTable}>
            <Button
              className={this.props.classes.paperworkButton}
              color="secondary"
              onClick={this.areAllApproved() ? this.savePdfHandler : (this.state.currentPDF ? this.approvePdf : this.generatePaperwork)}
              variant="contained"
            >
              {this.areAllApproved() ? 'Save PDFs to Gdrive' : (this.state.currentPDF ? 'Looks Good!' : 'Generate Paperwork')}
            </Button>
          </div>
        </div>
          <br/>

          <Paper className={this.props.classes.paper}>
            {(this.state.isLoading) ? <LinearProgress color="primary" /> : null}
            {this.state.currentPDF ?
              <PdfViewer
                classes={this.props.classes}
                currentPage={this.state.currentPage}
                currentPDF={this.state.currentPDF}
                nextPageHandler={this.nextPageHandler}
                onDocumentLoadSuccess={this.onDocumentLoadSuccess}
                previousPageHandler={this.previousPageHandler}
                totalPages={this.state.totalPages}
              />
              : null
            }
            {(this.state.isLoading) ? <LinearProgress color="primary" /> : null}
          </Paper>
        </div>
      </Fragment>
    )
  }
}

function EmojiLink(props){
  return (
    <div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
      <Emoji {...props}/>
      <ActionLink action={props.onClick} label={props.label} theme="secondary" />
    </div>
  )
}

function Emoji(props){
  return (
    <span
      role="img"
      aria-label={props.emojiLabel ? props.emojiLabel : ""}
      aria-hidden={props.emojiLabel ? "false" : "true"}
    >
      {props.emoji}
    </span>
  )
}

function validateSavePdfToGdriveArguments(documentType, activityNumber) {
  const VALID_DOCUMENT_TYPES = Object.values(DOCUMENT_TYPES)
  if (!VALID_DOCUMENT_TYPES.includes(documentType)) {
    throw new Error(`Document of type ${documentType} not supported`)
  }
}

const styles = theme => ({
  topTable:{
    display: 'flex',
    flexDirection: 'column',
  },
  buttons:{
    margin: theme.spacing(2)
  },
  buttonsTable:{
    borderWidth: '2px',
    borderLeftWidth: '0px',
    borderColor: '#cccccc',
    borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  buttonsSection:{
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  labelCell:{
    border: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    width: '130px'
  },
  flexRow:{
    display: 'flex',
  },
  menuCell:{
    border: '0px',
    paddingLeft: '0px',
    paddingRight: '0px'
  },
  paper:{
    background: '#cccccc',
    width: theme.spacing(125)
  },
  pdf:{
    padding: theme.spacing(2)
  },
  pdfPagingBar:{
    paddingBottom: theme.spacing(2)
  },
  settingsTable:{
    borderWidth: '2px',
    borderColor: '#cccccc',
    borderStyle: 'solid',
    padding: theme.spacing(2),
    width: theme.spacing(50)
  },
  selectMenu:{
    width: '100%'
  }
})

export default withStyles(styles)(PaperworkForm)
