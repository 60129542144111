import { pollDatabaseForResult } from './pollDatabaseForResult.js'

export default async function pollDatabaseForParamExtractionResult(partNumber) {
    const PARAM_FIELDS_SUCCESS = ['isCncSetupsJobDone','cncMillOrTurn']
    const PARAM_FIELD_FAIL = ''

    const throttle = 3000
    const timeout = 180000

    return pollDatabaseForResult(partNumber, PARAM_FIELDS_SUCCESS, PARAM_FIELD_FAIL, throttle, timeout)
}
