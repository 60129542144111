import React,{Component} from 'react'
import ACTIVITY_TYPES from './ACTIVITY_TYPES.js'
import { calculateArrivalDateFromShipDateAndShipDayEstimation, calculateShipDateFromOrderDateAndLeadTimeDays } from './calculateDateFromBusinessDayOffset.js'
import { deepCopyArray, deepCopyObject } from './deepCopyUtils.js'
import { generateActivities } from './generateActivities.js'
import { generateActivityNumbers } from './generateActivityNumbers'
import { getActivityByActivityNumber } from './getActivityByActivityNumber.js'
import loadSupplierTypes from './loadSupplierTypes.js'
import loadSuppliers from './loadSuppliers.js'
import loadSupplierLists from './loadSupplierLists.js'
import {
  loadProject,
} from '../../utils'
import ShipmentTracking from './ShipmentTracking.js'
import { shipMethodNumberOfDays } from './shipMethodNumberOfDays.js'
import { updateActivityWithNewValue, updateActivityWithPtpcPartBatchValue } from './updateActivityWithNewValue.js'
import { updateProjectWithActivities } from './updateProjectWithActivities'
import { updateFoldersInGoogleDriveAndDecorateProjectWithFolderIds } from './updateFoldersInGoogleDriveAndDecorateProjectWithFolderIds.js'
import { updateProjectAndActivitiesWithNewActivity } from './updateProjectAndActivitiesWithNewActivity.js'
import withSnackbar from './withSnackbar.js'
import FORMS from './FORMS.js'


class SmartShipmentTracking extends Component {
  constructor(props){
    super(props)

    this.state = {
      activities: generateActivities(this.props.project),
      currentForm: FORMS.activities,
      sourcingMakeOrderId: null,
      sourcingMakeOrderNumber: null,
      shipmentInBoxes: null,
      suppliers: {
        DOMESTIC_SUPPLIER_ARRAY: [],
        INTERNATIONAL_SUPPLIER_ARRAY: [],
        CLOSED_SUPPLIER_ARRAY: [],
      },
      SUPPLIER_TYPES: ['Loading supplier types...'],
    }
  }

  componentDidMount(){
    this.loadSupplierTypes()
    this.loadSuppliers()

    // Instantiate missing derived dates
    const dateFormat = "MM/DD/YYYY"
    const projectCopy = { ...this.props.project }

    projectCopy.makeOrders = projectCopy.makeOrders
      .map(makeOrder => {
        if(makeOrder.purchaseOrderDate && makeOrder.leadTime && !makeOrder.moPaidShipDate){
          makeOrder.moPaidShipDate = calculateShipDateFromOrderDateAndLeadTimeDays(makeOrder.purchaseOrderDate, dateFormat, parseInt(makeOrder.leadTime, 10)).format(dateFormat)
        }
        return makeOrder
      })

    projectCopy.shipments = projectCopy.shipments
      .map(shipment => {
        if(shipment.paidShipDate && shipment.paidShipMethod && !shipment.paidArrivalDate){
          shipment.paidArrivalDate = calculateArrivalDateFromShipDateAndShipDayEstimation(shipment.paidShipDate, dateFormat, shipMethodNumberOfDays(shipment.paidShipMethod), false).format(dateFormat)
        }
        if(shipment.actualShipDate && shipment.actualShipMethod && !shipment.actualArrivalDate){
          shipment.actualArrivalDate = calculateArrivalDateFromShipDateAndShipDayEstimation(shipment.actualShipDate, dateFormat, shipMethodNumberOfDays(shipment.actualShipMethod), false).format(dateFormat)
        }
        return shipment
      })

    this.props.setProject(projectCopy, { dontRecalc: true, saveProject: true })
  }

  componentDidUpdate(prevProps){
    // if props.project changes, regenerate activities
    if (this.props.project !== prevProps.project) {
      this.setState({
        activities: generateActivities(this.props.project),
      })
    }
  }

  loadSuppliers = () => {
    loadSuppliers()
      .then(s => loadSupplierLists(s))
      .then(suppliers => this.setState({suppliers}))
      .catch(e => this.props.openSnackbarWithMessage(`Error loading suppliers: ${e.message}`))
  }

  loadSupplierTypes = () => {
    loadSupplierTypes()
      .then(supplierTypes => this.setState({SUPPLIER_TYPES:supplierTypes}))
      .catch(e => this.props.openSnackbarWithMessage(`Error loading supplier types: ${e.message}`))
  }

  newActivityHandler = (newActivityType,activity) => {
    const project = updateProjectAndActivitiesWithNewActivity(
      newActivityType,
      activity,
      deepCopyObject(this.props.project),
      deepCopyArray(this.state.activities),
      this.props.MATERIAL_TRANSLATIONS,
    )

    this.props.setProject(project, {dontRecalc: true})
  }

  changeForms = async form => {
    // grab makeOrders, shipments, and partBatches from activities and merge into project before using project object
    let project = updateProjectWithActivities(deepCopyObject(this.props.project), deepCopyArray(this.state.activities), this.props.MATERIAL_TRANSLATIONS)
    if(form === FORMS.paperwork || form === FORMS.sourcing){
      try{
        project = await updateFoldersInGoogleDriveAndDecorateProjectWithFolderIds(project)
      } catch(err){
        if(err.message === 'Unexpected Status Code: 500'){
          err.message = 'Google drive timed out. Please try again.'
        }
        this.props.openSnackbarWithMessage(err.message)
        return
      }

      project = await this.props.saveProjectPromise(project, false)
      // reload project as temp workaround because the above call doesn't return
      // deeply nested objects such as postProcesses
      project = await loadProject(project.projectNumber, this.props.user)
    }

    this.props.setProject(project, {dontRecalc: true})
    this.setState({
      currentForm: form,
      shipmentInBoxes: null,
    })
    return
  }

  changeToSourcingAttemptForm = async (makeOrder) => {
    this.setState({
      sourcingMakeOrderNumber: makeOrder.makeOrderNumber,
      sourcingMakeOrderId: makeOrder.id
    })
    await this.changeForms(FORMS.sourcing)
  }

  onChangeHandler = (eventActivityNumber,event,partGroupNumber) => {
    console.log({eventActivityNumber,event,partGroupNumber})
    const {name,value} = event.target
    console.log({name,value})

    const activities = updateActivityWithNewValue(
      deepCopyArray(this.state.activities),
      eventActivityNumber,
      partGroupNumber,
      name,
      value,
      this.state.suppliers,
    )

    const updatedActivities = generateActivityNumbers(activities, deepCopyObject(this.props.project))
    this.props.setProject(updateProjectWithActivities(deepCopyObject(this.props.project), updatedActivities, this.props.MATERIAL_TRANSLATIONS), {dontRecalc: true})
  }

  onChangePtpcHandler = (eventActivityNumber,event,id) => {
    const {name,value} = event.target

    const activities = updateActivityWithPtpcPartBatchValue(
      deepCopyArray(this.state.activities),
      eventActivityNumber,
      id,
      name,
      value
    )

    const updatedActivities = generateActivityNumbers(activities, deepCopyObject(this.props.project))
    this.props.setProject(updateProjectWithActivities(deepCopyObject(this.props.project), updatedActivities, this.props.MATERIAL_TRANSLATIONS), {dontRecalc: true})
  }

  openBoxesForm = async (shipmentNumber) => {
    let project = updateProjectWithActivities(deepCopyObject(this.props.project), deepCopyArray(this.state.activities), this.props.MATERIAL_TRANSLATIONS)

    const shimentWithNoId = shipment => !shipment.id
    if(project.shipments.find(shimentWithNoId)){
      project = await this.props.saveProjectPromise(project, false)
    }

    const shipment = project.shipments
      .find(sh => sh.shipmentNumber === shipmentNumber)

    this.props.setProject(project, {dontRecalc: true})
    this.setState({
      currentForm: FORMS.boxes,
      shipmentInBoxes: shipment,
    })
    return
  }

  saveBoxesToShipment = async (shipmentId, boxes) => {
    let projectCopy = deepCopyArray(this.props.project)
    const shipment = projectCopy.shipments
          .find(sh => sh.id === shipmentId)

    shipment.boxes = boxes
    projectCopy = updatePartBatchesWithBoxTotals(projectCopy, shipment, boxes)

    projectCopy = await this.props.saveProjectPromise(projectCopy, false)
    // reload project as temp workaround because the above call doesn't return
    // deeply nested objects such as postProcesses
    projectCopy = await loadProject(projectCopy.projectNumber, this.props.user)

    this.props.setProject(projectCopy, {dontRecalc: true})
    this.setState({
      currentForm: FORMS.activities,
    })
  }

  onChangeProjectFieldHandler = (event, saveBool = false) => {
    const {name,value} = event.target

    const project = deepCopyObject(this.props.project)
    project[name] = value

    this.props.setProject(project, {dontRecalc: true, saveProject: saveBool, saveProjectDebounce: 3000})
  }

  saveActivities = (activities) => {
    const project = updateProjectWithActivities(deepCopyObject(this.props.project), activities, this.props.MATERIAL_TRANSLATIONS)
    this.props.saveProjectHandler(project)
  }

  onChangeActivityType = (e, activityNumber) => {
    const { name, value } = e.target

    let activities = updateActivityWithNewValue(
      deepCopyArray(this.state.activities),
      activityNumber,
      null,
      "reprintBool",
      (value === ACTIVITY_TYPES.REPRINT),
      this.state.suppliers,
    )

    activities = updateActivityWithNewValue(
      activities,
      activityNumber,
      null,
      "isSamplesOrder",
      (value === ACTIVITY_TYPES.SAMPLES),
      this.state.suppliers,
    )

    const updatedActivities = generateActivityNumbers(activities, deepCopyObject(this.props.project))
    this.props.setProject(updateProjectWithActivities(deepCopyObject(this.props.project), updatedActivities, this.props.MATERIAL_TRANSLATIONS), {dontRecalc: true})
  }

  updateSupplierPricing = (activityNumber) => {
    let makeOrders
    try{
      const {pricingData,selectedLeadTime} = JSON.parse(this.props.project.selectedPricingData)
      const makeOrders = pricingData.leadTimePricePairs
            .find(ltpp => {
              return ltpp.price === selectedLeadTime
            })
            .makeOrders

      const partGroupPrices = makeOrders.reduce(makeOrdersToPartGroupPrices, [])
      const ptpcPrices = makeOrders.reduce(makeOrdersToPtpcPrices, [])

      const activities = deepCopyArray(this.state.activities)
      const activity = getActivityByActivityNumber(activities, activityNumber)

      activity.partBatches = activity.partBatches
        .map(pb => {
          if(pb.productionToolPartConfigurationId){
            const price = ptpcPrices
                  .find(price => price.productionToolPartConfigurationId === pb.productionToolPartConfigurationId)

            pb.customUnitPrice = price.supplierUnitPrice
          } else {
            const price = partGroupPrices
                  .find(price => price.partGroupNumber === pb.partGroup)

            pb.customUnitPrice = price.supplierUnitPrice
          }
          return pb
        })

      const updatedActivities = generateActivityNumbers(activities, deepCopyObject(this.props.project))
      this.props.setProject(updateProjectWithActivities(deepCopyObject(this.props.project), updatedActivities, this.props.MATERIAL_TRANSLATIONS), {dontRecalc: true})
    } catch(e) {
      this.props.openSnackbarWithMessage(`Could not get pricing: ${e.message}`)
    }
  }

  render(){
    return(
        <ShipmentTracking
          activities={this.state.activities}
          changeForms={this.changeForms}
          changeToSourcingAttemptForm={this.changeToSourcingAttemptForm}
          currentForm={this.state.currentForm}
          currentlySaving={this.props.currentlySaving}
          errorMessage={this.props.openSnackbarWithMessage}
          MATERIAL_TRANSLATIONS={this.props.MATERIAL_TRANSLATIONS}
          newActivityHandler={this.newActivityHandler}
          onChange={this.onChangeHandler}
          onChangeActivityType={this.onChangeActivityType}
          onChangePtpc={this.onChangePtpcHandler}
          onChangeProjectFieldHandler={this.onChangeProjectFieldHandler}
          onDoneButtonClick={this.props.onDoneButtonClick}
          openBoxesForm={this.openBoxesForm}
          postProcessData={this.props.postProcessData}
          project={this.props.project}
          saveActivities={this.saveActivities}
          saveBoxesToShipment={this.saveBoxesToShipment}
          sourcingMakeOrderId={this.state.sourcingMakeOrderId}
          sourcingMakeOrderNumber={this.state.sourcingMakeOrderNumber}
          shipment={this.state.shipmentInBoxes}
          suppliers={this.state.suppliers}
          SUPPLIER_TYPES={this.state.SUPPLIER_TYPES}
          updateSupplierPricing={this.updateSupplierPricing}
        />
    )
  }
}

export default withSnackbar(SmartShipmentTracking)

function updatePartBatchesWithBoxTotals(project, shipment, boxes){
  shipment.partBatches = shipment.partBatches
    .map(partBatch => {
      const quantityInBoxes = getQuantityInBoxes(partBatch.partGroup, boxes)
      partBatch.quantity = quantityInBoxes
      return partBatch
    })

  shipment.ptpcPartBatches = shipment.ptpcPartBatches
    .map(ptpcPartBatch => {
      const quantityInBoxes = getPtpcQuantityInBoxes(ptpcPartBatch.productionToolPartConfigurationId, boxes)
      ptpcPartBatch.quantity = quantityInBoxes
      return ptpcPartBatch
    })

  if(shipment.makeOrder && shipment.makeOrder[0] && shipment.makeOrder[0].id){
    const makeOrder = project.makeOrders
          .find(mo => mo.id === shipment.makeOrder[0].id)

    makeOrder.partBatches = makeOrder.partBatches
    .map(partBatch => {
      const quantityInBoxes = getQuantityInBoxes(partBatch.partGroup, boxes)
      partBatch.quantity = quantityInBoxes
      return partBatch
    })

    makeOrder.ptpcPartBatches = makeOrder.ptpcPartBatches
      .map(ptpcPartBatch => {
        const quantityInBoxes = getPtpcQuantityInBoxes(ptpcPartBatch.productionToolPartConfigurationId, boxes)
        ptpcPartBatch.quantity = quantityInBoxes
        return ptpcPartBatch
      })
  }

  return project
}

function getQuantityInBoxes(partGroupNumber, boxes){
  return boxes.reduce(boxesToPartGroupTotalQuantity, 0)

  function boxesToPartGroupTotalQuantity(total, box){
    const partGroupInBox = box.partsInBox
      .find(partInBox => partInBox.partGroupNumber === partGroupNumber)

    const totalInBox = partGroupInBox.quantity
    return +total + +totalInBox
  }
}

function getPtpcQuantityInBoxes(ptpcId, boxes){
  return boxes.reduce(boxesToPartGroupTotalQuantity, 0)

  function boxesToPartGroupTotalQuantity(total, box){
    const ptpcInBox = box.partsInBox
      .find(partInBox => partInBox.ptpcId === ptpcId)

    const totalInBox = ptpcInBox ? ptpcInBox.quantity : 0
    return +total + +totalInBox
  }
}

function makeOrdersToPartGroupPrices(pricesArray,makeOrder){
  makeOrder.partGroupSupplierPrices
    .forEach(price => pricesArray.push(price))
  return pricesArray
}

function makeOrdersToPtpcPrices(pricesArray,makeOrder){
  makeOrder.ptpcSupplierPrices
    .forEach(price => pricesArray.push(price))
  return pricesArray
}
