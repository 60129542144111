import React,{useEffect} from 'react'
import {AppBar,Toolbar,Typography,withStyles} from '@material-ui/core'
import Logo from '../../Images/logo.png'
import {Link} from "@reach/router"

const ConfirmationPage = ({classes,disableUserNavigationToCheckoutPage,user}) => {

  useEffect(() => {
    // this prevents users from using browser forward/back pages to navigate to Checkout page
    disableUserNavigationToCheckoutPage()
  }, ['ONMOUNT'])

  return(
  <>
    <TopAppBar
      name={user.name}
      styles={classes.links}
    />
    <div className={classes.container}>
      <Typography align="center" color="primary" gutterBottom={true} variant="h4">
        Thank You
      </Typography>
        <div className={classes.subText}>
          <Typography align="left" gutterBottom={true} variant="subtitle1">
            Thank you for submitting your project to Autotiv! A Digital Manufacturing Engineer will review your parts for manufacturability shortly.
          </Typography>
          <Typography align="left" gutterBottom={true} variant="subtitle1">
            If you have any questions or comments on this ordering experience please send your thoughts to <a href="mailto:contact@autotiv.com">contact@autotiv.com</a>.
          </Typography>
          <Typography align="left" gutterBottom={true} variant="subtitle1">
            Have a great day!
          </Typography>
        </div>
    </div>
    <AppBar className={classes.bottomAppBar} position="fixed">
      <div align="center" className={classes.bottomFlex}>
        <a
          className={classes.links}
          href="https://s3.amazonaws.com/autotiv.com/terms-and-conditions/Autotiv+Terms+and+Conditions.pdf"
          rel="noopener noreferrer"
          target="_blank">General Terms and Conditions
        </a>
        <div style={{alignItems:'center',display:'flex'}}>
          <Link to="/">
            <LogoImage/>
          </Link>
          <Typography className={classes.rights} variant="body1">&copy;{new Date().getFullYear()} Autotiv, All Rights Reserved</Typography>
        </div>
      </div>
    </AppBar>
  </>
  )
}

const LogoImage = () => {
  return(
    <img
      alt="logo"
      style={{
        height: '40px',
        marginBottom: "8px",
        marginRight: "8px",
        marginTop: "8px"
      }}
      src={Logo}
    />
  )
}

const TopAppBar = ({ name, styles }) => {
  return(
    <AppBar position="static">
      <Toolbar>
        <Link to="/">
          <LogoImage/>
        </Link>
        <div style={{marginLeft: 'auto'}}>
          {name}
          <PipeSeparator/>
          <Link className={styles} to="/">New Quote</Link>
          <PipeSeparator/>
          <Link className={styles} to="/account">Account Settings</Link>
          <PipeSeparator/>
          <a
            className={styles}
            href="https://autotiv.com/contact"
            rel="noopener noreferrer"
            target="_blank"
          >
            Contact
          </a>
        </div>
      </Toolbar>
    </AppBar>
  )
}

const PipeSeparator = () => {
  return(
    <span style={{paddingLeft:'4px',paddingRight:'4px'}}>|</span>
  )
}

const STYLES = theme => ({
  bottomAppBar:{
    alignItems: 'center',
    bottom: 0,
    top: 'auto'
  },
  bottomFlex:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  container:{
    height: '100%',
    marginBottom: '20vh',
    marginLeft: '10vw',
    marginRight: '10vw',
    marginTop: '20vh',
    minHeight: '200px',
    minWidth: '635px',
    textAlign: 'center'
  },
  links:{
    color:'white',
    '&:visited':{
      color:'white',
    },
    '&:hover':{
      color:'#dedede',
    },
  },
  rights:{
    color:'white'
  },
  subText:{
    marginLeft: '15vw',
    marginRight: '15vw',
    textAlign: 'center'
  }
})

export default withStyles(STYLES)(ConfirmationPage)
