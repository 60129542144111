import { pollDatabaseForResult } from './pollDatabaseForResult.js'

export default async function pollDatabaseForFileConversionResult(partNumber) {
  const FIELD_SUCCESS = [ 's3StlFileIdNew', 's3GlbFileId' ]
  const FIELD_FAIL = [ '' ]

  const throttle = 3000
  const FIVE_MINUTES_IN_MS = 5 * 60 * 1000
  const timeout = FIVE_MINUTES_IN_MS

  return pollDatabaseForResult(partNumber, FIELD_SUCCESS, FIELD_FAIL, throttle, timeout)
}
