const axios = require('axios')
const config = require('../../config.js')
const { logUnexpected } = require('../../utils/logUnexpected.js')

export default async function loadSupplierTypes(){
  return [
    'autotiv_economy',
    'autotiv_yellow',
    'autotiv_orange',
    'autotiv_red',
    'machining_us_economy',
    'machining_us_yellow',
    'machining_us_orange',
    'machining_us_red',
    'lowVolumeMachining_china_economy',
    'lowVolumeMachining_china_yellow',
    'lowVolumeMachining_china_orange',
    'lowVolumeMachining_china_red',
    'highVolumeMachining_china_economy',
    'highVolumeMachining_china_yellow',
    'highVolumeMachining_china_orange',
    'highVolumeMachining_china_red',
    'lowVolumeInjectionMolding_china_economy',
    'lowVolumeInjectionMolding_china_yellow',
    'lowVolumeInjectionMolding_china_orange',
    'lowVolumeInjectionMolding_china_red',
    'highVolumeInjectionMolding_china_economy',
    'highVolumeInjectionMolding_china_yellow',
    'highVolumeInjectionMolding_china_orange',
    'highVolumeInjectionMolding_china_red',
    '3dPrinting_china_economy',
    '3dPrinting_china_yellow',
    '3dPrinting_china_orange',
    '3dPrinting_china_red'
  ]

  let response
  try{
    response = await loadSupplierTypesRequest()
  } catch (err){
    logUnexpected(err, {}, 'loadSupplierTypesRequest')
    throw err
  }

  let result
  try{
    result = parseLoadSupplierTypesResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseLoadSupplierTypesResponse', {})
    throw err
  }

  return result
}

function loadSupplierTypesRequest(){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: config.LOAD_SUPPLIER_TYPES_ENDPOINT,
    data: {
      
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseLoadSupplierTypesResponse(response) {
  if(response.status === 200){
    if(response.data && response.data.error){
      throw new Error(`Request failed with message: ${response.data.error}`)
    }

    if(response.data && response.data.success){
      return response.data.success
    } else {
      const responseDataFormatError = new Error('Malformed body format')
      responseDataFormatError.responseData = response.data
      throw responseDataFormatError
    }
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
