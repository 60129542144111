import { booleanFromTrueFalseString } from './booleanFromTrueFalseString.js'
import { calculateArrivalDateFromShipDateAndShipDayEstimation, calculateShipDateFromOrderDateAndLeadTimeDays } from './calculateDateFromBusinessDayOffset.js'
import { generateActivityNumbers } from './generateActivityNumbers'
import { getPartBatchByPartGroupNumber } from './getPartBatchByPartGroupNumber.js'
import { getActivityByActivityNumber } from './getActivityByActivityNumber.js'
import { parseDateFromDatePicker } from './datePickerDataParsing.js'
import { shipMethodNumberOfDays } from './shipMethodNumberOfDays.js'

export function updateActivityWithNewValue(activities, activityNumber, partGroupNumber, name, value, suppliers) {
  const dateFormat = "MM/DD/YYYY"
  let activity = getActivityByActivityNumber(activities, activityNumber)
  const DATE_FIELDS = ['purchaseOrderDate','paidShipDate','actualShipDate','moPaidShipDate']
  if(DATE_FIELDS.includes(name)){
    value = parseDateFromDatePicker(value)
  }

  switch (name) {
    case 'purchaseOrderDate':
    case 'leadTime':
      activity.makeOrder[name] = value
      let updatedMoPaidShipDate = calculateShipDateFromOrderDateAndLeadTimeDays(activity.makeOrder.purchaseOrderDate, dateFormat, parseInt(activity.makeOrder.leadTime, 10))
      activity.makeOrder.moPaidShipDate = (activity.makeOrder.purchaseOrderDate === '' || activity.makeOrder.leadTime === '') ? '' : updatedMoPaidShipDate.format(dateFormat)
      break

    case 'paidShipDate':
    case 'paidShipMethod':
      activity.shipment[name] = value
      let updatedPaidArrivalDate = calculateArrivalDateFromShipDateAndShipDayEstimation(activity.shipment.paidShipDate, dateFormat, shipMethodNumberOfDays(activity.shipment.paidShipMethod), false)
      activity.shipment.paidArrivalDate = activity.shipment.paidShipDate && activity.shipment.paidShipMethod ? updatedPaidArrivalDate.format(dateFormat) : ""
      break

    case 'actualShipDate':
    case 'actualShipMethod':
      activity.shipment[name] = value
      const updatedActualArrivalDate = calculateArrivalDateFromShipDateAndShipDayEstimation(activity.shipment.actualShipDate, dateFormat, shipMethodNumberOfDays(activity.shipment.actualShipMethod), false)
      activity.shipment.actualArrivalDate = activity.shipment.actualShipDate && activity.shipment.actualShipMethod ? updatedActualArrivalDate.format(dateFormat) : ""
      break

    case 'supplierId':
      activity.supplier[0] = retrieveSupplierObject(value, suppliers)
      break

    case 'moSupplierNotes':
    case 'poTotal':
    case 'poRevision':
    case 'moOurNotes':
    case 'pricingSupplier':
      activity.makeOrder[name] = value
      break

    case 'isSamplesOrder':
    case 'reprintBool':
      activity.makeOrder[name] = booleanFromTrueFalseString(value)
      break

    case 'shipSupplierNotes':
    case 'shipOurNotes':
      activity.shipment[name] = value
      break

    case 'partBatchesQuantity':
      let partBatchQuantityUpdate = getPartBatchByPartGroupNumber(activity.partBatches, partGroupNumber)
      partBatchQuantityUpdate.quantity = parseInt(value, 10)
      break

    case 'partBatchesCustomUnitPrice':
      let partBatchPriceUpdate = getPartBatchByPartGroupNumber(activity.partBatches, partGroupNumber)
      partBatchPriceUpdate.customUnitPrice = value
      break

    default:
      activity[name] = value
  }

  return activities
}

export function updateActivityWithPtpcPartBatchValue(activities, activityNumber, productionToolPartConfigurationId, name, value){
  const activity = getActivityByActivityNumber(activities, activityNumber)

  switch (name) {
    case 'partBatchesQuantity':
      const partBatchToUpdateWithQuantity = getPtpcPartBatchById(activity.partBatches, productionToolPartConfigurationId)
      partBatchToUpdateWithQuantity.quantity = parseInt(value, 10)
      break

    case 'partBatchesCustomUnitPrice':
      const partBatchToUpdateWithPrice = getPtpcPartBatchById(activity.partBatches, productionToolPartConfigurationId)
      partBatchToUpdateWithPrice.customUnitPrice = value
      break

    default:
      throw new Error(`on change handler not set up for this name: ${name}`)
  }

  return activities
}

function getPtpcPartBatchById(partBatches, productionToolPartConfigurationId){
  return partBatches
    .find(ptpcPb => ptpcPb.productionToolPartConfigurationId === productionToolPartConfigurationId)
}

function retrieveSupplierObject(supplierId, suppliers){
  let translation = suppliers.find(supplier =>{
    return (supplier.id === supplierId)
  })
  return translation
}
