import config from "../../../config"
import { getFileExtension } from '../../../utils/validateFile.js'
const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export async function startWallThicknessTask(part) {
  // default to analyzing the user-uploaded part file
  let s3Id = part.partFileS3
  let fileName = part.fileName

  if (isMoreEfficientToAnalyzeObjFile(part)) {
    s3Id = part.s3ObjFileIdNew ? part.s3ObjFileIdNew : part.s3ObjFileId
    fileName = part.fileName + '.obj'
  }

  let response
  try {
    response = await startWallThicknessTaskRequest(part, s3Id, fileName)
  } catch (err) {
    logUnexpected(err,{ part, s3Id, fileName }, 'startWallThicknessTaskRequest')
    throw err
  }

  let result
  try {
    result = parseStartWallThicknessTaskResponse(response)
  } catch (err) {
    logUnexpected(err, { response }, 'parseStartWallThicknessTaskResponse', { part, s3Id, fileName })
    throw err
  }

  return result
}

async function startWallThicknessTaskRequest(part, s3Id, fileName) {
  const response = await axios({
    method: 'POST',
    validateStatus: () => true,
    url: config.START_GEOM_ANALYSIS_ENDPOINT,
    data: {
      fileSize: part.fileSize,
      message: {
        taskName: 'wallthickness',
        s3Id: s3Id,
        fileName: fileName,
        units: part.units,
        partNumber: part.partNumber.toString(),
      }
    }
  })
  return { status: response.status, data: response.data }
}

function parseStartWallThicknessTaskResponse(response) {
  if (response.status === 200) {
    if (response.data.data) {
      if (response.data.data.taskUUID) {
        return response.data.data.taskUUID
      }
    }

    let unexpectedBodyFor200Error = new Error('Response has a 200 status but an unexpected response body')
    unexpectedBodyFor200Error.respBody = response.data
    throw unexpectedBodyFor200Error
  } else if (response.status === 400) { // all application errors have a 400 status
    let unexpectedError = new Error(`Unexpected error response message: ${response.data.message}`)
    unexpectedError.respBody = response.data
    throw unexpectedError
  } else {
    let unexpectedStatusCodeError = new Error(`Unexpected HTTP status code: ${response.status}`)
    unexpectedStatusCodeError.respBody = response.data
    throw unexpectedStatusCodeError
  }
}

function isMoreEfficientToAnalyzeObjFile(part) {
  // Netfabb analysis is more efficient with mesh filetypes than with CAD files because it doesnt
  // have to do a conversion. An OBJ mesh file is exported for each part during param extraction so
  // for all non-stl files, use the OBJ version of the part instead of the original part file for the
  // analysis
  const hasObjFile = part.s3ObjFileIdNew ? part.s3ObjFileIdNew : part.s3ObjFileId
  return Boolean(getFileExtension(part.fileName) !== 'STL' && hasObjFile)
}
