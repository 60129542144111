import React from 'react'
import FORMS from './FORMS.js'
import { ActivitiesForm } from './ActivitiesForm'
import BoxesForm from './BoxesForm'
import { PaperworkForm } from './PaperworkForm'
import SourcingPage from './SourcingPage.js'

export default function ShipmentTracking(props){
  return (
    props.currentForm === FORMS.activities ?
      <ActivitiesForm
        activities={props.activities}
        changeForms={props.changeForms}
        changeToSourcingAttemptForm={props.changeToSourcingAttemptForm}
        currentlySaving={props.currentlySaving}
        MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
        newActivityHandler={props.newActivityHandler}
        onChange={props.onChange}
        onChangeActivityType={props.onChangeActivityType}
        onChangeProjectFieldHandler={props.onChangeProjectFieldHandler}
        onChangePtpc={props.onChangePtpc}
        onDoneButtonClick={props.onDoneButtonClick}
        openBoxesForm={props.openBoxesForm}
        postProcessData={props.postProcessData}
        project={props.project}
        suppliers={props.suppliers}
        SUPPLIER_TYPES={props.SUPPLIER_TYPES}
        updateSupplierPricing={props.updateSupplierPricing}
      /> :
    (props.currentForm === FORMS.boxes ?
     <BoxesForm
       currentlySaving={props.currentlySaving}
       project={props.project}
       saveBoxesToShipment={props.saveBoxesToShipment}
       shipment={props.shipment}
     />
     :
    (props.currentForm === FORMS.sourcing ?
     <SourcingPage
       changeForms={props.changeForms}
       errorMessage={props.errorMessage}
       MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
       onChangeProjectFieldHandler={props.onChangeProjectFieldHandler}
       postProcessData={props.postProcessData}
       project={props.project}
       sourcingMakeOrderId={props.sourcingMakeOrderId}
       sourcingMakeOrderNumber={props.sourcingMakeOrderNumber}
     />
     :
     <PaperworkForm
       activities={props.activities}
       changeForms={props.changeForms}
       errorMessage={props.errorMessage}
       MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
       onDoneButtonClick={props.onDoneButtonClick}
       project={props.project}
       saveActivities={props.saveActivities}
     />
    )
    )
  )
}
