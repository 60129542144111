const axios = require('axios')
const { logUnexpected } = require('../../../utils/logUnexpected.js')

export default async function startThumbnailCreationTask(s3StlFileId, partNumber, fileName){
  let response
  try{
    response = await startThumbnailCreationTaskRequest(s3StlFileId, partNumber, fileName)
  } catch (err){
    logUnexpected(err, { s3StlFileId, partNumber, fileName }, 'startThumbnailCreationTaskRequest')
    throw err
  }

  let result
  try{
    result = parseStartThumbnailCreationTaskResponse(response)
  } catch (err){
    logUnexpected(err, { response }, 'parseStartThumbnailCreationTaskResponse', { s3StlFileId, partNumber, fileName })
    throw err
  }

  return result
}

function startThumbnailCreationTaskRequest(s3StlFileId, partNumber, fileName){
  return axios({
    method: 'post',
    validateStatus: () => true,
    url: '/geom/startThumbnailCreationTask',
    data: {
      s3StlFileId,
      partNumber,
      fileName,
    },
  })
    .then(({ data, status }) => ({ data, status }))
}

function parseStartThumbnailCreationTaskResponse(response) {
  console.log({response})
  if(response.status === 200){
    return response.data.success
  } else {
    const unhandledStatusCodeError = new Error(`Unhandled status code ${response.status}`)
    unhandledStatusCodeError.responseData = response.data
    throw unhandledStatusCodeError
  }
}
