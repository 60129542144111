const LOGISTICS_PEOPLE = [
  {"id":"","name":""},
  {"id":"60c750b88602ea001e1db89a","name":"Alex"},
  {"id":"5c8003d823a95a085daba20d","name":"Andrew Johnson"},
  {"id":"5dfaa20dd7e2db0016026c97","name":"Brad"},
  {"id":"6024034ca450be001bfe9a70","name":"Bridgett"},
  {"id":"5b0c12dc68397c3ab695f732","name":"Chris"},
  {"id":"5b0c12e6867dc224fa06f3f6","name":"Coltin"},
  {"id":"5de5812eda0df200163d7098","name":"Evan"},
  {"id":"63768af67887e700213d09a6","name":"Keegan"},
  {"id":"5f078eacbe9e2100152933d9","name":"Kevin"},
  {"id":"61eded46c92cfaf32718c037","name":"Marcus"},
  {"id":"6365c1fd240e020022fe93d5","name":"Mark"},
  {"id":"64b9763c0afdc30027167c1a","name":"Nick"},
  {"id":"60a57aabc3ada6001ea4d63b","name":"Taylor"}
]

module.exports = LOGISTICS_PEOPLE

