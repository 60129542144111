/*
  PROCESS_TRANSLATIONS is an array of objects that are used to generate select dropdown menus dynamically
  Each object element in the array has a back end "id" value and front end "display" value
*/
const PROCESS_TRANSLATIONS = [
  { "id": "sls", "display": "SLS" },
  { "id": "fdm", "display": "FDM" },
  { "id": "sla", "display": "SLA" },
  { "id": "mjf", "display": "MJF" },
  { "id": "polyJet", "display": "PolyJet" },
  { "id": "dmls", "display": "DMLS" },
  { "id": "cnc", "display": "CNC" },
  { "id": "injMoldingPart", "display": "Injection Molding" },
  { "id": "casting", "display": "Casting" },
  { "id": "sheetMetal", "display": "Sheet Metal" },
  { "id": "eSla", "display": "eSLA", isDeprecated: true },
  { "id": "fff", "display": "FFF", isDeprecated: true },
]

export default PROCESS_TRANSLATIONS

/*
  TranslateProcessId is a method that can be imported to other modules
  Use it to convert a display value for a process to a process id (ex: "SLS" > "sls")
  If no match is found it returns an empty string
*/
export function TranslateProcessId(processId){
  const PROCESS_DISPLAY_VALUE = PROCESS_TRANSLATIONS.find(currentProcess => {
    if(currentProcess.id === processId){
      return currentProcess
    }
    return null
  })
  if(PROCESS_DISPLAY_VALUE && PROCESS_DISPLAY_VALUE.display){
    return PROCESS_DISPLAY_VALUE.display
  }
  else{
    return ""
  }
}

/*
  TranslateProcessDisplay is a method that can be imported to other modules
  Use it to convert an id for a process to a display value (ex: "SLS" > "sls")
  If no match is found it returns an empty string
*/
export function TranslateProcessDisplay(processDisplay){
  const PROCESS_DISPLAY_VALUE = PROCESS_TRANSLATIONS.find(currentProcess => {
    if(currentProcess.display === processDisplay){
      return currentProcess
    }
    return null
  })
  if(PROCESS_DISPLAY_VALUE.id){
    return PROCESS_DISPLAY_VALUE.id
  }
  else{
    return ""
  }
}
