const getDataTableRecord = require( './ActivitiesForm/getDataTableRecord.js')
const INTERMEDIATE_INFO = require( './ActivitiesForm/INTERMEDIATE_INFO.json')
const {TO_FROM_TYPES} = require('./supplierTypeArrays.js')

module.exports = function getShipInfo(shipmentTo, project){
  if(shipmentTo === TO_FROM_TYPES.autotiv){ return autotivShipInfo() }
  if(shipmentTo === TO_FROM_TYPES.customer){ return customerShipInfo(project) }
  if(shipmentTo === TO_FROM_TYPES.intermediate){ return intermediateShipInfo(project) }
  return emptyShipInfo() // 'Held at Supplier' or 'blank'
}

function autotivShipInfo(){
  return {
    shipAddress:{
      street:'142 Main Street',
      street2:'Building B',
      city:'Salem',
      state:'NH',
      zip:'03079',
      country:'USA'
    },
    shipCompany:'Autotiv',
    shipName:'Evan Labelle',

    labelType:'Autotiv Label',
    shipProvider:'FedEx',
    shipAccountNumber:'Autotiv',
    shipAccountNumberZipCode:'', // Should be blank if shipAccountNumber = 'Autotiv'
  }
}

function customerShipInfo(project){
  return{
    shipAddress:project.shipAddress,
    shipCompany:project.shipCompany,
    shipName:project.shipName,

    labelType:project.labelType,
    shipProvider:project.shipProvider,
    shipAccountNumber:project.shipAccountNumber,
    shipAccountNumberZipCode:project.shipAccountZipCode,
  }
}

function emptyShipInfo(){
  return {
    shipAddress:{
      street:'',
      street2:'',
      city:'',
      state:'',
      zip:'',
      country:''
    },
    shipCompany:'',
    shipName:'',

    // These are fine as defaults
    labelType:'Autotiv Label',
    shipProvider:'FedEx',
    shipAccountNumber:'Autotiv',
    shipAccountNumberZipCode:'', // Should be blank if shipAccountNumber = 'Autotiv'
  }

}

function intermediateShipInfo(project){
  const supplierData = getDataTableRecord(INTERMEDIATE_INFO, 'supplierId', project.asParam_supplier)
  return {
    shipAddress:supplierData.shipAddress,
    shipCompany:supplierData.shipCompany,
    shipName:supplierData.shipName,

    labelType:'Autotiv Label',
    shipProvider:'FedEx',
    shipAccountNumber:'Autotiv',
    shipAccountNumberZipCode:'', // Should be blank if shipAccountNumber = 'Autotiv'
  }
}
