import addOrderToShipstation from './addOrderToShipstation.js'
import getOrderFromShipstation from './getOrderFromShipstation.js'

export default async function makeShipLabel(shipment, project){
  const order = {
    orderNumber: shipment.shipmentNumber,
    orderKey: shipment.shipmentNumber,
    orderDate: today(),
    orderStatus: "awaiting_shipment",
    customerEmail: getShippingEmail(project, shipment.shipmentNumber),
    billTo:{
      name: shipment.shipName,
    },
    shippingAmount: 100,
    internalNotes: getInternalNotes(shipment, project),
    requestedShippingService: getShippingMethod(shipment),
    advancedOptions:{
      storeId: getStoreCode(shipment) // 302449
    },
    gift: getWhiteLabel(shipment), // we use their gift option to denote if it's a white label shipment
    shipTo:{
      name: shipment.shipName,
      company: shipment.shipCompany,
      street1: shipment.shipAddress.street,
      street2: shipment.shipAddress.street2,
      city: shipment.shipAddress.city,
      state: shipment.shipAddress.state,
      postalCode: shipment.shipAddress.zip,
      phone: getPhoneNumber(shipment),
    },
  }

  const existingOrdersData = await getOrderFromShipstation(shipment.shipmentNumber)
  const {total} = existingOrdersData
  if(total > 0){
    throw new Error(`Order ${shipment.shipmentNumber} already exists in Shipstation`)
  }
  const newOrder = await addOrderToShipstation(order)
  return newOrder
}

function today() {
  const date = new Date()
  const todayString = `${1 + +date.getMonth()}/${date.getDate()}/${date.getFullYear()}`
  return todayString
}

function getShippingEmail(project, shipmentNumber) {
  let email = "projects@autotiv.com"

  if(project.customer && project.customer.email){
    email = project.customer.email
  }
  if(project.shipEmail){
    email = project.shipEmail
  }

  shipmentNumber = String(shipmentNumber)

  if (shipmentNumber.indexOf('.INT') > -1) {
    email = "projects@autotiv.com"
  }
  
  if (shipmentNumber.indexOf('.ATV') > -1) {
    email = "projects@autotiv.com"
  }
  
  if (shipmentNumber.indexOf('.HOLD') > -1) {
    email = "projects@autotiv.com"
  }
  
  return email
}

function getInternalNotes(shipment, project) {
  if(shipment.labelType === "White Label"){
    const companyName = project.customer.company.companyName
    if(companyName == "No Company in Database"){
      throw new Error("The selected customer can't be white labeled without dev setup")
    }
    return companyName
  }

  return "Autotiv"
}

function getShippingMethod(shipment) {
  const shipMethod = shipment.paidShipMethod ? shipment.paidShipMethod : shipment.actualShipMethod

  if (shipment.shipAccountNumber === "Autotiv") {
    return shipMethod
  }
  return  (
    "Label Type: " + shipment.labelType + "\n" +
      "Method: " + shipMethod + " /\n" +
      shipment.shipProvider + " Account #: " + shipment.shipAccountNumber + " /\n" +
      "Account Zip: " + shipment.shipAccountNumberZipCode
  )
}

function getStoreCode(shipment) {
  const supplier = shipment.supplier

  if(supplier === "Autotiv"){
    return 302449
  }
  if(supplier === "Form 3D"){
    return 323214
  }
  if(supplier === "QuickParts"){
    return 302446
  }
  if(supplier === "SIMT"){
    return 302448
  }
  if(supplier === "Varia 3D"){
    return 339557
  }
  if(supplier === "Laser Imaging"){
    return 367900
  }
  if(supplier === "Incept 3D"){
    return 302453
  }
  if(supplier === "QuickParts Tulsa"){
    return 302447
  }
  if(supplier === "Bills Garage"){
    return 302455
  }
  if(supplier === "3D Printer Experience"){
    return 311277
  }

  return 302449
}

function getWhiteLabel(shipment) {
  if(shipment.labelType === "Autotiv Label"){
    return false
  }
  else{
    return true
  }

}

function getPhoneNumber(shipment) {
  if(shipment.labelType === "Autotiv Label"){
    return "8883695442"
  }
  return ""
}
