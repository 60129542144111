import React,{Fragment,useState} from 'react'
import {MenuItem,Select,Toolbar,Typography,withStyles} from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Link} from '@reach/router'

import LogoOldAndBoring from '../../Images/logo.png'
import LogoDoug from '../../Images/autotiv-logo.svg'
import LogoHex from '../../Images/autotiv-logo-just-the-hex.png'

import frg from '../../feature-release-guards.js';
import USER_TYPES from '../../utils/USER_TYPES.js'

const AppToolBar = ( props ) => {
  const userNameOrPlaceholder = props.user && props.user.name && props.user.name.trim().length ? props.user.name : 'User'
  const [userIsDme, setUserIsDme] = useState(props.user.type === USER_TYPES.DME)
  return (
    <Toolbar>
      <LogoLink linkStyles={props.classes.links} logoStyles={props.classes.logo}/>

      {props.user.type === USER_TYPES.DME || userIsDme ?
       <div className="app-tool-bar">
         <Typography variant="body2">
           User Type:
         </Typography>
         <Select
           style={{ backgroundColor: 'lightGray', color: 'black', paddingLeft: '6px' }}
           onChange={e => props.changeUserType(e.target.value)}
           value={props.user.type}
         >
           <MenuItem value={USER_TYPES.UNKNOWN}>Unknown</MenuItem>
           <MenuItem value={USER_TYPES.DME}>DME</MenuItem>
         </Select>
       </div>
       : null}
      <TextLinks
        divStyle={props.classes.appBarRightGroup}
        linkStyles={props.classes.links}
        loggedIn={true}
        name={userNameOrPlaceholder}
        showHomeLink={true}
        spanStyle={props.classes.appBarRightSubGroup}
      />
    </Toolbar>
  )
}

const LogoLink = ({ linkStyles, logoStyles }) => {
  const isMobile = useMediaQuery('(max-width:800px)')
  let logo
  if ( frg.dougsPrideAndJoy ) {
    logo = LogoDoug 
  } else {
    // MEDIA QUERY
    if (isMobile) {
      logo = LogoHex
    } else {
      logo = LogoOldAndBoring
    }
  }
  return(
    <Link className={linkStyles} to="/">
        <img alt="logo" className={logoStyles} src={logo}/>
    </Link>
  )
}

const TextLinks = ({ divStyle,linkStyles, loggedIn, name, showHomeLink, spanStyle }) => {
  return(
    <div className={divStyle}>
      <span className={spanStyle}>
        <UserNameOrLoginLink
          loggedIn={loggedIn}
          linkStyles={linkStyles}
          name={name}
        />

        {showHomeLink ? <HomeLink linkStyles={linkStyles}/> : null}

        <ContactLink linkStyles={linkStyles}/>
      </span>
    </div>
  )
}

const UserNameOrLoginLink = ({ loggedIn, linkStyles, name }) => {
  if(loggedIn){
    return(
      <Fragment>
        <Link className={linkStyles} to="/account">{name}</Link>
        {"  |  "}
      </Fragment>
    )
  }
  else{
    return(
      <Fragment>
        <Link className={linkStyles} to="../login">Log In</Link>
        {"  |  "}
      </Fragment>
    )
  }
}

const HomeLink = ({ linkStyles }) => {
  return(
    <Fragment>
      <Link className={linkStyles} to="/">Home</Link>
      {"  |  "}
  </Fragment>
  )
}

const ContactLink = ({linkStyles}) => {
  return(
    <a
      className={linkStyles}
      href="https://autotiv.com/contact"
      rel="noopener noreferrer"
      target="_blank"
    >
      Contact
    </a>
  )
}

const STYLES = theme => ({
  appBarRightGroup:{
    marginLeft: 'auto'
  },
  appBarRightSubGroup:{
    marginRight: theme.spacing(2)
  },
  links:{
    color:'white',
    '&:visited':{
      color:'white',
    },
    '&:hover':{
      color:'#dedede',
    },
  },
  logo:{
    height: '40px',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  }
})

export default withStyles(STYLES)(AppToolBar)
