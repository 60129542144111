import React,{Fragment} from 'react'
import { exampleProps } from './ProjectBar.exampleProps.js'
import CustomerProjectBar from './CustomerProjectBar.js'
import DmeProjectBar from './DmeProjectBar.js'
import USER_TYPES from '../../utils/USER_TYPES.js'

function ProjectBar(props){
  const isNotDmeApproved = !isProjectDmeReviewed(props.project)

    return (
      <Fragment>
        <CustomerProjectBar
          cloneProject={props.cloneProject}
          checkoutHandler={props.checkoutHandler}
          currentlyLoading={props.currentlyLoading}
          downloadQuote={props.downloadQuote}
          isCalculatingPrices={props.isCalculatingPrices}
          isNotDmeApproved={isNotDmeApproved}
          leadTimePricePairs={props.leadTimePricePairs}
          onChange={props.onChange}
          pauseCalculations={props.pauseCalculations}
          postProcessData={props.postProcessData}
          project={props.project}
          removeAttachment={props.removeAttachment}
          reviewReasons={props.reviewReasons}
          saveAndNavigateToCheckout={props.saveAndNavigateToCheckout}
          selectedLeadTime={props.selectedLeadTime}
          setPauseCalculations={props.setPauseCalculations}
          setSelectedLeadTime={props.setSelectedLeadTime}
          submitForManualRfq={props.submitForManualRfq}
          submittingForRfq={props.submittingForRfq}
          uploadNewAttachment={props.uploadNewAttachment}
          user={props.user}
        />
        { props.user.type === USER_TYPES.DME ?
          <DmeProjectBar
            deleteInternalFile={props.deleteInternalFile}
            downloadAllProjectFiles={props.downloadAllProjectFiles}
            downloadAllQuotes={props.downloadAllQuotes}
            downloadInternalFiles={props.downloadInternalFiles}
            downloadQuote={props.downloadQuote}
            getCncToolsDataAllParts={props.getCncToolsDataAllParts}
            isCalculatingPrices={props.isCalculatingPrices}
            isMarkupProject={props.isMarkupProject}
            isNotDmeApproved={isNotDmeApproved}
            onChange={props.onChange}
            priceLockProject={props.priceLockProject}
            project={props.project}
            quotePrefix={props.quotePrefix}
            reviewReasons={props.reviewReasons}
            selectedLeadTime={props.selectedLeadTime}
            sendProjectToCustomer={props.sendProjectToCustomer}
            sendQuoteToLogistics={props.sendQuoteToLogistics}
            setIsMarkupProject={props.setIsMarkupProject}
            setQuotePrefix={props.setQuotePrefix}
            setUserAsDme={props.setUserAsDme}
            updateCustomer={props.updateCustomer}
            uploadInternalFile={props.uploadInternalFile}
            user={props.user}
          />
        : null}
      </Fragment>
    )
}

function isProjectDmeReviewed(project){
  const isApproved = obj => obj.isDmeReviewed

  const isProjectApproved = isApproved(project)
  const arePartGroupsApproved = project.partGroups.every(isApproved)
  const arePtpcsApproved = project.partGroups
        .flatMap(ptg => ptg.productionToolPartConfigurations
                 .filter(ptpc => ptpc.processId === ptg.processId))
        .every(isApproved)

  return isProjectApproved && arePartGroupsApproved && arePtpcsApproved
}

ProjectBar.exampleProps = exampleProps

export default ProjectBar
