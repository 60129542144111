import * as THREE from 'three'
import {MTLLoader} from 'three/examples/jsm/loaders/MTLLoader.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'
import {DEFAULT_MATERIAL} from '../../../test/fixtures/ModelData.js'
import {TAB_LABELS} from './optionsConfig.js'
import convertPointsToGroups from './convertPointsToGroups.js'
import { downloadBlobFromS3 } from '../cloudObjectStorage/downloadFileFromS3'


/* Scene configuration macros */
export function reRender(renderer,scene,camera,controls){
  renderer.render(scene,camera)
}

export function sceneSetup(el) {
  const WIDTH = el.clientWidth
  const HEIGHT = el.clientHeight
  const camera = setupCamera(WIDTH, HEIGHT)
  const controls = setupControls(camera, el)
  const renderer = setupRenderer(WIDTH, HEIGHT)

  const scene = smartScene(new THREE.Scene(),[controls,renderer])

  scene.background = new THREE.Color(0xd1d1d1)
  addLightToScene(scene)

  controls.addEventListener("change", () => reRender(renderer,scene,camera,controls))
  reRender(renderer,scene,camera,controls)

  // Add handler for if a user resizes the window
  const cleanUpWindowResizeHandler = setUpWindowResizer(el, camera, renderer, scene, controls)

  const cleanUp = () => {
    cleanUpWindowResizeHandler()

    scene.smartDispose()
  }

  return {camera, controls, renderer, scene, cleanUp }

  // NESTED FUNCTIONS
  function addLightToScene(scene) {
    const ambientLight = new THREE.AmbientLight(0xffffff) // white
    scene.add(ambientLight)
  }

  function setupCamera(WIDTH, HEIGHT) {
    const camera = new THREE.PerspectiveCamera(
      75, // field of view
      WIDTH/HEIGHT, // aspect ratio
      0.1, // near plane
      1000 // far plane
    )
    camera.position.z = 15 // set some distance from a model that is located at z = 0
    return camera
  }

  function setupControls(camera, el){
    const controls = new OrbitControls(camera, el) // OrbitControls allow a camera to orbit around the object
    controls.enableDamping = true  // intertial damping adds inertia to model rotation
    controls.dampingFactor = 0.2   // here we set the inertial damping to a low number, making the effect pleasant but imperceptible
    return controls
  }

  function setupRenderer(WIDTH, HEIGHT) {
    const renderer = new THREE.WebGLRenderer({antialias:true})
    renderer.setSize(WIDTH, HEIGHT)
    return renderer
  }
}

function smartScene(scene, itemsToDisposeOf=[]){
  scene.smartAdd = (object, disposalObjects=[]) => {
    disposalObjects.forEach(obj=>{
      itemsToDisposeOf.push(obj)
    })
    scene.add(object)
  }
  scene.smartDispose = () => {
    itemsToDisposeOf.forEach(item => {
      if(item) {
        item.dispose()
      }
    })
  }
  return scene
}

  /*
    addGridHelpersAndTextSpriteToScene creates a grid to help give scale to the model

    There are three visual elements being created here:
    1. grid
    2. subgrid
    3. textsprite

    And the only reason we do both the grids & textsprite in the same context
    is because they both need xdim & zdim (for different reasons, but still).

    We want the grid-lines and textsprite to play nicely together, so we
    configure them both in one function context.
  */
export function addGridHelpersAndTextSpriteToScene(centeredModel, scene, units) {
  // grab all the stuff we need from centeredModel
  const modelBB = centeredModel.children[0].geometry.boundingBox
  const modelYPosition = centeredModel.children[0].position.y
  const modelBBMinY = modelBB.min.y

  // get bounding box x dimension
  const xMax = modelBB.max.x
  const xMin = modelBB.min.x
  const xDim = xMax - xMin

  // get bounding box z dimension
  const zMax = modelBB.max.z
  const zMin = modelBB.min.z
  const zDim = zMax - zMin

  // create the visual elements
  const textSprite = createTextSprite(units, modelYPosition, xDim, zDim)
  const { gridHelper, gridHelperSubDivided } = createGridHelpers(modelBBMinY, xDim, zDim)

  // add them to the scene
  scene.smartAdd(textSprite.sprite,[textSprite.sprite.geometry,textSprite.sprite.material,textSprite.sprite.material.map])
  scene.smartAdd(gridHelper,[gridHelper.geometry,gridHelper.material])
  scene.smartAdd(gridHelperSubDivided,[gridHelperSubDivided.geometry,gridHelperSubDivided.material])

  // return the element references so they can be disposed
  return {gridHelper,gridHelperSubDivided,textSprite}

  // NESTED FUNCTIONS

  function createGridHelpers(modelBBMinY, xDim, zDim) {
    // Derive common data
    const largestDimension = xDim >= zDim ? xDim : zDim  // determine if the x or z axis is larger and base grid off that (so model doesn't fall off grid)
    const size = calculateGridSize(largestDimension)  // round grid up to the nearest tenth, then add ten more units to make grid slightly larger than model
    const divisions = size / 10 // calculate number of divisions in grid, since size is some multiple of ten, each division will = 10 units (mm or inches)

    // Create the Grid Helper
    const color = new THREE.Color(0xb5b5b5) // grid line color
    const gridHelper = new THREE.GridHelper(size,divisions,color,color) // create grid geometry
    gridHelper.position.y = modelBBMinY - 5 // set grid slightly below model to create some separation

    // Create the Subgrid
    const color2 = new THREE.Color(0x919191) // grid line color
    const gridHelperSubDivided = new THREE.GridHelper(size,(divisions/2),color2,color2) // create grid geometry
    gridHelperSubDivided.position.y = modelBBMinY - 5 // set grid slightly below model to create some separation

    return { gridHelper, gridHelperSubDivided }

    /*
      calculateGridSize takes a dimension (x,y,z) and calculates a size that is
      a multiple of ten that is slightly larger than the model.
    */
    function calculateGridSize(modelDimension) {
        const slightlyLargerThanModel = Math.ceil((modelDimension + 1) / 10) * 10 + 10
        return slightlyLargerThanModel
    }

  }

  /*
    createTextSprite creates a text label to act as a ruler
  */
  function createTextSprite(units, modelYPosition, xDim, zDim) {
      const shortUnits = units === 'inches' ? 'in' : 'mm'
      const message = `10${shortUnits} / grid unit`
      const fontFace = 'Helvetica'
      const fontSize = 40
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = fontSize + "px " + fontFace
      // text color
      context.fillStyle = 'rgba(133, 133, 133, 100)'
      context.fillText(message, 0, fontSize)
      // canvas contents will be used for a texture
      var texture = new THREE.Texture(canvas)
      texture.minFilter = THREE.LinearFilter
      texture.needsUpdate = true
      var spriteMaterial = new THREE.SpriteMaterial({ map: texture })
      var sprite = new THREE.Sprite(spriteMaterial)
      sprite.scale.set(20, 10, 1.0)
      sprite.position.y = modelYPosition - 7 // set label slightly below model to create some separation
      sprite.position.x = xDim/2 + 20 // set label slightly beside model
      sprite.position.z = zDim/2 // set label slightly beside model

      return { sprite, spriteMaterial, texture }
  }


}

export async function addMeshToScene(plainGlbArrayBuffer, glbArrayBuffer, scene, camera, controls) {
  let mesh
  if(glbArrayBuffer){
     mesh = await loadGltfMesh(glbArrayBuffer)
  } else{
     mesh = await loadGltfMesh(plainGlbArrayBuffer)
    console.log({mesh})
  }
    const centeredModel = centerBoundingBox(mesh) // Find center of model bounding box, makes rotating/inspecting part easier
    const wireFrameObject = addWireFrameToScene(centeredModel, scene)

    let modelComponentsDisposeOf = [centeredModel.children[0].geometry]
    const matls = centeredModel.children[0].material
    if(Array.isArray(matls)){
      modelComponentsDisposeOf = modelComponentsDisposeOf.concat(matls)
    } else if(matls){
      modelComponentsDisposeOf.push(matls)
    }
    scene.smartAdd(centeredModel, modelComponentsDisposeOf)

    return {centeredModel,wireFrameObject}

    // NESTED FUNCTIONS

  /*
    centerBoundingBox computes the bounding box of a models geometry and uses that to center the model
    This allows us to rotate the model on an axis that is centered within the model,
    allowing users to easily inspect it
  */
  function centerBoundingBox(mesh) {
      mesh.geometry.computeBoundingBox()
      // center the mesh
      mesh.geometry.boundingBox.getCenter(mesh.position).multiplyScalar(-1)

      // the mesh bounding box is not automatically updated when the mesh is moved to the center position
      // so update the bounding box to the meshes new position
      mesh.updateMatrixWorld(true)
      mesh.geometry.boundingBox.applyMatrix4(mesh.matrixWorld)

      const parent = new THREE.Object3D()
      parent.add(mesh)
      parent.position.x = 0
      parent.position.y = 0
      return parent
  }

  function addWireFrameToScene(centeredModel, scene) {
    const wireGeometry = new THREE.EdgesGeometry( centeredModel.children[0].geometry )
    const wireMaterial = new THREE.LineBasicMaterial( { color: 0x000000 } )
    const wireframe = new THREE.LineSegments( wireGeometry, wireMaterial )
    wireframe.position.x = centeredModel.children[0].position.x
    wireframe.position.y = centeredModel.children[0].position.y
    wireframe.position.z = centeredModel.children[0].position.z
    scene.smartAdd(wireframe, [wireframe.geometry, wireframe.material])
    return wireframe
  }

}

async function loadGltfMesh(glbArrayBuffer){
  const gltfTools = new GLTFLoader()

  return new Promise (function (resolve, reject) {
    gltfTools.parse(
      glbArrayBuffer,
      null, //path to other files such as textures
      success => {
        const mesh = success.scene.children[0]

        const targetMaterial = new THREE.MeshBasicMaterial()
        THREE.MeshBasicMaterial.prototype.copy.call( targetMaterial, mesh.material )
        mesh.material = targetMaterial

        resolve(mesh)
      },
      err => {
        reject(err)
      }
    )
  })
}

async function loadObjMesh(objFile){
  const loader = new OBJLoader()

  // return new Promise (function (resolve, reject) {
    return loader.parse(
      objFile,
    ).children[0] /*
      null, //path to other files such as textures
      success => {
        const mesh = success.scene.children[0]

        const targetMaterial = new THREE.MeshBasicMaterial()
        THREE.MeshBasicMaterial.prototype.copy.call( targetMaterial, mesh.material )
        mesh.material = targetMaterial

        resolve(mesh)
      },
      err => {
        reject(err)
      }
    )
  })
*/
}

/* Macros for adding a new model/material */

/*
  adjustCameraForModelSize does some basic trigonometry to determine the required distance of camera from model
  It moves the camera this far back, then adjusts the orbital camera controls for the new model size
*/
export function adjustCameraForModelSize(camera, controls, centeredModel) {
  const BOX = new THREE.Box3().setFromObject(centeredModel) // compute the box that contains model
  const BOX_SIZE = BOX.getSize(new THREE.Vector3()).length()

  const sizeToFitOnScreen = BOX_SIZE * 1.2
  const boxSize = BOX_SIZE
  const boxCenter = BOX.getCenter(new THREE.Vector3())

  // Determine required distance of camera in order to fit model inside fov
  const HALF_SIZE_TO_FIT_ONSCREEN = sizeToFitOnScreen * 0.5
  const HALF_OF_FOV_THETA = THREE.MathUtils.degToRad(camera.fov * .5)
  const DISTANCE = HALF_SIZE_TO_FIT_ONSCREEN / Math.tan(HALF_OF_FOV_THETA)
  // compute a unit vector that points in the direction the camera is now from the center of the box
  const direction = (new THREE.Vector3()).subVectors(camera.position, boxCenter).normalize()
  // move the camera to a position distance units way from the center
  // in whatever direction the camera was from the center already
  camera.position.copy(direction.multiplyScalar(DISTANCE).add(boxCenter))
  // pick some near and far values for the frustum that will contain the box
  camera.near = boxSize / 100
  camera.far = boxSize * 100
  camera.updateProjectionMatrix()
  camera.lookAt(boxCenter.x,boxCenter.y,boxCenter.z) // point the camera to look at the center of the box
  // update the Trackball controls to handle the new model size
  controls.maxDistance = boxSize * 4
  controls.minDistance = boxSize / 4
  controls.target.copy(boxCenter)
  controls.update()
}

/* Macros for materials which have to do with new analyses (e.g. wallthickness) */

export function getDefaultMaterial(){
  return new THREE.MeshStandardMaterial({ color: 0x808080 });
}

function setUpWindowResizer(el, camera, renderer, scene, controls){
  window.addEventListener("resize",handleWindowResize)
  window.addEventListener("resize",() => reRender(renderer,scene,camera,controls))
  return cleanUpWindowResizeHandler

  /*
    handleWindowResize is triggered by an EventListener monitoring when users change the window size
    It updates the canvas to the new dimensions of the container element
  */
  function handleWindowResize(){
    const WIDTH = el.clientWidth
    const HEIGHT = el.clientHeight
    renderer.setSize(WIDTH,HEIGHT)
    camera.aspect = WIDTH/HEIGHT
    // Note that after making changes to most of camera properties you have to call
    // .updateProjectionMatrix for the changes to take effect.
    camera.updateProjectionMatrix()
  }

  function cleanUpWindowResizeHandler(){
    window.removeEventListener("resize", handleWindowResize) // end resize listener
  }
}

/* Macros for materials which have to do opacity (wireframe/solid/xray) etc. */

export function updateMaterialsBasedOnViewingMode(materialsArrayOrMaterial,viewingModeIndex){
  const isArray = Array.isArray(materialsArrayOrMaterial)
  const materialsArray =  isArray ? materialsArrayOrMaterial : [materialsArrayOrMaterial]

  const tabLabelData = Object.values(TAB_LABELS).find(({tabIndex}) => tabIndex === viewingModeIndex)

  if(!tabLabelData){
    throw Error(`viewingModeIndex ${viewingModeIndex} not found in TAB_LABELS`)
  }

  materialsArray.forEach(material=>{
    Object.keys(tabLabelData.config).forEach(key=>{
      material[key] = tabLabelData.config[key]
    })
  })
}

export function addArrowsToScene(centeredModel, scene, originalCameraPosition){
  return convertPointsToGroups(centeredModel)
    .flatMap(triangles => {
      const triangleObject3d = createObj3dFromTriangles(triangles,centeredModel.children[0].position)
      const { arrow, sphere } = createArrowPointingToObject(triangleObject3d, originalCameraPosition)

      scene.smartAdd(triangleObject3d, [ triangleObject3d.children[0].geometry,triangleObject3d.children[0].material ])
      scene.smartAdd(arrow, [ arrow.line.geometry, arrow.line.material, arrow.cone.geometry, arrow.cone.material ])
      scene.smartAdd(sphere, [ sphere.geometry, sphere.material ])
      return [ arrow, sphere, triangleObject3d ]
    })
}

function createObj3dFromTriangles(triangles, position){
  const POINTS_PER_VERTEX = 3

  const vertices = triangles.flat().filter(Boolean)
  const positions = vertices.flatMap(x => Array.from(x)) // Datatype of element is float32Array which doesn't automatically behave as an array when called by .flat or .flatMap

  const verticesFloat = new Float32Array(positions)
  const geom = new THREE.BufferGeometry()
  geom.setAttribute('position', new THREE.BufferAttribute(verticesFloat, POINTS_PER_VERTEX))
  geom.computeVertexNormals()

  const RED = '#FF0000'
  const newMesh = new THREE.Mesh(geom, new THREE.MeshPhongMaterial({color: RED, side: THREE.DoubleSide}))

  const newObject3d = (new THREE.Object3D()).add(newMesh)
  newObject3d.position.set(position.x, position.y, position.z)

  return newObject3d
}

function createArrowPointingToObject(object, fromPoint){
  const COLOR = 'yellow'

  const objectCenter = (new THREE.Box3().setFromObject(object)).getCenter(new THREE.Vector3())

  const arrowLength = new THREE.Vector3().subVectors(fromPoint,objectCenter).length() / 5

  const arrowDirection = new THREE.Vector3().subVectors(fromPoint, objectCenter).normalize().multiplyScalar(arrowLength)

  const arrowFrom = new THREE.Vector3().add(objectCenter).add(arrowDirection)

  return {
    sphere: aSphere(arrowFrom, COLOR),
    arrow: anArrow(arrowFrom, objectCenter, COLOR)
  }
}

function anArrow(pointFromHere, pointToHere, color){
  const arrowDirection = new THREE.Vector3().subVectors(pointToHere, pointFromHere)

  // must get length before normalizing the vector (setting the length to one)
  const length = arrowDirection.length()

  // normalized direction vector, origin (non-arrow end), length, color
  return new THREE.ArrowHelper(arrowDirection.normalize(), pointFromHere, length, color)
}

function aSphere(location,color){
  const SPHERE_RADIUS = 0.35

  const geometry = new THREE.SphereGeometry(SPHERE_RADIUS);
  const material = new THREE.MeshBasicMaterial( { color } );
  const sphere = new THREE.Mesh( geometry, material );
  sphere.position.copy(location)
  return sphere
}

/**
 * Wraps a function in {@link window.requestAnimationFrame}
 * @param {function} original
 * @returns {function} proxy which will call the original at most once per frame
 */
export function frameThrottle(original) {
  let pending = false

  function wrap() {
    pending = false
    original()
  }

  function proxy() {
    if (!pending) {
      pending = true
      requestAnimationFrame(wrap)
    }
  }

  return proxy
}

