import React, { useContext, useEffect, useState } from "react"
import {
  Accordion,
  AccordionSummary,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useNonMountEffect } from "../../hooks"
import {appendInfillIfNeeded} from '../../shared/appendInfillIfNeeded.js'
import AtRiskPromptAtom from './AtRiskPromptAtom.js'
import {COLORS} from '../../shared/COLORS.js'
import CustomerPartConfiguration from './CustomerPartConfiguration.js'
import {determinePartRowTypeToReturn} from './determinePartRowTypeToReturn.js'
import DmeGeneralOverrides from "./DmeGeneralOverrides.js"
import DmeMarkupQuote from "./DmeMarkupQuote.js"
import DmeCnc from "./DmeCnc.js"
import DmeInjectionMolding from "./DmeInjectionMolding.js"
import DmeSheetMetal from './DmeSheetMetal.js'
import {formatAttachmentsList} from '../../utils/formatAttachmentsList'
import getManufacturabilityIssues from '../../shared/getManufacturabilityIssues.js'
import {getTolerancesLabel} from '../../shared/getTolerancesLabel.js'
import limitToTwoDecimals from './limitToTwoDecimals.js'
import ManualRfqTextWithTooltip from './ManualRfqTextWithTooltip.js'
import {needsManufacturabilityIssuesApproved} from '../../utils/needsManufacturabilityIssuesApproved.js'
import { NumberField } from "./NumberField.js"
import ProductionTool from "./ProductionTool.js"
import {DeleteButtonAtom,StatusFeedbackAtom} from "./partCardAtoms"
import USER_TYPES from '../../utils/USER_TYPES.js'
import { formatPrice, TranslateProcessId } from '../../utils'
import {formatFileDimensions,formatFileDimensionsForToolTip} from '../../utils/formatFileDimensions'
import {postProcessesAsString} from '../../shared/postProcessesAsString.js'
import scrollIntoView from 'scroll-into-view-if-needed'
const {TranslateMaterialId} = require('../Common/TranslationTableMaterial.js')

const ROW_TYPES = {
  uploadingPart: "uploading_part",
  noUnits: "no_units",
  atRisk: "at_risk",
  analysisComplete: "analysis_complete",
  wallThickness: "waiting_for_wall_thickness_analysis",
  paramExtract: "parameter_extract_in_progress",
}

function PartCard(props){
  const [isLoadingNewPtpc, setIsLoadingNewPtpc] = useState(false)
  useEffect(() => {
    // add default ProductionToolPartConfiguration if needed
    if(isProcessUsingProductionTool(props.partGroup.processId)){
      const ptpcs = props.partGroup.productionToolPartConfigurations
            .filter( productionToolPartConfiguration => productionToolPartConfiguration.processId === props.partGroup.processId)

      if(ptpcs.length === 0){
        addPartConfiguration()
      }
    }
  }, [props.partGroup.processId])

  useNonMountEffect(() => {
    if(props.partGroup.processId === 'cnc'){
      props.updateCncStockValues(props.partGroup.partGroupNumber)
    }
  }, [
    props.partGroup.part.units,
    props.partGroup.part.cncMillOrTurn,
    props.partGroup.part.xDimUnits, props.partGroup.part.yDimUnits, props.partGroup.part.zDimUnits,
  ])

  useEffect(() => {
    const isPartCnc = props.partGroup.processId === 'cnc'
    const doesCncStockHaveAnyValues = Boolean(
      Number(props.partGroup.part.cncStockX)
        || Number(props.partGroup.part.cncStockY)
        || Number(props.partGroup.part.cncStockZ)
        || Number(props.partGroup.part.cncStockOuterDiameter)
    )

    if(isPartCnc && !doesCncStockHaveAnyValues){
      props.updateCncStockValues(props.partGroup.partGroupNumber)
    }
  }, [props.partGroup.materialId])

  function addPartConfiguration(){
    setIsLoadingNewPtpc(true)
    props.addPartConfigurationToPartGroup(props.partGroup)
      .then(()=>{
        setIsLoadingNewPtpc(false)
      })
  }

  if(props.isLeadTimeLocked){
    return (
      <div className="collapsedPartCardAccordion" style={{ padding: '1em' }}>
      <CollapsedPartCard
        {...props}
      />
      </div>
    )
  }
  if(props.partGroupNumberToEdit === props.partGroup.partGroupNumber){
    return (
      <EditingPartCard
        {...props}
        isLoadingNewPtpc={isLoadingNewPtpc}
        setIsLoadingNewPtpc={setIsLoadingNewPtpc}
      />
    )
  } else {
    return (
      <Accordion className="collapsedPartCardAccordion" TransitionProps={{ unmountOnExit: true }} expanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          onClick={e => props.setPartGroupNumberToEdit(props.partGroup.partGroupNumber)}
        >
          <CollapsedPartCard
            {...props}
          />
        </AccordionSummary>
      </Accordion>
    )
  }
}

function CollapsedPartCard(props) {
  const partGroupPriceMaybe = props.pricingData.partGroupPrices && props.pricingData.partGroupPrices.find(ptg => ptg.partGroupNumber === props.partGroup.partGroupNumber)
  let unitPrice = partGroupPriceMaybe ? partGroupPriceMaybe.unitPrice : 0 // to accomodate for parts that are new and dont have prices yet
  if(props.isLeadTimeLocked){
    unitPrice = props.partGroup.lockedUnitPrice
  }

  const isPartAtRisk = needsManufacturabilityIssuesApproved(props.status)
  const rowType = determinePartRowTypeToReturn(isPartAtRisk, props.status, props.partGroup.part)

  const materialFilteredPtpcs = props.partGroup.productionToolPartConfigurations
        .filter(ptpc => ptpc.processId === props.partGroup.processId)

  const usesATool = props.partGroup.processId === "injMoldingPart" || props.partGroup.processId === "casting"
  const materialId = usesATool ? props.partGroup.materialIdProductionToolIsDesignedFor : props.partGroup.materialId
  let material = TranslateMaterialId(props.partGroup.processId, materialId, props.MATERIAL_TRANSLATIONS)
  material = appendInfillIfNeeded(material, props.partGroup.processId, props.partGroup.infillPercentage)

  return (
    <div className="collapsedPartCard">
      <div className="collapsedPartCardGrid">
        <div style={{gridArea: 'sttus', display: 'flex', justifyContent: 'end'}}>
          <StatusFeedbackAtom
            manufacturabilityIssues={ getManufacturabilityIssues(props.partGroup, props.postProcessData.POST_PROCESS_OPTIONS) }
            onChange={e => props.onChange(e, props.partGroup.partGroupNumber)}
            partGroup={props.partGroup}
            riskAccepted={props.partGroup.part.riskAccepted}
            rowType={rowType}
            showWallThicknessCheckbox={false}
            user={props.user}
          />
        </div>
        <div style={{gridArea: 'fname', alignSelf: 'center', fontWeight: 'bold'}}>
          {props.partGroup.part.fileName}
        </div>
        <div style={{gridArea: 'psmtl', alignSelf: 'center'}}>
          {TranslateProcessId(props.partGroup.processId)}, {material}
        </div>
        <div style={{gridArea: 'qunty', alignSelf: 'center'}} className="collapsedPartCardQuantityAtom">
          Qty: {props.partGroup.quantity}
        </div>
        <div style={{gridArea: 'unitp', alignSelf: 'center'}} className="collapsedPartCardUnitPriceAtom">
          { ( rowType !== ROW_TYPES.paramExtract && rowType !== ROW_TYPES.atRisk ) &&
            (
              props.isCalculatingPrices ? null :
                (props.partReasons.length > 0 ? null : (
                  props.isLeadTimeLocked ? <LockedUnitPriceInput lockedUnitPrice={props.partGroup.lockedUnitPrice} onChange={e => props.onChange(e, props.partGroup.partGroupNumber)} />
                  : `${formatPrice(unitPrice)} ea.`
                ))
            )
          }
        </div>
        <div style={{gridArea: 'sbttl', alignSelf: 'center'}} className="collapsedPartCardSubtotalAtom">
          { rowType === ROW_TYPES.atRisk &&
            <AtRiskPromptAtom
              isCondensed
              manufacturabilityIssues={getManufacturabilityIssues(props.partGroup, props.postProcessData.POST_PROCESS_OPTIONS)}
              onChange={props.onChange}
              part={props.partGroup}
            />
          }
          { ( rowType !== ROW_TYPES.paramExtract && rowType !== ROW_TYPES.atRisk ) &&
            (
              props.isCalculatingPrices ? <CircularProgress size="0.9em"/> :
                (props.partReasons.length > 0 ?
                 <ManualRfqTextWithTooltip
                   isCondensed
                   partGroup={props.partGroup}
                 />
                 : <span style={{ fontWeight: 'bold'}}> {formatPrice(props.partGroup.quantity * unitPrice)} </span>)
            )
          }
        </div>
        <div style={{gridArea: 'trash', alignSelf: 'center', textAlign: 'right'}}>
          {props.isLeadTimeLocked ? null :
          <DeleteButtonAtom
            deleteHandler={(e)=>{
              e.stopPropagation() // Don't trigger the onClick in parent that sets the expanded row to this (now deleted) row
              props.deletePartGroup(props.partGroup.partGroupNumber)
            }}
            tooltipTitle="Delete this part"
          />
          }
        </div>
      </div>

      {props.partGroup.postProcesses.length ?
       <div className="collapsedPartCardRow">
         Post Processes: {postProcessesAsString(props.partGroup.postProcesses, props.postProcessData.POST_PROCESS_OPTIONS)}
       </div>
       : null}

      {props.partGroup.tolerances.length ? 
       <div className="collapsedPartCardRow">
         Tolerances: {getTolerancesLabel(props.partGroup.tolerances, props.toleranceClassifications).join(' ')}
       </div>
       : null}

      {props.partGroup.attachments.length ? 
       <div className="collapsedPartCardRow">
         {formatAttachmentsList(props.partGroup.attachments)}
       </div>
       : null}

      {props.partGroup.part.partNotes.length ? 
       <div className="collapsedPartCardRow">
         Notes: {props.partGroup.part.partNotes}
       </div>
       : null}

      {materialFilteredPtpcs.length ?
       <>
         <div className="collapsedPartCardPtpcHeader">
           Part Configurations:
         </div>
         {materialFilteredPtpcs
          .map(ptpc => {
            const ptpcPriceMaybe = props.pricingData.ptpcPrices && props.pricingData.ptpcPrices
                  .find(ptpcPrice => ptpcPrice.productionToolPartConfigurationId === ptpc.productionToolPartConfigurationId)
            let ptpcUnitPrice = ptpcPriceMaybe ? ptpcPriceMaybe.unitPrice : 0 // to accomodate for parts that are new and dont have prices yet
            if(props.isLeadTimeLocked){
              ptpcUnitPrice = ptpc.lockedUnitPrice
            }

            return (
              <div className="collapsedPtpc" key={`collapsedPtpc-${ptpc.productionToolPartConfigurationId}`}>
                <div className="collapsedPtpcGrid">
                  <div style={{gridArea: 'mtlcl'}} className="collapsedPtpcMaterialAtom">
                    {`${TranslateMaterialId(ptpc.processId, ptpc.materialId, props.MATERIAL_TRANSLATIONS)}, ${getColorDisplayFromColor(ptpc.color)}`}
                  </div>
                  <div style={{gridArea: 'qunty'}} className="collapsedPartCardQuantityAtom">
                    Qty: {ptpc.quantity}
                  </div>
                  <div style={{gridArea: 'unitp'}} className="collapsedPartCardUnitPriceAtom">
                    {
                      props.isCalculatingPrices ? null :
                        (props.partReasons.length > 0 ? null : (
                          props.isLeadTimeLocked ?
                            <LockedUnitPriceInput
                              lockedUnitPrice={ptpc.lockedUnitPrice}
                              onChange={e => props.updateProductionToolPartConfiguration(e.target.name, e.target.value, ptpc.productionToolPartConfigurationId, props.partGroup.partGroupNumber)}
                            />
                          :
                          `${formatPrice(ptpcUnitPrice)} ea.`
                        ))
                    }
                  </div>
                  <div style={{gridArea: 'sbttl'}} className="collapsedPartCardSubtotalAtom">
                    {
                      props.isCalculatingPrices ? <CircularProgress size="0.9em"/> :
                        (props.partReasons.length > 0 ?
                         <ManualRfqTextWithTooltip
                           isCondensed
                           partGroup={props.partGroup}
                         />
                         : formatPrice(ptpc.quantity * ptpcUnitPrice))
                    }
                  </div>
                </div>

                {ptpc.postProcesses.length ?
                 <div className="collapsedPtpcAtom">
                   Post Processes: {postProcessesAsString(ptpc.postProcesses, props.postProcessData.POST_PROCESS_OPTIONS)}
                 </div>
                 : null}

                {ptpc.attachments.length ?
                 <div className="collapsedPtpcAtom">
                   Attachments: {ptpc.attachments.map(a => a.fileName).join()}
                 </div>
                 : null}

                {ptpc.notes.length ?
                 <div className="collapsedPtpcAtom">
                   Notes: {ptpc.notes}
                 </div>
                 : null}
              </div>
            )
          })
         }
       </>
       : null}
    </div>
  )
}

function getColorDisplayFromColor(color){
  const colorMaybe = COLORS
        .find(colorObj => colorObj.value === color)

  return colorMaybe ? colorMaybe.label : ''
}

function EditingPartCard(props) {
  const id = 'EditingPartCardId'

  useEffect(() => {
    const node = document.getElementById(id)

    if(node){
      scrollIntoView(node, {
        scrollMode: 'always',
        block: 'start',
        behavior: 'smooth',
      })
    }
  }, ['ONMOUNT']) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const isPartInjectionMolding = props.partGroup.processId === 'injMoldingPart'
    const hasExistingTolerances = props.partGroup.tolerances.length

    if(isPartInjectionMolding && hasExistingTolerances){
      props.removeAllTolerances(props.partGroup.partGroupNumber)
    }

  }, [props.partGroup.processId])

  return(
    <>
      {isProcessUsingProductionTool(props.partGroup.processId) ?
       <div id={id}>
         <ProductionTool
           addPartConfigurationToPartGroup={props.addPartConfigurationToPartGroup}
           crud={props.crud}
           currentlyLoading={props.currentlyLoading}
           deletePartGroup={props.deletePartGroup}
           deleteProductionToolPartConfiguration={props.deleteProductionToolPartConfiguration}
           isCalculatingPrices={props.isCalculatingPrices}
           isLoadingNewPtpc={props.isLoadingNewPtpc}
           MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
           onChange={props.onChange}
           openPartModal={props.openPartModal}
           partGroup={props.partGroup}
           partGroupPrices={props.pricingData.partGroupPrices}
           partReasons={props.partReasons}
           postProcessData={props.postProcessData}
           projectNumber={props.projectNumber}
           ptpcPrices={props.pricingData.ptpcPrices}
           removeAttachment={props.removeAttachment}
           replacePartFile={props.replacePartFile}
           setIsLoadingNewPtpc={props.setIsLoadingNewPtpc}
           setPartGroupNumberToEdit={props.setPartGroupNumberToEdit}
           status={props.status}
           updateProductionToolPartConfiguration={props.updateProductionToolPartConfiguration}
           uploadNewAttachment={props.uploadNewAttachment}
           user={props.user}
         />
       </div>
       :
       <div id={id}>
         <CustomerPartConfiguration
           crud={props.crud}
           currentlyLoading={props.currentlyLoading}
           deletePartGroup={props.deletePartGroup}
           isCalculatingPrices={props.isCalculatingPrices}
           MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
           onChange={props.onChange}
           openPartModal={props.openPartModal}
           partGroup={props.partGroup}
           partGroupPrices={props.pricingData.partGroupPrices}
           partReasons={props.partReasons}
           postProcessData={props.postProcessData}
           removeAttachment={props.removeAttachment}
           replacePartFile={props.replacePartFile}
           setPartGroupNumberToEdit={props.setPartGroupNumberToEdit}
           status={props.status}
           toleranceClassifications={props.toleranceClassifications}
           uploadNewAttachment={props.uploadNewAttachment}
           user={props.user}
         />
       </div>
      }

      {areDmeSectionsRendered() ?
        <DmeGeneralOverrides
          onChange={props.onChange}
          partGroup={props.partGroup}
        /> :
        null
      }

      {areDmeSectionsRendered() && props.partGroup.processId === "cnc" ?
        <DmeCnc
          addTool={props.addTool}
          deleteTool={props.deleteTool}
          getCncToolsData={props.getCncToolsData}
          onChange={props.onChange}
          onChangeToolField={props.onChangeToolField}
          partGroup={props.partGroup}
        /> :
        null
      }

      {areDmeSectionsRendered() && props.partGroup.processId === "sheetMetal" ?
        <DmeSheetMetal
          onChange={props.onChange}
          partGroup={props.partGroup}
        /> :
        null
      }

      {areDmeSectionsRendered() && [ "injMoldingPart", "casting" ].includes(props.partGroup.processId) ?
        <DmeInjectionMolding
          crud={props.crud}
          addTool={props.addTool}
          deleteTool={props.deleteTool}
          isCalculatingPrices={props.isCalculatingPrices}
          MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
          onChange={props.onChange}
          onChangeToolField={props.onChangeToolField}
          partGroup={props.partGroup}
          partGroupPrices={props.pricingData.partGroupPrices}
          postProcessData={props.postProcessData}
          TOOL_MATERIALS={props.TOOL_MATERIALS}
        /> :
        null
      }

      {areDmeSectionsRendered() && props.isMarkupProject ?
        <DmeMarkupQuote
          onChange={props.onChange}
          partGroup={props.partGroup}
        /> :
        null
      }
    </>
  )

  function areDmeSectionsRendered(){
    const isUserDme = props.devBackdoors.user.type ?
    (props.devBackdoors.user.type === USER_TYPES.DME) :
    (props.user.type === USER_TYPES.DME)
    const isAnalysisComplete = (props.status.PARAM_EXTRACT_FINISHED && props.status.WALL_THICKNESS_TASK_FINISHED)
    return isUserDme && isAnalysisComplete
  }
}

function LockedUnitPriceInput({lockedUnitPrice, onChange}){
  const onChangeUnitPrice = e => {
    const newUnitPrice = limitToTwoDecimals(e.target.value)
    if(newUnitPrice !== lockedUnitPrice){
      // Because we limit decimals to 2 for a price, it's possible for a change request to
      //   be triggered even if the value isn't actually changed.
      // Therefore we only want to trigger a state update if the value is actually changing
      onChange(e)
    }
  }

  return(
    <NumberField
      label="Locked Unit Price"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      name="lockedUnitPrice"
      onChange={onChangeUnitPrice}
      size="small"
      variant="outlined"
      value={lockedUnitPrice}
    />
  )
}

function isProcessUsingProductionTool(processId){
  return ['casting','injMoldingPart'].includes(processId)
}

export default PartCard

// SHOWCASE STUFF
const _ = require('lodash')
PartCard.exampleProps = _.cloneDeep(CustomerPartConfiguration.exampleProps)
PartCard.exampleProps.status.WALL_THICKNESS_TASK_FINISHED = true
PartCard.exampleProps.partGroup.processId = "injMoldingPart"
PartCard.exampleProps.partGroup.part.cnc5AxisMillComplexity = "Medium"
PartCard.exampleProps.addPartConfigurationToPartGroup = async ()=>{console.log("adding part config...")}
PartCard.exampleProps.partGroup.productionToolPartConfigurations = []
PartCard.exampleProps.partGroup.overmoldedInserts = [
  {
    name:'part #4534',
    qtyInsertsPerPart:2,
    qtyInsertsPerPack:100,
    costPerPack:600,
    volume:26,
    surfaceArea:12,
    xDimension:3,
    yDimension:12,
    zDimension:1
  },
  {
    name:'part blah',
    qtyInsertsPerPart:2,
    qtyInsertsPerPack:100,
    costPerPack:600,
    volume:26,
    surfaceArea:12,
    xDimension:3,
    yDimension:12,
    zDimension:1
  },
]
PartCard.exampleProps.user =
  {
    "aud": "2419hen1jem1oit2m7g46gj7e1",
    "auth_time": 1631204082,
    "cognito:username": "11162ead-fd7f-4b35-8a32-800b371872f4",
    "custom:firstName": "zack",
    "custom:lastName": "lyons",
    "email": "zack@autotiv.com",
    "email_verified": true,
    "event_id": "2bc3ea45-3beb-4c08-a175-d1063b133a87",
    "exp": 1632514675,
    "iat": 1632511075,
    "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_15fViVABs",
    "name": "zack lyons",
    "sub": "11162ead-fd7f-4b35-8a32-800b371872f4",
    "token_use": "id"
  }
PartCard.Showcase = Showcase
function Showcase() {
  // EXPERIMENTAL; this is the wiring for a dynamic props-editor if we were to
  // ever make it.
  const [exampleProps, setExampleProps] = useState({ ...PartCard.exampleProps })
  return(
    <>
      <PartCard { ...exampleProps } devBackdoors={{ user: {type:USER_TYPES.DME} }}/>
    </>
  )
}

PartCard.defaultProps = {
  devBackdoors: { /* prevent misindices */
    user: {}
  }
}
