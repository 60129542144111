export default async function loadSupplierLists(suppliers){

  const DOMESTIC_SUPPLIER_ARRAY = suppliers
        .filter(s => s.supplierType === 'domestic')
        .sort((sa,sb) => sa.sortOrder - sb.sortOrder)

  const INTERNATIONAL_SUPPLIER_ARRAY = suppliers
        .filter(s => s.supplierType === 'international')
        .sort((sa,sb) => sa.sortOrder - sb.sortOrder)

  const CLOSED_SUPPLIER_ARRAY = suppliers
        .filter(s => s.supplierType === 'closed')
        .sort((sa,sb) => sa.sortOrder - sb.sortOrder)

  return {
    DOMESTIC_SUPPLIER_ARRAY,
    INTERNATIONAL_SUPPLIER_ARRAY,
    CLOSED_SUPPLIER_ARRAY,
  }
}
