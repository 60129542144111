import React from 'react'
import PartsMenu from './PartsMenu.js'
import MakeOrderDataCard from './MakeOrderDataCard.js'
import { NumberField } from '../../QuoteTool/NumberField.js'
import ShipmentDataCard from './ShipmentDataCard.js'
import {Button,Table,TableBody,TableCell,TableRow,Paper,Tooltip,Typography} from '@material-ui/core'

const ActivityColumn = ({
  activity,
  changeToSourcingAttemptForm,
  newActivityHandler,
  onChange,
  onChangeActivityType,
  onChangePtpc,
  openBoxesForm,
  project,
  suppliers,
  SUPPLIER_TYPES,
  updateSupplierPricing
}) => {
  return(
    <div
      style={{
        display:'flex',
        flexDirection:'column',
        gap: '0.5vw',
        minWidth: '30em',
      }}
    >
      {(activity.makeOrder) ?
          <MakeOrderDataCard
            activity={activity}
            changeToSourcingAttemptForm={changeToSourcingAttemptForm}
            onChange={onChange}
            onChangeActivityType={onChangeActivityType}
            project={project}
            suppliers={suppliers}
            SUPPLIER_TYPES={SUPPLIER_TYPES}
          />
        :
        <EmptyMakeOrderCard
          activityNumber={activity.activityNumber}
        />
      }
      {(activity.shipment) ?
          <ShipmentDataCard
            activity={activity}
            onChange={onChange}
            project={project}
            suppliers={suppliers}
          />
        :
        <EmptyShipmentCard
          activity={activity}
          newActivityHandler={newActivityHandler}
        />
      }
      <PartBatchesTable
        activity={activity}
        onChange={onChange}
        onChangePtpc={onChangePtpc}
        openBoxesForm={openBoxesForm}
        project={project}
        updateSupplierPricing={updateSupplierPricing}
      />
    </div>
  )
}

const EmptyMakeOrderCard = ( props ) => {
  const { activityNumber, ...otherProps } = props
  return (
    <Paper
      className="make-order-row-height"
      {...otherProps}
    >
      <Typography align="center" variant="h6">{activityNumber}</Typography>
    </Paper>
  )
}

const EmptyShipmentCard = ( props ) => {
  const { activity, newActivityHandler, ...otherProps } = props
  return (
    <Paper
      className="shipment-row-height"
      {...otherProps}
    >
      <Button
        color="secondary"
        onClick={()=>{newActivityHandler("shipment",activity)}}
        style={{minWidth: '150px', minHeight: '125px'}}
        variant="outlined"
      >
        Add Shipment
      </Button>
    </Paper>
  )
}

const PartBatchesTable = ( props ) => {
  const { activity, onChange, onChangePtpc, openBoxesForm, project, updateSupplierPricing, ...otherProps } = props
  const doesShipmentHaveBoxes = (activity.shipment && activity.shipment.boxes && activity.shipment.boxes.length > 0)
  return (
    <Paper
      {...otherProps}
    >
      <Table>
        <TableBody className="table-rows-alternating-colors">
          <TableRow>
            <TableCell className="table-header-row">
              Quantity
            </TableCell>
            <TableCell className="table-header-row">
              <ShipmentBoxesButtonMaybe
                activityNumber={activity.activityNumber}
                openBoxesForm={openBoxesForm}
                shipment={activity.shipment}
              />
            </TableCell>
            <TableCell className="table-header-row">
              <div style={{display:'flex', justifyContent:'center'}}>
                Supplier Unit Price
              </div>
            </TableCell>
            <TableCell className="table-header-row">
              <div style={{display:'flex', justifyContent:'end'}}>
                <PartsMenu
                  activity={activity}
                  partGroups={project.partGroups}
                  updateSupplierPricing={updateSupplierPricing}
                />
              </div>
            </TableCell>
          </TableRow>
          <PartBatchRows
            activityNumber={activity.activityNumber}
            doesShipmentHaveBoxes={doesShipmentHaveBoxes}
            partBatches={activity.partBatches}
            onChange={onChange}
            onChangePtpc={onChangePtpc}
          />
        </TableBody>
      </Table>
    </Paper>
  )
}

const ShipmentBoxesButtonMaybe = ({ activityNumber, openBoxesForm, shipment }) => {
  return shipment ?
    <Button
      color="secondary"
      onClick={() => openBoxesForm(activityNumber)}
      size="small"
      variant="outlined"
    >
      Boxes
    </Button>
  : null
}

const PartBatchRows = ({ activityNumber, doesShipmentHaveBoxes, partBatches, onChange, onChangePtpc }) => {
  return partBatches.map((partBatch) => {
    return(
      <PartBatchRow
        activityNumber={activityNumber}
        doesShipmentHaveBoxes={doesShipmentHaveBoxes}
        key={`partBatchRow-${activityNumber}-${partBatch.partGroup}-${partBatch.productionToolPartConfigurationId}`}
        partBatch={partBatch}
        onChange={onChange}
        onChangePtpc={onChangePtpc}
      />
    )
  })
}

const PartBatchRow = ({activityNumber, doesShipmentHaveBoxes, partBatch, onChange, onChangePtpc}) => {
  const onChangeHandler = (e) => {
    if(partBatch.productionToolPartConfigurationId){
      onChangePtpc(activityNumber, e, partBatch.productionToolPartConfigurationId)
    } else {
      onChange(activityNumber, e, partBatch.partGroup)
    }
  }

  return (
    <TableRow className="fixed-part-row-height">
      <TableCell colSpan="2">
        <NumberFieldWithDisabledTooltip
          disabled={doesShipmentHaveBoxes}
          disabledTooltipText={"Value auto-set by boxes"}
          name="partBatchesQuantity"
          onChange={onChangeHandler}
          placeholder="Part Quantity"
          style={{width: "100%"}}
          value={partBatch.quantity ? partBatch.quantity : ''}
        />
      </TableCell>
      <TableCell colSpan="2">
        <NumberField
          name="partBatchesCustomUnitPrice"
          onChange={onChangeHandler}
          placeholder="Custom Price"
          value={partBatch.customUnitPrice ? partBatch.customUnitPrice : ''}
        />
      </TableCell>
    </TableRow>
  )
}

export default ActivityColumn

function NumberFieldWithDisabledTooltip(props){
  const { disabledTooltipText, ...otherProps } = props

  const Enabled = () => <NumberField {...otherProps}/>

  const Disabled = () => (
    <Tooltip title={disabledTooltipText}>
      <span>
        <NumberField {...otherProps}/>
      </span>
    </Tooltip>
  )

  return props.disabled ? Disabled() : Enabled()
}
