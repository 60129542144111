import React,{useEffect,useState} from "react"
import {NumberField} from "./NumberField"
import {Button,CircularProgress,IconButton,TextField,Tooltip,Typography} from "@material-ui/core"
import {CloudDownload} from '@material-ui/icons'
import DmeApprovedNotesAtom from './DmeApprovedNotesAtom.js'
import downloadFromS3AndSaveFile from '../../utils/downloadFromS3AndSaveFile.js'
import Info from "@material-ui/icons/Info"
import ManualRfqTextWithTooltip from './ManualRfqTextWithTooltip.js'
import Menu from "@material-ui/icons/Menu"
import SelectMaterialAutocomplete from "../Common/SelectMaterialAutocomplete.js"
import {
  DeleteButtonAtom,
  ExtractingParametersWaitingIndicator,
  FormattedFileNameAtom,
  PartAttachmentsAtom,
  PartDimensionsAtom,
  PartProcessAtom,
  PostProcessesAtom,
  StatusFeedbackAtom,
  ThumbnailOrPlaceHolderAtom,
  UnitSelectionAtom,
  UploadingLinearProgress,
} from "./partCardAtoms"
import {appendInfillIfNeeded} from '../../shared/appendInfillIfNeeded.js'
import CustomerPartConfiguration from './CustomerPartConfiguration'
import {determinePartRowTypeToReturn} from './determinePartRowTypeToReturn'
import {formatAttachmentsList} from '../../utils/formatAttachmentsList'
import {formatFileDimensions,formatFileDimensionsForToolTip} from '../../utils/formatFileDimensions'
import getManufacturabilityIssues from '../../shared/getManufacturabilityIssues.js'
import {needsManufacturabilityIssuesApproved} from '../../utils/needsManufacturabilityIssuesApproved'
import {formatPrice,TranslateProcessId} from '../../utils'
import remainingProductionToolLife from './remainingProductionToolLife.js'
import PartCardActions from './PartCardActions.js'
import USER_TYPES from '../../utils/USER_TYPES.js'
const {TranslateMaterialId} = require('../Common/TranslationTableMaterial.js')


export default function Tooling (props) {
  const [purchasedToolData, setPurchasedToolData] = useState({remainingLife: 'Loading...', projects: []})
  useEffect(() => {
    if(!props.partGroup.isOriginalPartGroup){
      remainingProductionToolLife(props.partGroup)
        .then(data => {
          setPurchasedToolData(data)
        })
    }
  }, ['ONMOUNT'])

  let rowType = props.devBackdoors.spoofedRowType ?
  props.devBackdoors.spoofedRowType
  : determinePartRowTypeToReturn(needsManufacturabilityIssuesApproved(props.status), props.status, props.partGroup.part)

  const partGroupPriceMaybe = props.partGroupPrices && props.partGroupPrices.find(ptg => ptg.partGroupNumber === props.partGroup.partGroupNumber)
  const actualGuaranteedLife = partGroupPriceMaybe ? partGroupPriceMaybe.guaranteedLife : 0 // to accomodate for parts that are new and dont have guaranteed lives calculated yet
  const toolPrice = partGroupPriceMaybe ? partGroupPriceMaybe.unitPrice : 0 // to accomodate for parts that are new and dont have prices yet

  let projectsArray = purchasedToolData.projects
        .map(project => `${project.projectNumber} (${project.projectTotal} parts)`)

  if(props.partGroup.isLegacyTool){
    projectsArray = [`Legacy (${props.partGroup.legacyPartsMade} parts)` , ...projectsArray]
  }

  const projectsList = projectsArray
        .join(', ')

  return(
    <div className="tooling">
      <div style={{ gridArea: "sttus" }}>
        <StatusFeedbackAtom
          manufacturabilityIssues={ getManufacturabilityIssues(props.partGroup, props.postProcessData.POST_PROCESS_OPTIONS) }
          onChange={e => props.onChange(e, props.partGroup.partGroupNumber)}
          partGroup={props.partGroup}
          riskAccepted={props.partGroup.part.riskAccepted}
          rowType={rowType}
          showWallThicknessCheckbox={true}
          user={props.user}
        />
      </div>
      <div className="thumbnail-atom" style={{ gridArea: "thumb" }}>
        <ThumbnailOrPlaceHolderAtom
          hasModel={props.partGroup.part.s3ObjFileId || props.partGroup.part.s3ObjFileIdNew}
          isAtRisk={rowType === "at_risk"}
          onClick={()=>props.openPartModal(props.partGroup)}
          thumbnailS3id={props.partGroup.part.thumbnailS3id}
          s3ThumbnailFileIdNew={props.partGroup.part.s3ThumbnailFileIdNew}
        />
      </div>
      <div className="file-name-atom" style={{ gridArea: "fname" }}>
        <FormattedFileNameAtom
          fileName={props.partGroup.part.fileName}
        />
        <IconButton tabIndex="-1" color="primary" onClick={() => downloadFromS3AndSaveFile(props.partGroup.part.partFileS3, props.partGroup.part.fileName)}>
          <CloudDownload
            color="primary"
            fontSize="small"
          />
        </IconButton>
      </div>
      <div style={{ gridArea: "pdims" }}>
        { rowType === "parameter_extract_in_progress" && <ExtractingParametersWaitingIndicator/> }
        { rowType === "uploading_part" && <UploadingLinearProgress/> }
        { ( ! (rowType === "uploading_part" || rowType === "parameter_extract_in_progress") ) &&
          <PartDimensionsAtom
            formattedDimensionsLabel={formatFileDimensions(props.partGroup.part)}
            formattedDimensionsToolTipLabel={formatFileDimensionsForToolTip(props.partGroup.part)}
          />
        }
      </div>
      <div className="unit-selection-atom" style={{ gridArea: "units" }}>
        <UnitSelectMaybe
          disabled={!props.partGroup.isOriginalPartGroup}
          onChange={props.onChange}
          partGroup={props.partGroup}
          rowType={rowType}
        />
      </div>
      <div className="process-select-atom" style={{ gridArea: "prces" }}>
        <ProcessSelectMaybe
          disabled={!props.partGroup.isOriginalPartGroup}
          partId={props.partGroup.partGroupNumber}
          processId={props.partGroup.processId}
          onChange={props.onChange}
        />
      </div>
      <div style={{ justifyContent: "normal", gridArea: "mater" }}>
        <MaterialSelectMaybe
          disabled={!props.partGroup.isOriginalPartGroup}
          MATERIAL_TRANSLATIONS={props.MATERIAL_TRANSLATIONS}
          onChange={props.onChange}
          partGroup={props.partGroup}
          user={props.user}
        />
      </div>
      <div className="quantity-input-atom" style={{gridArea: "quant" }}>
        <GuaranteedLifeMaybe
          actualGuaranteedLife={actualGuaranteedLife}
          disabled={!props.partGroup.isOriginalPartGroup}
          onChange={(e)=> props.onChange(e,props.partGroup.partGroupNumber)}
          processId={props.partGroup.processId}
          showTooltip={props.partGroup.isOriginalPartGroup}
          value={props.partGroup.isOriginalPartGroup ? props.partGroup.guaranteedLife : props.partGroup.actualGuaranteedLife}
        />
      </div>
      <div className="tool-price-atom"  style={{gridArea: "tlprc" }}>
        {props.partGroup.isOriginalPartGroup ?
         <ToolPriceField
           isCalculatingPrices={props.isCalculatingPrices}
           partGroup={props.partGroup}
           partReasons={props.partReasons}
           value={toolPrice}
         />
         :
         <ReadOnlyField label="Parts Remaining" value={purchasedToolData.remainingLife}/>
        }
      </div>
      <div style={{ gridArea: "postp" }} className="truncated-list-container-parent">
        <PostProcessesAtom
          disabled={!props.partGroup.isOriginalPartGroup}
          /* materialId={props.partGroup.materialId} */
          materialId='imToolAluminum6061' // STUB value to get all of the IM options since we don't know the IM material in the front-end.
          overrideLabel="Surface Finish"
          onClick={() => props.setPostProcessesModalIsOpen(true)}
          postProcessData={props.postProcessData}
          postProcesses={props.partGroup.postProcesses}
          postProcessesCrud={props.crud.partGroups(props.partGroup.partGroupNumber).postProcesses}
          processLabel={props.partGroup.finishing}
          user={props.user}
        />
      </div>
      <div style={{ gridArea: "atchs" }} className="truncated-list-container-parent" >
        <PartAttachmentsAtom
          attachmentsList={formatAttachmentsList(props.partGroup.attachments)}
          disabled={!props.partGroup.isOriginalPartGroup}
          isNotDmeReviewed={props.user.type === USER_TYPES.DME && !props.partGroup.isDmeReviewed}
          openAttachmentsModal={() => props.setAttachmentsListModalIsOpen(true)}
        />
      </div>
      <div style={{ gridArea: "notes" }}>
        <DmeApprovedNotesAtom
          disabled={!props.partGroup.isOriginalPartGroup}
          isDmeReviewed={props.partGroup.isDmeReviewed}
          onChangeCheckbox={e => {
            const target = {
              name: e.target.name,
              value: e.target.checked,
            }
            props.onChange({target}, props.partGroup.partGroupNumber)
          }}
          onChangeNotes={(e)=> props.onChange(e,props.partGroup.partGroupNumber)}
          notes={props.partGroup.part.partNotes}
          user={props.user}
        />
        {props.partGroup.isOriginalPartGroup ? null :
         <div style={{ gridArea: "projs", display: 'flex', alignItems: 'center', gap: '0.5em', paddingTop: '1em' }}>
           Projects: {
             purchasedToolData.projects.length ? projectsList
             :
               <CircularProgress style={{textAlign: 'center'}} size='0.9em'/>
           }
         </div>
        }
      </div>
      <div className="delete-button-atom" style={{ gridArea: "dltpt" }}>
        <DeleteButtonAtom
          deleteHandler={()=>props.deletePartGroup(props.partGroup.partGroupNumber)}
          tooltipTitle="Delete this part"
        />
      </div>
      <div style={{ gridArea: "clpse" }}>
        <PartCardActions
          collapse={() => props.setPartGroupNumberToEdit(null)}
          partGroupNumber={props.partGroup.partGroupNumber}
          replacePartFile={props.replacePartFile}
          user={props.user}
        />
      </div>

    </div>
  )
}


const DmeDetailsMenu = ({ onClick }) => {
  return(
    <IconButton color="primary" onClick={onClick}>
      <Menu/>
    </IconButton>
  )
}

const GuaranteedLifeMaybe = ({ actualGuaranteedLife, disabled, onChange, processId, showTooltip, value }) => {
  if(processId !== "injMoldingPart"){
    return null // only show this for IM
  }

  return(
    <NumberField
      disabled={disabled}
      label={
        <div style={{display:"flex", alignItems:"flex-end", width:"12em"}}>
          {showTooltip ?
          <Tooltip
            enterDelay={400}
            placement="top"
            title={
            <Typography variant="body2">
              Actual Guaranteed Life: {Number(actualGuaranteedLife).toLocaleString()}<br/>
              For best results, select an increment of 2,000
            </Typography>
          }>
            <Info className="informative" fontSize="small"/>
          </Tooltip>
           : null}
          <span>Tool Life Quantity</span>
        </div>
      }
      name="guaranteedLife"
      onChange={onChange}
      value={value}
    />
  )
}

const ToolPriceField = ({ isCalculatingPrices, partGroup, partReasons, value }) => {
  return(
    isCalculatingPrices ?
      <CircularProgress /> :
      <b> {
        partReasons.length > 0 ?
          <ManualRfqTextWithTooltip
            partGroup={partGroup}
          />
        : formatPrice(value)
      } </b>

  )
}

const UnitSelectMaybe = ({ disabled, onChange, partGroup, rowType }) => {
  return(
    disabled ?
      <ReadOnlyField label="Units" value={partGroup.part.units}/>
      :
      <UnitSelectionAtom
        onChange={(e)=> onChange(e, partGroup.partGroupNumber)}
        part={partGroup.part}
        rowType={rowType}
        unitValue={partGroup.part.units}
      />
  )
}

const ProcessSelectMaybe = ({ disabled, partId, processId, onChange }) => {
  return(
    disabled ?
      <ReadOnlyField label="Process" value={TranslateProcessId(processId)}/>
      :
      <PartProcessAtom
        onChange={onChange}
        partId={partId}
        processId={processId}
      />
  )
}

const MaterialSelectMaybe = ({ disabled, MATERIAL_TRANSLATIONS, onChange, partGroup, user }) => {
  let material = TranslateMaterialId(partGroup.processId, partGroup.materialIdProductionToolIsDesignedFor, MATERIAL_TRANSLATIONS)
  material = appendInfillIfNeeded(material, partGroup.processId, partGroup.infillPercentage)

  if(partGroup.processId !== "injMoldingPart"){
    return null // only show this for IM
  }

  return(
    disabled ?
      <ReadOnlyField label="Part Material the Tool is Designed For" value={material}/>
      :
      <SelectMaterialAutocomplete
        label="Part Material the Tool is Designed For"
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        onChange={(e) => {
          let event = { target: { name: "materialIdProductionToolIsDesignedFor", value: e.value } }
          onChange(event,partGroup.partGroupNumber)
        }}
        process={partGroup.processId}
        user={user}
        value={partGroup.materialIdProductionToolIsDesignedFor}
      />
  )
}

const ReadOnlyField = ({ label, value }) => {
  return(
    <TextField label={label} value={value} style={{width:"100%"}} disabled />
  )
}

// SHOWCASE STUFF
const _ = require('lodash')
Tooling.exampleProps = _.cloneDeep(CustomerPartConfiguration.exampleProps)
Tooling.exampleProps.partGroup.guaranteedLife = 10000
Tooling.exampleProps.partGroup.toolPrice = 5025.56
Tooling.Showcase = Showcase
function Showcase() {
  return(
    <>
      <Tooling { ...Tooling.exampleProps } devBackdoors={{}}/>
    </>
  )
}

Tooling.defaultProps = {
  devBackdoors: {} /* prevent misindices */
}
