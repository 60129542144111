import React from 'react'
import { 
  Tooltip,
  Select, 
  TextField,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import {Info} from '@material-ui/icons'
import { NumberField } from "./NumberField.js"
import {SelectOptionProcess} from '../Common'
import SelectMaterialAutocomplete from "../Common/SelectMaterialAutocomplete.js"

function ApplyAllToolbar(props) {
  const { 
    applyValuesToAll,
    MATERIAL_TRANSLATIONS,
    onChangeApplyAllFieldQuoteToolHandler,
    user,
  } = props

  return (
    <ApplyToAllHeaderRow
      applyValuesToAll={applyValuesToAll}
      MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
      onChangeApplyAllFieldQuoteToolHandler={onChangeApplyAllFieldQuoteToolHandler}
      user={user}
    />
  )

}

const ApplyToAllHeaderRow = (props) => {
  const {
    applyValuesToAll,
    MATERIAL_TRANSLATIONS,
    onChangeApplyAllFieldQuoteToolHandler,
    user,
  } = props

  return ( 
    <div className="apply-all-toolbar">
      <ApplyAllRowLabel/>
      <UnitsSelectionForAll
        onChangeApplyAllFieldQuoteToolHandler={onChangeApplyAllFieldQuoteToolHandler}
        applyValuesToAll={applyValuesToAll}
      />
      <ProcessSelection
        applyValuesToAll={applyValuesToAll}
        onChange={onChangeApplyAllFieldQuoteToolHandler}
      />
      <MaterialSelection
        applyValuesToAll={applyValuesToAll}
        MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
        onChange={onChangeApplyAllFieldQuoteToolHandler}
        user={user}
      />
      <QuantityForAll 
        onChangeApplyAllFieldQuoteToolHandler={onChangeApplyAllFieldQuoteToolHandler}
        applyValuesToAll={applyValuesToAll}
      />
    </div>
  )
}

const ApplyAllRowLabel = () => (
  <Tooltip title="This field sets all parts in the project to the selected value. All new parts will also be created with this value">
    <span className="apply-all-label" style={{
      textDecoration: "underline",
    }}>
      Apply to All <Info fontSize="small" style={{marginLeft:'0.5em'}}/>
    </span>
  </Tooltip>
)

const UnitsSelectionForAll = (props) => {
  const { 
    onChangeApplyAllFieldQuoteToolHandler,
    applyValuesToAll,
  } = props
  return (
    <FormControl fullWidth={true}>
      <InputLabel id='unit-selector'>Units</InputLabel>
        <Select
          labelId='unit-selector'
          native
          name="forAllUnits"
          onChange={onChangeApplyAllFieldQuoteToolHandler}
          value={applyValuesToAll.forAllUnits}
        >
          <option value=""></option>
          <option value="mm">mm</option>
          <option value="inches">inches</option>
      </Select>
    </FormControl>
  )
}
const QuantityForAll = (props) => {
  const { 
    onChangeApplyAllFieldQuoteToolHandler,
    applyValuesToAll,
  } = props

  return (
    <NumberField
      fullWidth={true}
      name="forAllQuantity"
      onChange={onChangeApplyAllFieldQuoteToolHandler}
      value={applyValuesToAll.forAllQuantity}
      label="Quantity"
    />
  )
}

const ProcessSelection = (props) => {
  const {
    applyValuesToAll, 
    onChange,
  } = props

  return(
    <SelectOptionProcess
      blankOptionEnabled
      name="forAllProcess"
      onChange={onChange}
      value={applyValuesToAll.forAllProcess}
    />
  )
}

const MaterialSelection = (props) => {
  const { 
    applyValuesToAll,
    MATERIAL_TRANSLATIONS,
    onChange,
    user,
  } = props

  return(
    <SelectMaterialAutocomplete
      blankOptionEnabled
      MATERIAL_TRANSLATIONS={MATERIAL_TRANSLATIONS}
      onChange={(e) => {
        let event = { target: { name: "forAllMaterial", value: "" } }
        if(e !== null) event.target.value = e.value
        onChange(event)
      }}
      process={applyValuesToAll.forAllProcess}
      user={user}
      value={applyValuesToAll.forAllMaterial}
    />
  )
}


export default ApplyAllToolbar
