import React,{Component} from 'react'
import {
  addPartDisplayValues,
  applyDefaultValuesToNewPart,
  applyDefaultValuesToNewPartGroup,
  checkFileTypeIncludeUnits,
  createPartGroup,
  createFile,
  deleteFile,
  fileIsTooLargeToAnalyze,
  isModelOrDrawing,
  MAX_ALLOWED_ATTACHMENT_FILE_SIZE_IN_BYTES,
  MAX_ALLOWED_UPLOAD_FILE_SIZE_IN_MB,
  partAnalysisStatus,
  removePartGroup,
  replacePartFile,
  sendManualRfqEmailPromise,
  sleep,
  unitHandling,
  updateMaterialAndFinish,
  updatePartGroup,
  updateWholeProjectPromise,
} from '../../utils'
import { CNC_TYPES } from './CNC_TYPES.js'
import {COLORS} from '../../shared/COLORS.js'
import cloneProject from './cloneProject.js'
import createProductionToolPartConfiguration from "../../utils/createProductionToolPartConfiguration"
import deleteProductionToolPartConfiguration from "../../utils/deleteProductionToolPartConfiguration.js"
import updateProductionToolPartConfiguration from "../../utils/updateProductionToolPartConfiguration.js"
import { convertDimensionsEtcInPartGroup } from '../../utils/convertDimensionsEtcInPartGroup.js'
import nestedPartGroupFieldsCrud from './nestedPartGroupFieldsCrud.js'
import QuoteTool from './QuoteTool.js'
import frg from '../../feature-release-guards.js'
import * as geom from '../../Components/QuoteTool/geometryAnalysis'
import * as s3 from '../../Components/QuoteTool/cloudObjectStorage'
import sendCustomerQuoteEmail from './sendCustomerQuoteEmail.js'
import sendLogisticsQuoteEmail from './sendLogisticsQuoteEmail.js'
import updateProjectManager from '../Common/updateProjectManager.js'
import withProject from '../Common/withProject.js'
import { getCncMillStockValues, getCncTurnStockValues } from './getCncStockValues.js'
import { createNewPartGroupFromFile, setNewPartDefaultValues } from './createNewPartGroupFromFile.js'
import USER_TYPES from '../../utils/USER_TYPES.js'


import createPostProcessConfig from './crudConfig/createPostProcessConfig.js'
import createOvermoldedInsertConfig from './crudConfig/createOvermoldedInsertConfig.js'
import createToleranceConfig from './crudConfig/createToleranceConfig.js'
import createProductionToolPartConfigurationAttachment from '../../utils/createProductionToolPartConfigurationAttachment.js'


const { calculateInjectionMoldingData } = require('../../utils/calculateInjectionMoldingData.js')
const { addToArrayIfUnique } = require('../../shared/addToArrayIfUnique.js')

const BYTES_IN_A_MB = 1048576
const QUANTITY_FIELD_MAX_NUMBER_OF_DIGITS_ALLOWED_BY_DATABASE = 10

const deepCopy = (array) => JSON.parse(JSON.stringify(array))

const emptyApplyToAll = { // Object for passing 'apply value to all' defaults
  forAllMaterial: '', // Used in quote tool table for copying value to all rows
  forAllProcess: '', // Used in quote tool table for copying value to all rows
  forAllQuantity: undefined, // Used in quote tool table for copying value to all rows
  forAllUnits: '', // Used in quote tool table for copying value to all rows
}

class SmartQuoteTool extends Component{
  constructor(props){
    super(props)
    this.state = {
      applyValuesToAll: emptyApplyToAll,
      currentlyLoading: false,      // Used to trigger loading animations and disables some buttons
      selectedLeadTime: null,       // Holds the selected lead time. Must be null when not selected due to the component (Autocomplete) using this field
      submittingForRfq: false,      // Flag for when submitting a quote for manual RFQ is processing
    }
  }

  componentDidMount(){
    this.checkPartGroupsForMissingParamExtract()
    this.checkPartGroupsForInjectionMoldingJob()
  }

  checkPartGroupsForInjectionMoldingJob = () => {
    const partGroups = deepCopy(this.props.project.partGroups)
    partGroups.forEach((partGroup) => {
      this.triggerInjectionMoldingWallThicknessTaskIfNecessary(partGroup.partGroupNumber)
    })
  }

  checkPartGroupsForMissingParamExtract = () => {
    const partGroups = deepCopy(this.props.project.partGroups)
    partGroups.forEach((partGroup) => {
      if(!partGroup.part.s3ObjFileId && !partGroup.part.s3ObjFileIdNew){
        this.runParameterExtraction(partGroup.partGroupNumber)
      }
    })
  }

  runParameterExtraction = async (partGroupNumber) => {
    const project = deepCopy(this.props.project)
    const partGroup = project.partGroups
          .find(partGroup => partGroup.partGroupNumber === partGroupNumber)

    if(partGroup === undefined){return} // short circuit to prevent a bug when a part is deleted before the netfabb results return

    // if the uploaded file is a drawing
    if (isModelOrDrawing(partGroup.part.fileName) === 'drawing') {
      // drawings need to be manually quoted
      partGroup.part.mfgAnalysisIssues = addToArrayIfUnique(partGroup.part.mfgAnalysisIssues,'Drawings must be manually quoted')

      this.props.setProject(project, { partGroupNumber, savePartGroup: true })
      return
    }

      if (fileIsTooLargeToAnalyze(partGroup.part.fileSize)) {
        // show a manual RFQ reason saying the file is too large to quote
        partGroup.part.mfgAnalysisIssues = addToArrayIfUnique(partGroup.part.mfgAnalysisIssues,'File is too large to be automatically quoted')

        this.props.setProject(project, { partGroupNumber, savePartGroup: true })
        return
      }

    let updatedPart
    try {
      // start a new netfabb task
      // AKA add a new message to the netfabb processing queue
      await geom.startFileConversionTask(partGroup.part.partFileS3, partGroup.part.partNumber, partGroup.part.fileName)
      updatedPart = await geom.pollDatabaseForFileConversionResult(partGroup.part.partNumber)

      // fetch the results from netfabbs file processing
      // which will either be dimensions or an error message
      // if the process completed successfully
      await geom.startNewDimensionTask(updatedPart.s3StlFileIdNew, updatedPart.partNumber)
      await geom.startThumbnailCreationTask(updatedPart.s3StlFileIdNew, updatedPart.partNumber, updatedPart.fileName)

      updatedPart = await geom.pollDatabaseForNewParamExtractionResult(updatedPart.partNumber)
      updatedPart = await geom.pollDatabaseForThumbnailResult(updatedPart.partNumber)
    } catch (error) {
      const project = deepCopy(this.props.project)
      const partGroup = project.partGroups
            .find(partGroup => partGroup.partGroupNumber === partGroupNumber)

      if(partGroup === undefined){return} // short circuit to prevent a bug when a part is deleted before the netfabb results return

      let message = error.message.includes('failed to fetch') ? 'Tried to analyze part for 3 minutes with no success. Please try again or submit to us for a manual review' : 'Failed to process file'
      partGroup.part.paramExtractError = message

      // push generic error message to customer
      partGroup.part.mfgAnalysisIssues = addToArrayIfUnique(partGroup.part.mfgAnalysisIssues,'Failed to process file dimensions')

      this.props.setProject(project, { partGroupNumber, savePartGroup: true })
      return
    }

    const projectCopy = deepCopy(this.props.project)
    let rowCopy = projectCopy.partGroups.find(partGroup => partGroup.partGroupNumber === partGroupNumber)
    if(rowCopy === undefined){return} // short circuit to prevent a bug when a part is deleted before the netfabb results return

    // update dimensions here
    // limit precision to two places after the decimal (in mm)
    rowCopy.part.partSurfaceAreaUnits = Number(Number(updatedPart.partSurfaceAreaUnits).toFixed(6))
    rowCopy.part.partVolumeUnits = Number(Number(updatedPart.partVolumeUnits).toFixed(6))
    rowCopy.part.xDimUnits = Number(Number(updatedPart.xDimUnits).toFixed(6))
    rowCopy.part.yDimUnits = Number(Number(updatedPart.yDimUnits).toFixed(6))
    rowCopy.part.zDimUnits = Number(Number(updatedPart.zDimUnits).toFixed(6))

    rowCopy.part.meshProperties = updatedPart.meshProperties
    rowCopy.part.paramExtractError = updatedPart.paramExtractError
    rowCopy.part.s3ObjFileId = updatedPart.s3ObjFileId
    rowCopy.part.s3StlFileId = updatedPart.s3StlFileId
    rowCopy.part.thumbnailS3id = updatedPart.thumbnailS3id

    rowCopy.part.s3GlbFileId = updatedPart.s3GlbFileId
    rowCopy.part.s3ObjFileIdNew = updatedPart.s3ObjFileIdNew
    rowCopy.part.s3StlFileIdNew = updatedPart.s3StlFileIdNew
    rowCopy.part.s3ThumbnailFileIdNew = updatedPart.s3ThumbnailFileIdNew

    // add initial dimension mapping (also happens onChange of units, dimensions, etc)
    rowCopy = convertDimensionsEtcInPartGroup(rowCopy)

    const callback = () => {
      this.triggerWallThicknessTaskIfNecessary(rowCopy.partGroupNumber)
      this.triggerInjectionMoldingWallThicknessTaskIfNecessary(rowCopy.partGroupNumber)
    }
    this.props.setProject(projectCopy, { partGroupNumber: rowCopy.partGroupNumber, savePartGroup: true, callback })
  }

  uploadParts = async (partCollection, failedPartCollection) => {
    this.setState({ currentlyLoading: true })

    const uploadedPartAttempts = await Promise.allSettled(
      partCollection
        .map(async (file, index) => {
          const INTERVAL = index * 1000 // seconds
          await sleep(INTERVAL)

          return this.handleFile(file)
        })
    )

    const failedPartAttempts = uploadedPartAttempts
          .filter(result => result.status === "rejected")
          .map(result => result.reason)
          .map(error => error.message)

    let promiseArray = failedPartCollection.map(failedPart => {
      let fileSizeMb = Math.round(failedPart.size / BYTES_IN_A_MB, 0)
      return (`${failedPart.name} : File size of ${fileSizeMb} MB is too big. File upload limit is ${MAX_ALLOWED_UPLOAD_FILE_SIZE_IN_MB} MB per file`)
    })

    let failedToUploadArray = promiseArray.concat(failedPartAttempts)
    // create a popup if any files failed to upload
    if (failedToUploadArray.length > 0) {
      let failedToUploadString = 'The following files failed to upload. Try again or send files to contact@autotiv.com: '
      this.props.openDialog(failedToUploadArray,failedToUploadString)
    }
    this.setState({ currentlyLoading: false })
  }

  /*
   * handleFile takes a file object and calls a few backend services to handle the part
   * First it uploads the file to s3 and creates a new part row
   * Next it starts a new Netfabb task to extract the dimensions
   * Finally it fetches the netfabb result when it is complete and fills in the rows dimenion fields
   * NOTE: rejected promises means that the file couldn't be uploaded to storage and a row was not created
   * otherwise if a row was created the promise will be resolved
   */
  handleFile = async (file) => {
    let newPartGroup = await createNewPartGroupFromFile(file, this.props.project, this.state.applyValuesToAll, this.props.MATERIAL_TRANSLATIONS)

    // re-apply apply-to-all values after all async operation in case user has set a value in the meantime
    newPartGroup = applyDefaultValuesToNewPartGroup(newPartGroup, this.state.applyValuesToAll, this.props.MATERIAL_TRANSLATIONS) //If any 'apply value to all' fields are selected use those values for default
    newPartGroup.part = applyDefaultValuesToNewPart(newPartGroup.part, this.state.applyValuesToAll) //If any 'apply value to all' fields are selected use those values for default

    const projectCopy = deepCopy(this.props.project)
    projectCopy.partGroups.push(newPartGroup)

    this.props.setProject(projectCopy, { partGroupNumber: newPartGroup.partGroupNumber, saveProject: true, savePartGroup: true })

    // upload to s3
    let uploadFileKey
    try {
      uploadFileKey = await s3.uploadFileToS3(file)
    } catch(error) {
      const project = deepCopy(this.props.project)
      project.partGroups = project.partGroups.filter(ptg => ptg.partGroupNumber !== newPartGroup.partGroupNumber)
      this.props.setProject(project, { partGroupNumber: newPartGroup.partGroupNumber, saveProject: true, savePartGroup: true })
      throw new Error(`${file.name} : Internal error: failed to upload file`)
    }

    // create the file in the database
    let fileId
    try {
      const fileToCreate = {
        fileName: file.name,
        s3Id: uploadFileKey,
        projectId: projectCopy.projectNumber,
        partId: newPartGroup.part.partNumber,
        fileType: 'part',
        userUploaded: projectCopy.customerEmail,
        isActive: true,
      }

      const newFile = await createFile(fileToCreate)
      fileId = newFile.fileId
    } catch(error) {
      const project = deepCopy(this.props.project)
      project.partGroups = project.partGroups.filter(ptg => ptg.partGroupNumber !== newPartGroup.partGroupNumber)
      this.props.setProject(project)
      throw new Error(`${file.name} : Failed to create file in database`)
    }

    const project = deepCopy(this.props.project)
    const partGroup = project.partGroups.find(ptg => ptg.partGroupNumber === newPartGroup.partGroupNumber)

    if(!partGroup){
      // early bail out to handle parts that get deleting while uploading
      return
    }

    // save the ids of the s3 object and the file row to the part
    partGroup.part.partFileS3 = uploadFileKey

    this.props.setProject(project, {
      callback: () => this.runParameterExtraction(newPartGroup.partGroupNumber),
      partGroupNumber: newPartGroup.partGroupNumber,
      savePartGroup: true,
      saveProject: true,
    })
  }

  replacePartFile = async (file, partGroupNumber) => {
    try{
      // upload new file
      const uploadFileKey = await s3.uploadFileToS3(file)

      // create new file in db
      const partNumber = this.props.project.partGroups
            .find(ptg => ptg.partGroupNumber === partGroupNumber)
            .part
            .partNumber

      const fileToCreate = {
        fileName: file.name,
        s3Id: uploadFileKey,
        projectId: this.props.project.projectNumber,
        partId: partNumber,
        fileType: 'part',
        userUploaded: this.props.project.customerEmail,
        isActive: true,
      }

      await replacePartFile(fileToCreate)

      // replace existing part with new part
      const newPartDefaults = await setNewPartDefaultValues(file, this.state.applyValuesToAll)

      const projectCopy = deepCopy(this.props.project)
      const partGroup = projectCopy.partGroups
        .find(ptg => ptg.partGroupNumber === partGroupNumber)
      
      partGroup.part = {
        ...newPartDefaults,
        partNumber: partGroup.part.partNumber,
        partNotes: partGroup.part.partNotes,
      }
      partGroup.part.partFileS3 = uploadFileKey

      // update relevart partGroup fields
      partGroup.isDmeReviewed = !Boolean(partGroup.part.partNotes)
      partGroup.areImParamsDmeApproved = false
      partGroup.isWallThicknessMMDmeReviewed = false
      partGroup.isWallThicknessInchesDmeReviewed = false

      const callback = async () => {
        // must reset netfabb params in database before starting new param extract anaylsis
        const saveNetfabbFields = true
        await updatePartGroup(partGroup, saveNetfabbFields)

        this.runParameterExtraction(partGroupNumber)
      }

      this.props.setProject(projectCopy, {
        callback,
        partGroupNumber: partGroupNumber,
        savePartGroup: true,
        saveProject: true,
      })
    } catch(e){
      this.props.openSnackBarHandler(`Error replacing file: ${e.message}`)
    }
  }

  /*
    onChangePartFieldQuoteToolHandler takes input from the quote tool and updates state
    the optional param 'materialSearchInput' is a bool used to identify the part table material search input
  */
  onChangePartFieldQuoteToolHandler = (event,partID) => {
    console.log('onchange part field')
    let {name,value} = event.target
    const PART_FIELDS = [
      'finishId',
      'materialId',
      'partNotes',
      'processId',
      'quantity',
      'riskAccepted',
      'units',
      'xDimUnits','yDimUnits','zDimUnits','partVolumeUnits','partSurfaceAreaUnits',
      'dmeMarkedAtRiskPartBool',
      'dmeEstimatedRatioOfVolumeToBoundingBox',
      'dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea',
      'pricingMethod','markupCostQuantity1',
      'lockedUnitPrice',
      'guaranteedLife',
      'markupQuantityX','markupCostQuantityX',
      'cnc5AxisMillComplexity',
      'cncMillOrTurn','cncIsHeldInVice','cncSetupsPerPart','cncNumberOfAxes',
      'cncStockX', 'cncStockY', 'cncStockZ', 'cncStockInnerDiameter', 'cncStockOuterDiameter',
      'sheetMetalCutPathLength', 'sheetMetalStockX', 'sheetMetalStockY',
      'sheetMetalStockThickness', 'sheetMetalNumberOfBends', 'sheetMetalNumberOfWelds',
      'averageWallThickness', 'numberOfCollapsingCores', 'numberOfSlides', 'standardDeviationWallThickness',
      'guaranteedLife', 'materialIdProductionToolIsDesignedFor', 'cavityCount',
      'postProcesses', 'drawingUnits',
      'areImParamsDmeApproved', 'isDmeReviewed',
      'isLegacyTool', 'legacyPartsMade',
      'infillPercentage',
      'isWallThicknessMMDmeReviewed', 'isWallThicknessInchesDmeReviewed',
    ]
    if(PART_FIELDS.includes(name)){ //If change is made to a part
      const project = deepCopy(this.props.project)
      let partCollection = project.partGroups
      const PART_INDEX = partCollection.findIndex(part=>{return(part.partGroupNumber === partID)}) //Identify which part in the collection was updated
      if(name === 'processId' && ['injMoldingPart', 'casting'].includes(value)){ // TODO: link up this logic to backend tooling process list
        partCollection[PART_INDEX].quantity = 1
      }

      if(this.props.user.type !== USER_TYPES.DME){
        const DME_REVIEWED_FIELDS = ['partNotes']
        if(DME_REVIEWED_FIELDS.includes(name)){
          partCollection[PART_INDEX].isDmeReviewed = false
        }
      }

      if([
        'dmeEstimatedRatioOfVolumeToBoundingBox',
        'dmeEstimatedRatioOfSurfaceAreaToBoundingBoxSurfaceArea',
        'cnc5AxisMillComplexity',
        'cncMillOrTurn','cncNumberOfAxes',
        'cncStockX', 'cncStockY', 'cncStockZ', 'cncStockInnerDiameter', 'cncStockOuterDiameter',
        'sheetMetalCutPathLength', 'sheetMetalStockX', 'sheetMetalStockY', 'sheetMetalStockThickness',
        'averageWallThickness', 'numberOfCollapsingCores', 'numberOfSlides', 'standardDeviationWallThickness',
        'partNotes',
      ].includes(name)){
        partCollection[PART_INDEX].part[name] = value  //Update relevant part with new value
      }
      else if(name === 'dmeMarkedAtRiskPartBool' || name === 'cncIsHeldInVice'){ // boolean type part fields
        let boolValue = false
        if(value === 'true' || value === true){
          boolValue = true
        }
        partCollection[PART_INDEX].part[name] = boolValue
      }
      else if(['cncSetupsPerPart', 'sheetMetalNumberOfBends', 'sheetMetalNumberOfWelds'].includes(name)){
        value = value.replace(/[^0-9]/g, "") // ensure this number is an integer
        partCollection[PART_INDEX].part[name] = value
      }
      else if(['units','xDimUnits','yDimUnits','zDimUnits','partVolumeUnits','partSurfaceAreaUnits'].includes(name)){
        partCollection[PART_INDEX].part[name] = value  //Update relevant part with new value
        partCollection = unitHandling(partCollection)
      }
      else if(name === 'materialId'){
        partCollection[PART_INDEX]['materialId'] = value  //Update relevant part with new value
        partCollection = addPartDisplayValues(partCollection, this.props.MATERIAL_TRANSLATIONS)
      }
      else if(name === 'quantity'){
        if (value.length > QUANTITY_FIELD_MAX_NUMBER_OF_DIGITS_ALLOWED_BY_DATABASE) {
          return // abort without updating state
        }
        // if its a non-empty value, make sure the value is greater than 0 and dont allow any
        // letters
        if (value === "") {
          partCollection[PART_INDEX][name] = value //Update relevant part with new value
          this.props.setProject(project)
          return
        }
        else {
          let newValue = value < "1" ? "1" : value
          value = newValue.replace(/[^0-9]/g, "")
        }
        partCollection[PART_INDEX][name] = value  //Update relevant part with new value
        partCollection = addPartDisplayValues(partCollection, this.props.MATERIAL_TRANSLATIONS)
      }
      else if(name === 'infillPercentage'){
        // an integer between 10 and 100
        value = Math.round(value)
        value = String(value).replace(/[^0-9]/g, "") // ensure this number is an integer
        if(value > 100){
          value = 100
          this.props.openSnackBarHandler('Infill must be 100% or less')
        }
        if(value < 10){
          value = 10
          this.props.openSnackBarHandler('Infill must be 10% or more')
        }
        partCollection[PART_INDEX][name] = value
      }
      //if updating part process, material, quantity, or finish : call for cost recalculation
      else if(name === 'processId'){ //If updating process validate Material and Finish, then update costs
        partCollection[PART_INDEX][name] = value  //Update relevant part with new value
        partCollection[PART_INDEX] = updateMaterialAndFinish(partCollection[PART_INDEX], this.props.MATERIAL_TRANSLATIONS) //When updating process set default material and finish for new process
        partCollection = addPartDisplayValues(partCollection, this.props.MATERIAL_TRANSLATIONS)

        if(['injMoldingPart', 'casting'].includes(value)){
          partCollection[PART_INDEX].materialIdProductionToolIsDesignedFor = partCollection[PART_INDEX].materialId
          partCollection[PART_INDEX].materialId = ''
          partCollection[PART_INDEX].material = ''
        }
      }
      else if(name === 'finishId'){
        partCollection[PART_INDEX][name] = value  //Update relevant part with new value
        partCollection = addPartDisplayValues(partCollection, this.props.MATERIAL_TRANSLATIONS)
      }
      else if(name === 'riskAccepted'){
        partCollection[PART_INDEX].part[name] = true
      }
      else{
        partCollection[PART_INDEX][name] = value  //Update relevant part with new value
      }
      project.partGroups = partCollection

      const callback = () => {
        if(name === 'processId' || name === 'units'){
          this.triggerWallThicknessTaskIfNecessary(partID)
        }

        if (name === 'processId') {
          this.triggerInjectionMoldingWallThicknessTaskIfNecessary(partID)
        }
      }

      const DONT_RECALC_FIELDS = ['lockedUnitPrice']
      const dontRecalc = DONT_RECALC_FIELDS.includes(name)

      this.props.setProject(project, { partGroupNumber: partID, saveProject: true, savePartGroup: true, callback, dontRecalc })
    }
    else{
      throw new Error('Wrong change handler used')
    }
  }

  addPartConfigurationToPartGroup = async partGroup => {
    const newPtpc = await createProductionToolPartConfiguration({
      color: 'black',
      isDmeReviewed: true,
      materialId: partGroup.materialIdProductionToolIsDesignedFor,
      partGroupNumber: partGroup.partGroupNumber,
      pricingMethod: "calculated",
      processId: partGroup.processId,
      notes: '',
      quantity: 1,
    })

    newPtpc.attachments = []
    newPtpc.postProcesses = []

    const project = {...this.props.project}
    project
      .partGroups
      .find(ptg => ptg.partGroupNumber === partGroup.partGroupNumber)
      .productionToolPartConfigurations
      .push(newPtpc)

    this.props.setProject(project)
  }

  updateProductionToolPartConfiguration = async (name, value, productionToolPartConfigurationId, partGroupNumber) => {
    const project = {...this.props.project}
    const productionToolPartConfiguration = project
          .partGroups
          .find(ptg => ptg.partGroupNumber === partGroupNumber)
          .productionToolPartConfigurations
          .find(ptpc => ptpc.productionToolPartConfigurationId === productionToolPartConfigurationId)

    productionToolPartConfiguration[name] = value

    if(this.props.user.type !== USER_TYPES.DME){
      const DME_REVIEWED_FIELDS = ['notes']
      if(DME_REVIEWED_FIELDS.includes(name)){
        productionToolPartConfiguration.isDmeReviewed = false
      }
    }

    if(name === 'materialId'){
      const materialObject = this.props.MATERIAL_TRANSLATIONS
            .find(matObj => matObj.id === value)

      const allowedColors = COLORS.filter(color => color.colorSets.includes(materialObject.colorSet))

      const isColorAllowed = allowedColors
            .map(colorObj => colorObj.value)
            .includes(productionToolPartConfiguration.color)

      if(!isColorAllowed){
        productionToolPartConfiguration.color = allowedColors[0].value
      }
    }

    const DONT_RECALC_FIELDS = ['lockedUnitPrice']
    const dontRecalc = DONT_RECALC_FIELDS.includes(name)

    const callback = () => updateProductionToolPartConfiguration(productionToolPartConfiguration)
    this.props.setProject(project, { callback, dontRecalc })
  }

  deleteProductionToolPartConfiguration = (productionToolPartConfigurationId, partGroupNumber) => {
    const project = {...this.props.project}
    const partGroup = project.partGroups
      .find(ptg => ptg.partGroupNumber === partGroupNumber)

    partGroup.productionToolPartConfigurations = partGroup
      .productionToolPartConfigurations
      .filter(ptpc => ptpc.productionToolPartConfigurationId !== productionToolPartConfigurationId)

    const callback = () => deleteProductionToolPartConfiguration(productionToolPartConfigurationId)
    this.props.setProject(project, { callback })
  }

  triggerWallThicknessTaskIfNecessary = async (partGroupNumber) => {
    const partGroup = this.props.project.partGroups
          .find(partGroup => partGroup.partGroupNumber === partGroupNumber)

    const isMMFileWithAllMissingMMFields = partGroup.part.units === 'mm'
          && !(partGroup.part.wallThicknessOBJFileMM && partGroup.part.wallThicknessMaterialFileMM)

    const isInchesFileWithAllMissingInchesFields = partGroup.part.units === 'inches'
          && !(partGroup.part.wallThicknessOBJFileInches && partGroup.part.wallThicknessMaterialFileInches)

    let needToStartWallthickness = partAnalysisStatus(partGroup, this.props.postProcessData.POST_PROCESS_OPTIONS).NEEDS_WALL_THICKNESS
        && partGroup.part.partFileS3
        && (partGroup.part.s3ObjFileId || partGroup.part.s3ObjFileIdNew)
        && (isMMFileWithAllMissingMMFields || isInchesFileWithAllMissingInchesFields)

    if(needToStartWallthickness){
      // get correct wall thickness file id fields to check for based on units
      let unitSuffix
      if (partGroup.part.units === "mm") { unitSuffix = 'MM' }
      if (partGroup.part.units === "inches") { unitSuffix = 'Inches' }
        // start wall thickness analysis
        this.wallThicknessAnalysis(partGroup.part,this.props.project.projectNumber)
        .then((partFromDatabase)=>{
          const analysisErrorMessage = partFromDatabase["wallThicknessError" + unitSuffix]
          if (analysisErrorMessage) throw new Error(analysisErrorMessage)

          // get the results from the wall thickness analysis and place them in the current
          // partGroup before saving it to state
          const projectToSave = deepCopy(this.props.project) // Must re-grab state after an async operation
          projectToSave.partGroups = projectToSave.partGroups.map(partGroup => {
            if(partGroup.partGroupNumber === partGroupNumber){
              partGroup = this.addWallThicknessResultsToPartGroupBeforeSavingToState(partGroup, partFromDatabase, unitSuffix)
            }
            return partGroup
          })
          this.props.setProject(projectToSave, { partGroupNumber, saveProject: true, savePartGroup: true })
        })
        .catch((err) => {
          // this error can either be a failure to start the wall thickness task, a timeout waiting
          // for the result, or an error that occured in the wall thickness analysis process on the
          // backend

          // set a generic wall thickness failure error to display in the row
          const projectToSave = deepCopy(this.props.project) // Must re-grab state after an async operation
          const partGroupToSave = projectToSave.partGroups.find(partGroup => partGroup.partGroupNumber === partGroupNumber)

          partGroupToSave.part['wallThicknessError' + unitSuffix] = err.message
          this.props.setProject(projectToSave, { partGroupNumber, saveProject: true, savePartGroup: true })
        })
    }
  }

  triggerInjectionMoldingWallThicknessTaskIfNecessary = async (partGroupNumber) => {
    let partGroup = this.props.project.partGroups
          .find(partGroup => partGroup.partGroupNumber === partGroupNumber)

    let partStatus = partAnalysisStatus(partGroup, this.props.postProcessData.POST_PROCESS_OPTIONS)

    const {
      NEEDS_INJECTION_MOLDING_WALL_THICKNESS,
      INJECTION_MOLDING_WALL_THICKNESS_FINISHED,
    } = partAnalysisStatus(partGroup, this.props.postProcessData.POST_PROCESS_OPTIONS)

    try {
      if (NEEDS_INJECTION_MOLDING_WALL_THICKNESS && !INJECTION_MOLDING_WALL_THICKNESS_FINISHED) {
        await geom.startNewInjectionMoldingWallThicknessTask(partGroup.part)
        const partWithResult = await geom.pollDatabaseForNewInjectionMoldingWallThicknessResult(partGroup.part.partNumber)
        this.saveImData(partGroup.partGroupNumber, partWithResult)
      }
    } catch(err) {
      this.props.openSnackBarHandler(`Error getting IM tools for ${partGroup.part.fileName}: ${err.message}`)
    }
  }

  getCncToolsDataAllParts = async () => {
    this.props.project.partGroups
      .map(partGroup => {
        const partIsCnc = partGroup.processId === 'cnc'
        const partNeedsCncToolsAnaylsis = !partGroup.part.cncMillOrTurn && !partGroup.part.cncSetupsPerPart
        if(partIsCnc && partNeedsCncToolsAnaylsis){
          this.getCncToolsData(partGroup.partGroupNumber)
        }
      })
  }

  getCncToolsData = async (partGroupNumber) => {
    try{
      const partGroup = this.props.project.partGroups
            .find(partGroup => partGroup.partGroupNumber === partGroupNumber)

      const stlFileId = partGroup.part.s3StlFileIdNew ? partGroup.part.s3StlFileIdNew : partGroup.part.s3StlFileId // backwards compatibility
      await geom.startCncToolsTask(stlFileId, partGroup.part.partNumber)
      const results = await geom.pollDatabaseForCncToolsResult(partGroup.part.partNumber)

      const projectToSave = deepCopy(this.props.project) // Must re-grab state after an async operation
      const updatedPartGroup = projectToSave.partGroups
            .find(partGroup => partGroup.partGroupNumber === partGroupNumber)

      updatedPartGroup.part.cncFinishingTools = results.cncFinishingTools
      updatedPartGroup.part.cncRoughingTools = results.cncRoughingTools
      updatedPartGroup.part.cncMillOrTurn = results.cncMillOrTurn
      updatedPartGroup.part.cncSetupsPerPart = results.cncSetupsPerPart
      updatedPartGroup.part.isCncSetupsJobDone = results.isCncSetupsJobDone

      const callback = () => this.props.openSnackBarHandler(`Tools updated for ${updatedPartGroup.part.fileName}`)
      this.props.setProject(projectToSave, { partGroupNumber, saveProject: true, savePartGroup: true, callback })
    } catch(e) {
      const partGroupMaybe = this.props.project.partGroups
            .find(partGroup => partGroup.partGroupNumber === partGroupNumber)
      const fileNameMaybe = partGroupMaybe.part ? partGroupMaybe.part : 'a deleted part'

      this.props.openSnackBarHandler(`Error getting tools for ${fileNameMaybe}: ${e.message}`)
    }
  }

  saveInjectionMoldingWallThicknessTaskId = (partGroupNumber, taskId) => {
    const projectToSave = deepCopy(this.props.project) // Must re-grab state after an async operation
    projectToSave.partGroups = projectToSave.partGroups.map(partGroup => {
      if (partGroup.partGroupNumber === partGroupNumber){
        partGroup.part.injectionMoldingWallThicknessTaskId = taskId
      }
      return partGroup
    })

    this.props.setProject(projectToSave, { partGroupNumber, saveProject: true, savePartGroup: true })
  }

  saveInjectionMoldingWallThicknessResult = (partGroupNumber, imWallThicknessResultFileId, imWallThicknessError) => {
    const projectToSave = deepCopy(this.props.project) // Must re-grab state after an async operation
    projectToSave.partGroups = projectToSave.partGroups.map(partGroup => {
      if (partGroup.partGroupNumber === partGroupNumber){
        if (imWallThicknessError) {
          partGroup.part.injectionMoldingWallThicknessError = imWallThicknessError
        }
        if (imWallThicknessResultFileId) {
          partGroup.part.injectionMoldingWallThicknessResultFileId = imWallThicknessResultFileId
        }
      }
      return partGroup
    })

    this.props.setProject(projectToSave, { partGroupNumber, saveProject: true, savePartGroup: true })
  }

  saveImData = (partGroupNumber, part) => {
    console.log({partGroupNumber, part})
    const projectToSave = deepCopy(this.props.project) // Must re-grab state after an async operation
    projectToSave.partGroups = projectToSave.partGroups.map(partGroup => {
      if (partGroup.partGroupNumber === partGroupNumber){
        partGroup.part.averageWallThickness = part.averageWallThickness
        partGroup.part.standardDeviationWallThickness = part.standardDeviationWallThickness

        partGroup.part.imRoughingTools = part.imRoughingTools
        partGroup.part.imFinishingTools = part.imFinishingTools
      }

      return partGroup
    })

    this.props.setProject(projectToSave, { partGroupNumber, saveProject: true, savePartGroup: true })
  }

  addWallThicknessResultsToPartGroupBeforeSavingToState = (partGroup, partWithWallThicknessResult, unitSuffix) => {
    partGroup.part['s3ObjFileId'] = partWithWallThicknessResult['s3ObjFileId']
    partGroup.part['s3ObjFileIdNew'] = partWithWallThicknessResult['s3ObjFileIdNew']

    const wallThicknessGlbFileField = "wallThicknessGlbFileId" + unitSuffix
    partGroup.part[wallThicknessGlbFileField] = partWithWallThicknessResult[wallThicknessGlbFileField]

    const thinWallPercentageField = "thinWallPercentage" + unitSuffix + "New"
    partGroup.part[thinWallPercentageField] = partWithWallThicknessResult[thinWallPercentageField]

    const objField = "wallThicknessOBJFile" + unitSuffix
    partGroup.part[objField] = partWithWallThicknessResult[objField]

    const mtlField = "wallThicknessMaterialFile" + unitSuffix
    partGroup.part[mtlField] = partWithWallThicknessResult[mtlField]

    const resultsField = "wallThicknessResults" + unitSuffix
    partGroup.part[resultsField] = partWithWallThicknessResult[resultsField]

    const totalSurfaceAreaField = 'netfabbWallThicknessTotalSurfaceArea' + unitSuffix
    partGroup.part[totalSurfaceAreaField] = partWithWallThicknessResult[totalSurfaceAreaField]

    const belowThresholdField = 'netfabbWallThicknessSurfaceAreaBelowThreshold' + unitSuffix
    partGroup.part[belowThresholdField] = partWithWallThicknessResult[belowThresholdField]

    const clustersField = 'netfabbWallThicknessNumberOfClustersBelowThreshold' + unitSuffix
    partGroup.part[clustersField] = partWithWallThicknessResult[clustersField]

    const largestClusterField = 'netfabbWallThicknessAreaOfLargestClusterBelowThreshold' + unitSuffix
    partGroup.part[largestClusterField] = partWithWallThicknessResult[largestClusterField]

    return partGroup
  }

  wallThicknessAnalysis = async (part, projectNumber) => {
    // start a new netfabb task
    // AKA add a new message to the netfabb processing queue
    await geom.startNewWallThicknessTask(part.s3StlFileIdNew, part.partNumber, part.units)

    // wait until the wall thickness task is finished
    const updatedPart = await geom.pollDatabaseForNewWallThicknessResult(part.partNumber, part.units)

    return updatedPart
  }

  /*
    onChangeProjectFieldQuoteToolHandler takes input from the quote tool and updates state
  */
  onChangeProjectFieldQuoteToolHandler = (event) => {
    console.log('onchange project field')
    let projectCopy = { ...this.props.project }
    let { name, value } = event.target
    const PROJECT_FIELDS = [
      'customerNotes', 'leadTimeRequest', 'paidExpediteLevel',
      'lastQuoteRevision',
      'orderDate',
      'isLeadTimeLocked', 'lockedProductionLeadTimeDays', 'lockedSampleLeadTimeDays',
      'isDmeReviewed',
      'lockedLeadTimeExpediteFee', 'supplierNotes',
      'markupLeadTimeQuantity1', 'markupLeadTimeQuantityX', 'markupSupplierId',
      'isDomesticSourcing',
    ]
    if (PROJECT_FIELDS.includes(name)) {
      if (name === 'leadTimeRequest') {
          let newValue = value < "1" ? "1" : value
          value = Number(String(newValue).replace(/[^0-9]/g, ""))
      }

      if(name === 'lockedProductionLeadTimeDays' || name === 'lockedSampleLeadTimeDays'){
        let newValue = value >= 0 ? value : 0 // only allow positive numbers
        value = Number(String(value).replace(/[^0-9]/g, "")) // only allow integers
      }

      let saveProjectDebounce = 0
      if(name === 'customerNotes' || name === 'supplierNotes'){
        saveProjectDebounce = 2000
      }

      if(this.props.user.type !== USER_TYPES.DME){
        const DME_REVIEWED_FIELDS = ['customerNotes']
        if(DME_REVIEWED_FIELDS.includes(name)){
          projectCopy.isDmeReviewed = false
        }
      }

      const dontRecalc = projectCopy.isLeadTimeLocked

      projectCopy[name] = value
      this.props.setProject(projectCopy, { saveProject: true, saveProjectDebounce, dontRecalc })
    }
    else {
      throw new Error('Wrong change handler used')
    }
  }

  /*
    onChangeApplyAllFieldQuoteToolHandler takes input from the quote tool and updates state
  */
  onChangeApplyAllFieldQuoteToolHandler = (event) => {
    let {name,value} = event.target
    const VALUE_FOR_ALL_FIELDS = ['forAllMaterial','forAllProcess','forAllQuantity','forAllUnits']
    if(VALUE_FOR_ALL_FIELDS.includes(name)){ //If updating a 'for all' field
      let defaultValues = {...this.state.applyValuesToAll}
      defaultValues[name] = value
      if(name === 'forAllProcess'){
        defaultValues['forAllMaterial'] = ''
      }
      if (name === 'forAllQuantity') {
        if (value.length > QUANTITY_FIELD_MAX_NUMBER_OF_DIGITS_ALLOWED_BY_DATABASE) {
          return // abort without updating state
        }
      }
      this.setState({
        applyValuesToAll: defaultValues,
      },()=>{ // setState callback
        if(value){  // Only copy value to all rows when not updating to empty string
          this.copyValueToAllRows(name)
        }
        else{
          // if changing an apply all field to blank, we dont update any actual parts so there is no need to recalculate
        }
      })
    }
    else{
      throw new Error('Wrong change handler used')
    }
  }

  copyValueToAllRows = fieldName => {
    const project = deepCopy(this.props.project)
    let partCollection = project.partGroups
    let notApplied = false
    partCollection.forEach(partGroup=>{
      if(!partGroup.isOriginalPartGroup){
        notApplied = true
        return
      }
      if(fieldName === 'forAllProcess'){
        partGroup.processId = this.state.applyValuesToAll.forAllProcess
        partGroup = updateMaterialAndFinish(partGroup, this.props.MATERIAL_TRANSLATIONS) //When updating process set default material and finish for new process

        if(['injMoldingPart', 'casting'].includes(this.state.applyValuesToAll.forAllProcess)){
          partGroup.materialIdProductionToolIsDesignedFor = partGroup.materialId
          partGroup.materialId = ''
          partGroup.material = ''
          partGroup.quantity = 1
        }
      }
      else if(fieldName === 'forAllMaterial'){
        partGroup.materialId = this.state.applyValuesToAll.forAllMaterial
        let materialData = this.props.MATERIAL_TRANSLATIONS.find(material=>material.id === partGroup.materialId)  // If updating material update process to match
        const RELEVANT_PROCESS = materialData.processId
        partGroup.processId = RELEVANT_PROCESS
      }
      else if(fieldName === 'forAllQuantity'){
        if(['injMoldingPart', 'casting'].includes(partGroup.processId)){
          partGroup.quantity = 1
        } else {
          partGroup.quantity = this.state.applyValuesToAll.forAllQuantity
        }
      }
      else if(fieldName=== 'forAllUnits' && !checkFileTypeIncludeUnits(partGroup.part.fileName)){
        partGroup.part.units = this.state.applyValuesToAll.forAllUnits
      }
    })
    partCollection = addPartDisplayValues(partCollection, this.props.MATERIAL_TRANSLATIONS)  //Update display values to match id values
    partCollection = unitHandling(partCollection)
    project.partGroups = partCollection

    if(notApplied){
      this.props.openSnackBarHandler('Apply to All was not applied to Production Tool(s) added from other Projects')
    }

    const callback = () => {
      for (let p = 0; p < partCollection.length; p++) {
        let partGroup = partCollection[p]
        this.triggerWallThicknessTaskIfNecessary(partGroup.partGroupNumber)
        this.triggerInjectionMoldingWallThicknessTaskIfNecessary(partGroup.partGroupNumber)
      }
    }
    this.props.setProject(project, { callback, saveProject: true, savePartGroups: true })
  }

  /*
    uploadNewAttachmentHandler takes file upload events from the quote tool and sends them
    to the Attachments file handler API
    partId is an optional parameter to upload part level attachments

    dropZoneUploadBool is used to activate special handling for dropzone file attachment
    uploads (vs native input)
  */
  uploadNewAttachmentHandler = (event,partId,dropZoneUploadBool,failedDropZoneFiles) => {
    let project = {...this.props.project}
    let files
    if(dropZoneUploadBool){ // If upload came from dropzone
      files = event
      if(failedDropZoneFiles.length > 0){
        const failedFileNames = failedDropZoneFiles
              .map(f => `${f.name} (${Math.round(f.size/BYTES_IN_A_MB)} MB)`)
              .join(', ')
        this.props.openDialog([`File size may be too large (max ${Math.round(MAX_ALLOWED_ATTACHMENT_FILE_SIZE_IN_BYTES/BYTES_IN_A_MB)} MB)`,`File(s): ${failedFileNames}`], "Attachments Failed to Upload")
      }
    }
    else{ // If upload came from native html input element
      files = Object.values(event.target.files)
    }

    // Create attachments table placeholder until file is uploaded
    files.forEach(file => {
      let fileName = file.name
      let placeholderAttachment = {fileName:fileName,currentlyLoading:true} // Create placeholder part attachment
      if (!file) { return }
      if(partId){ // If attachment is uploaded to part group add placeholder attachment to that part
        const project = deepCopy(this.props.project)
        let partGroup = project.partGroups.find(partGroup => partGroup.partGroupNumber === partId)
        let attachmentsArray = partGroup.attachments
        attachmentsArray = attachmentsArray ? attachmentsArray : []
        attachmentsArray.push(placeholderAttachment)
        partGroup.attachments = attachmentsArray
        this.props.setProject(project)
      }
      else{ // If attachment is uploaded to project add placeholder attachment to project attachment array
        project.attachments.push(placeholderAttachment)
        this.props.setProject(project)
      }
    })

    // Upload each attachment
    files.forEach(file => {
      s3.uploadFileToS3(file)
      .then(async (key)=>{
        let fileName = file.name

        let project = {...this.props.project}

        const attachment = {
          fileName,
          s3Id: key,
          projectId: project.projectNumber,
          fileType: 'attachment',
          isActive: true,
          userUploaded: project.customerEmail,
        }

        if(partId){
          const project = deepCopy(this.props.project)
          const partGroup = project.partGroups.find(partGroup => partGroup.partGroupNumber === partId)

          //create file attachment
          attachment.partGroupId = partId
          const fileFromDb = await createFile(attachment)
          partGroup.attachments = partGroup.attachments ? partGroup.attachments : []
          partGroup.attachments.push(fileFromDb)

          //remove placeholder attachment
          let ATTACHMENT_TO_REMOVE_INDEX = partGroup.attachments.findIndex(attachment=>attachment.fileName === fileName && attachment.currentlyLoading)
          partGroup.attachments.splice(ATTACHMENT_TO_REMOVE_INDEX,1) // Delete placeholder loading attachment

          if(this.props.user.type !== USER_TYPES.DME){
            partGroup.isDmeReviewed = false
          }

          this.props.setProject(project, { savePartGroup: true, partGroupNumber: partId })
        }
        else{
          //create file attachment
          const fileFromDb = await createFile(attachment)
          project.attachments = project.attachments ? project.attachments : []
          project.attachments.push(fileFromDb)

          //remove placeholder attachment
          let ATTACHMENT_TO_REMOVE_INDEX = project.attachments.findIndex(attachment=>attachment.fileName === fileName && attachment.currentlyLoading)
          project.attachments.splice(ATTACHMENT_TO_REMOVE_INDEX,1)

          if(this.props.user.type !== USER_TYPES.DME){
            project.isDmeReviewed = false
          }

          this.props.setProject(project, {saveProject: true})
        }
      })
      .catch((err)=>{
        this.props.openDialog(["There was an issue uploading your attachment"], "Error")
      })
    })
  }

  removeAttachmentHandler = (fileId,partGroupId) => {
    const project = deepCopy(this.props.project)

    let attachmentsArray
    if(partGroupId){
      const partGroup = project.partGroups.find(partGroup => partGroup.partGroupNumber === partGroupId)
      attachmentsArray = partGroup.attachments.slice()
    }
    else{
      attachmentsArray = this.props.project.attachments.slice()
    }
    if(!attachmentsArray.some(file => file.fileId === fileId)) return  // if attachment not found, bail out
    attachmentsArray = attachmentsArray.filter(file => file.fileId !== fileId)
    if(partGroupId){
      const partGroup = project.partGroups.find(partGroup => partGroup.partGroupNumber === partGroupId)
      partGroup.attachments = attachmentsArray
    }
    else{
      project.attachments = attachmentsArray
    }

    const callback = () => {
      deleteFile(fileId)
    }
    this.props.setProject(project, {callback})
  }

  /*
    deletePartGroup takes a row id and removes it from the collection of rows in state
  */
  deletePartGroup = async partGroupNumber => {
    // Tell them youre removing the row and updating the quote total
    const project = deepCopy(this.props.project)
    project.partGroups = project.partGroups
      .filter(partGroup => partGroup.partGroupNumber !== partGroupNumber)

    const callback = () => {
      removePartGroup(partGroupNumber)
    }
    this.props.setProject(project, {callback, partGroupNumber, saveProject: true })
  }

  submitForManualRfq = () => {
    let project = {...this.props.project}
    this.setState({submittingForRfq:true})
    // Save project
    project.customerReady = false
    project.updateGdriveLocations = true
    updateWholeProjectPromise(project).then((response)=>{
      if (response === "200"){
        sendManualRfqEmailPromise(
          this.props.project,
          this.props.reviewReasons.projectReasons,
          this.props.reviewReasons.partsReasons
        ).then(()=>{
          let stateObject = {
            submittingForRfq: false,
            applyValuesToAll: emptyApplyToAll
          }
          this.setState(stateObject,()=>{
            this.props.navigateToConfirmationPage()
          })
        })
        .catch((err) => {
          this.setState({submittingForRfq:false},()=>{
            // There was an issue submitting project. Alert user to email us
            let msg = 'There was an issue submitting your project.'
            msg += ' Please reach out to us directly at contact@autotiv.com'
            msg += ' and we will get back to you ASAP'
            this.props.openSnackBarHandler(msg)
          })
        })
      }
      else {
        let msg = 'There was an issue submitting your project.'
        msg += ' Please reach out to us directly at contact@autotiv.com'
        msg += ' and we will get back to you ASAP'
        this.props.openSnackBarHandler(msg)
        // There was an issue submitting project. Alert user to email us
      }
    })
  }

  saveAndNavigateToCheckout = () => {
    let project = {...this.props.project}
    updateWholeProjectPromise(project)
    this.props.enableAndNavigateToCheckout(this.props.project.projectNumber, this.props.pricingData, this.state.selectedLeadTime)
  }

  addTool = (defaultPercentage, partGroupNumber, toolType) => {
    const project = deepCopy(this.props.project)
    const partGroup = project.partGroups.find(partGroup => partGroup.partGroupNumber === partGroupNumber)
    const NEW_TOOL_TEMPLATES = {
      cncFinishingTools: {
        diameter: '',
        surfaceAreaPercentage: defaultPercentage,
      },
      cncRoughingTools: {
        diameter: '',
        volumePercentage: defaultPercentage,
      },
      imFinishingTools: {
        diameter: '',
        surfaceAreaPercentage: defaultPercentage,
      },
      imRoughingTools: {
        diameter: '',
        volumePercentage: defaultPercentage,
      }
    }

    partGroup.part[toolType].push(NEW_TOOL_TEMPLATES[toolType])

    this.props.setProject(project, { partGroupNumber, saveProject: true, savePartGroup: true })
  }

  onChangeToolField = (event, partGroupNumber, toolIndex, toolType) => {
    const {name, value} = event.target
    const project = deepCopy(this.props.project)
    const partGroup = project.partGroups.find(partGroup => partGroup.partGroupNumber === partGroupNumber)

    partGroup.part[toolType][toolIndex][name] = value

    this.props.setProject(project, { partGroupNumber, saveProject: true, savePartGroup: true })
  }

  deleteTool = (partGroupNumber, toolType, toolIndex) => {
    const project = deepCopy(this.props.project)
    const tools = project
      .partGroups
      .find(partGroup => partGroup.partGroupNumber === partGroupNumber)
      .part[toolType]

    tools.splice(toolIndex, 1)

    this.props.setProject(project, { partGroupNumber, saveProject: true, savePartGroup: true })
  }

  sendProjectToCustomer = async () => {
    const callback = async () => {
      await sendCustomerQuoteEmail(this.props.project, this.props.pricingData, this.props.selectedLeadTime)
      .catch(e => this.props.openSnackBarHandler(e.message))

      this.props.navigateToSummaryPage(this.props.project.projectNumber)
    }

    const project = {...this.props.project}

    const currentQuoteRevision = +project.lastQuoteRevision + 1
    project.lastQuoteRevision = Number.isNaN(currentQuoteRevision) ? 0 : currentQuoteRevision

    project.customerReady = true

    this.props.setProject(project, { callback, dontRecalc: true, saveProject: true })
  }

  sendQuoteToLogistics = async () => {
    sendLogisticsQuoteEmail(this.props.project, this.props.reviewReasons.projectReasons)
      .then(() => this.props.openSnackBarHandler('Email sent to logistics!'))
      .catch(e => this.props.openSnackBarHandler(e.message))
  }

  setSelectedLeadTime = selectedLeadTime => {
    this.setState({selectedLeadTime})
  }

  updateCncStockValues = (partGroupNumber) => {
    const project = deepCopy(this.props.project)
    const partGroup = project.partGroups
          .find(ptg => ptg.partGroupNumber === partGroupNumber)

    if(partGroup.part.cncMillOrTurn === CNC_TYPES.mill){
      const { cncStockX, cncStockY, cncStockZ } = getCncMillStockValues(partGroup)
      partGroup.part.cncStockX = cncStockX
      partGroup.part.cncStockY = cncStockY
      partGroup.part.cncStockZ = cncStockZ
    } else {
      const { cncStockInnerDiameter, cncStockOuterDiameter, cncStockZ } = getCncTurnStockValues(partGroup)
      partGroup.part.cncStockInnerDiameter = cncStockInnerDiameter
      partGroup.part.cncStockOuterDiameter = cncStockOuterDiameter
      partGroup.part.cncStockZ = cncStockZ
    }

    this.props.setProject(project, { partGroupNumber, saveProject: true, savePartGroup: true })
  }

  setUserAsDme = () => {
    try{
      const project = updateProjectManager(deepCopy(this.props.project), this.props.user.email)
      this.props.setProject(project, { saveProject: true, dontRecalc: true })
    } catch(e) {
      this.props.openSnackBarHandler(e.message)
    }
  }

  removeAllTolerances = (partGroupNumber) => {
    const project = deepCopy(this.props.project)
    const partGroup = project
      .partGroups
      .find(partGroup => partGroup.partGroupNumber === partGroupNumber)

    const toleranceCrud = nestedPartGroupFieldsCrud(this.props.project, this.props.setProject)
          .partGroups(partGroupNumber)
          .tolerances

    partGroup.tolerances.forEach(tolerance => {
      toleranceCrud(tolerance.id).delete()
    })

    this.props.openSnackBarHandler("Tolerances are not supported for injection molding and have been automatically removed")
  }

  priceLockProject = () => {
    const project = deepCopy(this.props.project)

    // update project, partGroups, and ptpcs
    const leadTimePricePair = this.props.pricingData
          .leadTimePricePairs
          .find(ltpp => ltpp.price === this.state.selectedLeadTime)

    project.isLeadTimeLocked = true
    project.lockedProductionLeadTimeDays = leadTimePricePair.productionLeadTime
    project.lockedSampleLeadTimeDays = leadTimePricePair.sampleLeadTime
    project.lockedLeadTimeExpediteFee = (leadTimePricePair.price - this.props.pricingData.leadTimePricePairs[0].price).toFixed(2)

    project.lockedMakeOrders = JSON.stringify(leadTimePricePair.makeOrders)

    project.partGroups.forEach(ptg => {
      const lockedUnitPrice = this.props.pricingData
            .partGroupPrices
            .find(({partGroupNumber}) => partGroupNumber === ptg.partGroupNumber)
            .unitPrice
      ptg.lockedUnitPrice = lockedUnitPrice

      ptg.productionToolPartConfigurations
        .filter(ptpc => ptpc.processId === ptg.processId)
        .forEach(ptpc => {
          const lockedUnitPrice = this.props.pricingData
                .ptpcPrices
                .find(({productionToolPartConfigurationId}) => productionToolPartConfigurationId === ptpc.productionToolPartConfigurationId)
                .unitPrice
          ptpc.lockedUnitPrice = lockedUnitPrice
        })
    })

    // save to state, save project and partGroups to DB
    this.props.setProject(project, { savePartGroups: true, saveProject: true })

    // save ptpcs to DB
    const crud = nestedPartGroupFieldsCrud(this.props.project, this.props.setProject)
    project.partGroups
      .map(ptg => ptg.productionToolPartConfigurations
           .map(ptpc => updatePtpc(ptg, ptpc))
          )

    function updatePtpc(partGroup, ptpc){
      const ptpcCrud = crud.partGroups(partGroup.partGroupNumber).productionToolPartConfigurations(ptpc.productionToolPartConfigurationId)
    }
  }

  addPurchasedToolToProject = async (purchasedTool) => {
    const partGroupNumber = await createPartGroup(purchasedTool.partGroup.part.partNumber)

    const newPartGroup = {
      ...purchasedTool.partGroup,
      partGroupNumber,
      project: this.props.project.projectNumber,
      isOriginalPartGroup: false,
    }

    const defaultPtpc = await createProductionToolPartConfiguration({
      color: 'black',
      isDmeReviewed: true,
      materialId: purchasedTool.partGroup.materialIdProductionToolIsDesignedFor,
      partGroupNumber,
      pricingMethod: "calculated",
      processId: purchasedTool.partGroup.processId,
      notes: '',
      quantity: 1,
    })

    defaultPtpc.attachments = []
    defaultPtpc.postProcesses = []

    newPartGroup.productionToolPartConfigurations = [ defaultPtpc ]

    const attachmentToCopy = async (attachment) => {
      const attachmentPayload = {
        s3Id: attachment.s3Id,
        fileName: attachment.fileName,
        projectId: project.projectNumber,
        partGroupId: partGroupNumber,
        fileType: 'attachment',
        gDriveId: attachment.gDriveId,
        userUploaded: attachment.userUploaded,
        isActive: true,
      }

      const newFile = await createFile(attachmentPayload)
      return newFile
    }
    const overmoldedInsertToCopy = async ({id, ...overmoldedInsertFields}) => {
      const overmoldedInsertPayload = {...overmoldedInsertFields}
      const newOvermoldedInsert = await createOvermoldedInsertConfig(overmoldedInsertPayload)
      return newOvermoldedInsert
    }
    const postProcessToCopy = async ({id, ...postProcessFields}) => {
      const postProcessPayload = {...postProcessFields}
      const newPostProcess = await createPostProcessConfig(postProcessPayload)
      return newPostProcess
    }
    const toleranceToCopy = async ({id, ...toleranceFields}) => {
      const tolerancePayload = {...toleranceFields}
      const newTolerance = await createToleranceConfig(tolerancePayload)
      return newTolerance
    }

    newPartGroup.attachments = await Promise.all(purchasedTool.partGroup.attachments.map(attachmentToCopy))
    newPartGroup.overmoldedInserts = await Promise.all(purchasedTool.partGroup.overmoldedInserts.map(overmoldedInsertToCopy))
    newPartGroup.postProcesses = await Promise.all(purchasedTool.partGroup.postProcesses.map(postProcessToCopy))
    newPartGroup.tolerances = await Promise.all(purchasedTool.partGroup.tolerances.map(toleranceToCopy))

    const project = deepCopy(this.props.project)

    project.partGroups.push(newPartGroup)

    this.props.setProject(project, { partGroupNumber,  savePartGroup: true, saveProject: true })
  }

  updateCustomer = customer => {
    const project = deepCopy(this.props.project)

    project.customer = customer
    project.customerEmail = customer.email

    const callback = () => this.props.openSnackBarHandler('Customer Updated!')
    this.props.setProject(project, { callback, saveProject: true })
  }

  deleteInternalFile = async (fileId) => {
    deleteFile(fileId)
      .then(() => {
        const updatedInternalFiles = this.props.project.internalFiles
              .filter(file => file.fileId !== fileId)
        const updatedProject = {
          ...this.props.project,
          internalFiles: updatedInternalFiles,
        }
        this.props.setProject(updatedProject)
      })
      .catch(e => this.props.openSnackBarHandler(`Error deleting internal file: ${e.message}`))
  }

  uploadInternalFile = async (acceptedFiles, rejectedFiles) => {
    if(rejectedFiles.length > 0){
      this.props.openSnackBarHandler(`Can't upload internal files: ${rejectedFiles.map(f => f.name).join(', ')}`)
    } else {
      const createNewInternalFile = async (file) => {
        const s3Id = await s3.uploadFileToS3(file)
        const internalFileTemplate = {
          fileName: file.name,
          fileType: 'internal',
          isActive: true,
          s3Id,
          projectId: this.props.project.projectNumber,
          userUploaded: this.props.user.email,
        }
        const internalFile = await createFile(internalFileTemplate)
        return internalFile
      }
      const createNewInternalFileProm = (file) => {
        return createNewInternalFile(file)
          .catch(e => {
            this.props.openSnackBarHandler(e.message)
            throw e
          })
      }
      const newInternalFiles = await Promise.all(acceptedFiles.map(createNewInternalFileProm))
      const updatedInternalFiles = [...this.props.project.internalFiles, ...newInternalFiles]
      const updatedProject = {
        ...this.props.project,
        internalFiles: updatedInternalFiles,
      }
      this.props.setProject(updatedProject)
    }
  }

  cloneProject = async () => {
    const userIsDme = this.props.user.type === USER_TYPES.DME
    return cloneProject(this.props.project, userIsDme)
      .then(projectNumber => {
        this.props.navigateToSummaryPage(projectNumber)
      })
      .catch(err => {
        this.props.openSnackBarHandler(err.message)
      })
  }

  render(){
    return(
      <QuoteTool
        {...this.props}
        addPartConfigurationToPartGroup={this.addPartConfigurationToPartGroup}
        addPurchasedToolToProject={this.addPurchasedToolToProject}
        addTool={this.addTool}
        applyValuesToAll={this.state.applyValuesToAll}
        cloneProject={this.cloneProject}
        crud={nestedPartGroupFieldsCrud(this.props.project, this.props.setProject)}
        currentlyLoading={this.state.currentlyLoading}
        deleteInternalFile={this.deleteInternalFile}
        deletePartGroup={this.deletePartGroup}
        deleteProductionToolPartConfiguration={this.deleteProductionToolPartConfiguration}
        deleteTool={this.deleteTool}
        getCncToolsData={this.getCncToolsData}
        getCncToolsDataAllParts={this.getCncToolsDataAllParts}
        isCalculatingPrices={this.props.isCalculatingPrices}
        MATERIAL_TRANSLATIONS={this.props.MATERIAL_TRANSLATIONS}
        onChangeApplyAllFieldQuoteToolHandler={this.onChangeApplyAllFieldQuoteToolHandler}
        onChangePartFieldQuoteToolHandler={this.onChangePartFieldQuoteToolHandler}
        onChangeProjectFieldQuoteToolHandler={this.onChangeProjectFieldQuoteToolHandler}
        onChangeToolField={this.onChangeToolField}
        pauseCalculations={this.props.pauseCalculations}
        postProcessData={this.props.postProcessData}
        priceLockProject={this.priceLockProject}
        pricingData={this.props.pricingData}
        project={this.props.project}
        removeAllTolerances={this.removeAllTolerances}
        removeAttachment={this.removeAttachmentHandler}
        replacePartFile={this.replacePartFile}
        reviewReasons={this.props.reviewReasons}
        saveAndNavigateToCheckout={this.saveAndNavigateToCheckout}
        selectedLeadTime={this.state.selectedLeadTime}
        sendProjectToCustomer={this.sendProjectToCustomer}
        sendQuoteToLogistics={this.sendQuoteToLogistics}
        setPauseCalculations={this.props.setPauseCalculations}
        setSelectedLeadTime={this.setSelectedLeadTime}
        setUserAsDme={this.setUserAsDme}
        submitForManualRfq={this.submitForManualRfq}
        submittingForRfq={this.state.submittingForRfq}
        TOOL_MATERIALS={this.props.TOOL_MATERIALS}
        toleranceClassifications={this.props.toleranceClassifications}
        updateCncStockValues={this.updateCncStockValues}
        updateCustomer={this.updateCustomer}
        updateProductionToolPartConfiguration={this.updateProductionToolPartConfiguration}
        uploadInternalFile={this.uploadInternalFile}
        uploadNewAttachment={this.uploadNewAttachmentHandler}
        uploadParts={this.uploadParts}
      />
    )
  }
}

export default withProject(SmartQuoteTool)
