import {
    fileIsTooLargeToAnalyze,
    isModelOrDrawing,
} from '../utils'
import { businessLogicMfgIssues } from '../shared/businessLogicMfgIssues.js'
import frg from '../feature-release-guards.js'

export function partAnalysisStatus(partGroup, POST_PROCESS_OPTIONS) {

    // can only be analyzed if it is a model type file, ex. stl and not a drawing ex. pdf
    let IS_MODEL = false
    try {
      IS_MODEL = isModelOrDrawing(partGroup.part.fileName) === 'model'
    }
    catch (e) {}
    
    let CAN_BE_ANALYZED
    const FILE_SIZE_CAN_BE_ANALYZED = !fileIsTooLargeToAnalyze(partGroup.part.fileSize)
    CAN_BE_ANALYZED = IS_MODEL && FILE_SIZE_CAN_BE_ANALYZED // Only models under the size limit can be analyzed

    // only need to do wall thickness analysis on 3d printing models
    const ARRAY_OF_3D_PRINTING_PROCESSES = ['sls','fff','fdm','sla','mjf','polyJet']
    const IS_3D_PRINTING_PROCESS = ARRAY_OF_3D_PRINTING_PROCESSES.includes(partGroup.processId)

    const IS_IM_PROCESS = [ "injMoldingPart", "casting" ].includes(partGroup.processId)

    // TODO
    // let PARAM_EXTRACT_STARTED = false

    // Check mfg issue array to see if there are any issues
    const mfgIssues = partGroup.part.mfgIssues ? partGroup.part.mfgIssues : []
    let HAS_MESH_ISSUES = checkForMeshProperties(partGroup)
    const HAS_BUSINESS_LOGIC_ISSUES = businessLogicMfgIssues(partGroup, POST_PROCESS_OPTIONS).length > 0
    let HAS_MANUFACTURABILITY_ISSUES = mfgIssues.length > 0 || HAS_MESH_ISSUES || HAS_BUSINESS_LOGIC_ISSUES

    const mfgAnalysisIssues = (partGroup.part.mfgAnalysisIssues) ? partGroup.part.mfgAnalysisIssues : []
    let HAD_ISSUES_ANALYZING_PARAMS = partGroup.part.paramExtractError || mfgAnalysisIssues.length > 0

    const HAS_DIMENSIONS = partGroup.part.xDimUnits > 0 || partGroup.part.yDimUnits > 0 || partGroup.part.zDimUnits > 0

    const NEEDS_WALL_THICKNESS = CAN_BE_ANALYZED && IS_3D_PRINTING_PROCESS && !HAD_ISSUES_ANALYZING_PARAMS

    const NEEDS_INJECTION_MOLDING_WALL_THICKNESS =
      CAN_BE_ANALYZED &&
      IS_IM_PROCESS &&
      !HAD_ISSUES_ANALYZING_PARAMS

    const NEEDS_IM_CALCULATION = CAN_BE_ANALYZED && IS_IM_PROCESS && !HAD_ISSUES_ANALYZING_PARAMS && injectionMoldingInputsAreEmpty(partGroup)

    function injectionMoldingInputsAreEmpty(partGroup) {
      if (partGroup.part.averageWallThickness) {
        return false
      }

      if (partGroup.part.standardDeviationWallThickness) {
        return false
      }

      return true
    }


    let PARAM_EXTRACT_FINISHED = (HAS_DIMENSIONS && Boolean(partGroup.part.s3ObjFileId || partGroup.part.s3ObjFileIdNew)) || HAD_ISSUES_ANALYZING_PARAMS

    // TODO
    // let WALL_THICKNESS_TASK_STARTED = false
    
    // Wall thickness is done once we have the wall thickness files for the current units
    let WALL_THICKNESS_TASK_FINISHED = false
    let HAD_ISSUES_ANALYZING_WALL_THICKNESS = false
    if(NEEDS_WALL_THICKNESS){
      if(partGroup.part.units === "mm"){
        WALL_THICKNESS_TASK_FINISHED = Boolean(CAN_BE_ANALYZED && partGroup.part.wallThicknessGlbFileIdMM)
        HAD_ISSUES_ANALYZING_WALL_THICKNESS = Boolean(partGroup.part.wallThicknessErrorMM)
      }
      if(partGroup.part.units === "inches"){
        WALL_THICKNESS_TASK_FINISHED = Boolean(CAN_BE_ANALYZED && partGroup.part.wallThicknessGlbFileIdInches)
        HAD_ISSUES_ANALYZING_WALL_THICKNESS = Boolean(partGroup.part.wallThicknessErrorInches)
      }
    }
    else{
      WALL_THICKNESS_TASK_FINISHED = true
    }

    const PART_HAS_INJECTION_MOLDING_WALL_THICKNESS_FIELDS = (
      partGroup.part.averageWallThickness
      || partGroup.part.injectionMoldingWallThicknessError
    )
    const INJECTION_MOLDING_WALL_THICKNESS_FINISHED = (
      NEEDS_INJECTION_MOLDING_WALL_THICKNESS
      && PART_HAS_INJECTION_MOLDING_WALL_THICKNESS_FIELDS
    )

    const PART_HAS_IM_CALCULATION_FIELDS = (
      partGroup.part.averageWallThickness
        && partGroup.part.standardDeviationWallThickness
        &&  partGroup.part.imRoughingTools
    )
    const IM_CALCULATION_FINISHED = NEEDS_IM_CALCULATION && PART_HAS_IM_CALCULATION_FIELDS


    // Has thin walls if wall thickness analysis is complete and the wall thickness result field is false
    let HAS_THIN_WALLS = checkForThinWalls(partGroup)

    let RISK_ACCEPTED = partGroup.part.riskAccepted
    let HAS_ISSUES = HAS_MANUFACTURABILITY_ISSUES || HAS_THIN_WALLS || partGroup.part.dmeMarkedAtRiskPartBool

    return {
        CAN_BE_ANALYZED,
        // PARAM_EXTRACT_STARTED,
        PARAM_EXTRACT_FINISHED,
        HAS_MANUFACTURABILITY_ISSUES,
        NEEDS_WALL_THICKNESS,
        // WALL_THICKNESS_TASK_STARTED,
        WALL_THICKNESS_TASK_FINISHED,
        HAS_THIN_WALLS,
        HAD_ISSUES_ANALYZING_PARAMS,
        HAD_ISSUES_ANALYZING_WALL_THICKNESS,
        RISK_ACCEPTED,
        HAS_ISSUES,
        NEEDS_INJECTION_MOLDING_WALL_THICKNESS,
        INJECTION_MOLDING_WALL_THICKNESS_FINISHED,
        NEEDS_IM_CALCULATION,
        IM_CALCULATION_FINISHED,
    }

}

function checkForMeshProperties(partGroup){
  let meshProperties = ['badEdges', 'boundaryEdges', 'isManifold', 'flippedTriangleCount', 'shells']
  let hasMeshProperties = meshProperties.every((property) => {
    if(partGroup.part.meshProperties == null) return false
    return partGroup.part.meshProperties.hasOwnProperty(property)
  })

  const isProcess3dp = ['sla', 'sls', 'fdm', 'dmls', 'polyJet', 'mjf' ].includes(partGroup.processId)

  let hasMeshIssues = false
  if(hasMeshProperties && isProcess3dp){
    // check for mesh issues
    if (partGroup.part.meshProperties.badEdges > 0) {
      hasMeshIssues = true
    }
    if (partGroup.part.meshProperties.boundaryEdges > 0) {
      hasMeshIssues = true
    }
    if (partGroup.part.meshProperties.isManifold === 'false') {
      hasMeshIssues = true
    }
    if (partGroup.part.meshProperties.flippedTriangleCount > 0) {
      hasMeshIssues = true
    }
    if (Number(partGroup.part.meshProperties.shells) !== 1) {
      hasMeshIssues = true
    }
    return hasMeshIssues
  }
  else{
    return false
  }
}

function checkForThinWalls(partGroup){
  const part = partGroup.part
  let hasThinWalls = false

  const isProcess3dp = ['sla', 'sls', 'fdm', 'dmls', 'polyJet', 'mjf' ].includes(partGroup.processId)

  if(isProcess3dp){
    if(part.units === "mm"){
      if(part.wallThicknessResultsMM === false || part.wallThicknessResultsMM === 'false'){
        hasThinWalls = true
      }
    }
    if(part.units === "inches"){
      if(part.wallThicknessResultsInches === false || part.wallThicknessResultsInches === 'false'){
        hasThinWalls = true
      }
    }
  }
  return hasThinWalls
}
